import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Modal, Button, Row } from "react-bootstrap";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/UserContext";

function UploadComponent({ api, href, from }) {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const { getDailyPriorityTagDetails } = context;
  const [documentSuccessMsg, setdocumentSuccessMsg] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [files, setfiles] = useState([]);
  const [successFailure, setsuccessFailure] = useState("");
  const inputRef = useRef();
  const [showVendorNew, setShowVendorNew] = useState(false);
  const [errorItems, seterrorItems] = useState([]);
  const [loginData, setloginData] = useState({});
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const [loadingPendingTags, setloadingPendingTags] = useState(false);
  const [pendingTagItems, setpendingTagItems] = useState([]);
  const [showpendingTagPopup, setshowpendingTagPopup] = useState(false);
  const [submittingPoList, setsubmittingPoList] = useState(false);
  const [submittingPoError, setsubmittingPoError] = useState(null);
  const [errorMessageFalse, seterrorMessageFalse] = useState({});
  const [errorMessageFalseArray, seterrorMessageFalseArray] = useState([]);
  const handleChangeFileFun = async (e) => {
    const files = Array.from(e.target.files);
    setfiles(files);

    e.target.value = null; // Reset the file input value
  };
  console.log(api, href, from, "api href");
  const getPendingDailyTags = async () => {
    setloadingPendingTags(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/daily/sku/tag/pending_update`,
        method: "GET",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
      });

      if (response.status == 200) {
        setloadingPendingTags(false);
        if (!response.data.error) {
          console.log(response.data, "response data pending tgs");
          setpendingTagItems(response.data.items);
          setshowpendingTagPopup(true);
        } else {
          inputRef.current.click();
        }
      }
    } catch (error) {
      console.log(error);
      setloadingPendingTags(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const postDetails = async () => {
    seterrorMessageFalseArray([]);
    seterrorMessageFalse({});
    setsubmittingPoError(null);
    setsubmittingPoList(true);
    let arraydata = pendingTagItems?.map((item) => {
      return {
        item_code: item.item_code,
        tags_qty: parseInt(item.tags_qty) || null,
        damage_marbete_number: "",
        damage_tag_qty: 0,
        starting_tags: parseInt(item?.starting_tags) || null,
        ending_tags: parseInt(item?.ending_tags) || null,
        custom_declration_number: item?.custom_declration_number,
        extraction_date: item?.extraction_date,
        tags_priority: item?.tags_priority,
      };
    });
    let data = {
      user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
      customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
        ?.customer_id,
      company_name: localStorage.getItem("company_name"),
      items: arraydata,
      priority_date: pendingTagItems[0]?.priority_date,
      warehouse_id: pendingTagItems[0]?.warehouse_id,
    };
    console.log(data, "data");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/daily/sku/tag/tagQty/submit`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: data,
      });
      if (response.status == 200) {
        setsubmittingPoList(false);
        if (!response.data.error) {
          inputRef.current.click();
          setshowpendingTagPopup(false);
        } else {
          setsubmittingPoError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setsubmittingPoList(false);
      setsubmittingPoError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };

  const submitFn = async () => {
    setsuccessFailure("");
    seterrorItems([]);
    if (files?.length > 0) {
      setIsFileUploading(true);
      const formdata = new FormData();

      for (const element of files) {
        formdata.append("document", element, element?.name);
      }

      formdata.append(
        "user_id",
        JSON.parse(localStorage.getItem("mtm_user_data"))?.id
      );
      formdata.append(
        "customer_id",
        JSON.parse(localStorage.getItem("mtm_user_data"))?.customer_id
      );
      formdata.append("company_name", localStorage.getItem("company_name"));

      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}${api}`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            jwt_token: localStorage.getItem("mtm_user_token"),
          },
          data: formdata,
        });
        if (response.status == 200) {
          setIsFileUploading(false);
          if (!response.data.error) {
            getDailyPriorityTagDetails();
            if (
              response.data.error_items &&
              response.data.error_items?.length > 0
            ) {
              setsuccessFailure("Error");
              seterrorItems(response.data.error_items);
            } else {
              setsuccessFailure("Success");
              setdocumentSuccessMsg(response.data.message);
              handleShowSuccess();
              setTimeout(() => {
                setfiles([]);
                handleCloseSuccess();
                // navigate("/actions");
              }, 2000);
            }
          } else {
            setsuccessFailure("Error");
            if (
              response.data.error_items &&
              response.data.error_items?.length > 0
            ) {
              seterrorItems(response.data.error_items);
            }
            setdocumentSuccessMsg(response.data.message);
            handleShowSuccess();
          }
        }
      } catch (error) {
        setIsFileUploading(false);
        console.log(error);
      }
    }
  };
  const getTypeDoc = (path) => {
    if (!path) {
      return "";
    }
    let type = path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return (
        <img
          src={window.location.origin + "/images/pdf.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/excel.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/doc.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }

    return (
      <img
        src={path}
        style={{
          width: "40px",
          height: "30px",
          margin: "5px",
          marginRight: "10px",
        }}
        alt="doc"
      />
    );
  };
  console.log(files, "files...");
  useEffect(() => {
    setloginData(JSON.parse(localStorage.getItem("mtm_user_data")));
  }, []);

  return (
    <>
      <Col sm={12} md={6} lg={6} className="py-4">
        <div className="d-flex align-items-center">
          <span className="fw-700 fs-22">Upload File -</span>
          <span
            className="grd-btn fs-20 rounded-pill px-5 py-1 ms-4 btn-file d-flex align-items-center"
            disabled={loadingPendingTags}
            onClick={() => {
              // getPendingDailyTags();
              inputRef.current.click();
            }}
            style={{ cursor: loadingPendingTags ? "not-allowed" : "pointer" }}
          >
            {loadingPendingTags ? (
              <Loader />
            ) : (
              <>
                <svg className="icon me-2" style={{ fontSize: "16px" }}>
                  <use href="#icon_plus"></use>
                </svg>
                <span>Upload</span>
              </>
            )}
          </span>
          <span className="fs-20 rounded-pill  ms-4 btn-file d-flex align-items-center">
            {/* <svg className="icon me-2" style={{ fontSize: "16px" }}>
              <use href="#icon_plus"></use>
            </svg>
            Upload{" "} */}
            <input
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ref={inputRef}
              onChange={handleChangeFileFun}
            />
          </span>
        </div>
        <div className="pt-3">
          <a href={`${window.location.origin}/${href}`} download>
            Download Example Document
          </a>
        </div>
        <div className="pb-3">
          {files.length > 0 && (
            <div className="border py-2 px-3 rounded-3 mt-4 d-flex align-items-center">
              <div className="d-flex align-items-center">
                {/* <div>
                  <svg className="icon fs-22">
                    <use href="#icon_pdf"></use>
                  </svg>
                </div> */}
                {getTypeDoc(files[0].name)}
                <div className="ms-3">
                  <p className="fw-400 fs-16">{files[0].name} </p>
                </div>
              </div>
              <div className="ms-auto">
                {/* <button
                  type="button"
                  className="tbl-edit me-5"
                  onClick={() => {
                    inputRef.current.click();
                  }}
                >
                  <svg className="icon fs-22">
                    <use href="#icon_edit"></use>
                  </svg>
                </button> */}
                <button
                  type="button"
                  className="tbl-delete"
                  onClick={() => {
                    handleShowVendorNew();
                  }}
                >
                  <svg className="icon fs-22">
                    <use href="#icon_delete"></use>
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </Col>
      <Col md={12} className="d-flex pt-1 pb-4">
        <div className="ms-auto">
          <button
            type="button"
            className="normal-btn rounded-pill me-4"
            onClick={() => {
              navigate("/actions");
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`submit-btn rounded-pill`}
            disabled={isFileUploading || files.length == 0}
            onClick={submitFn}
          >
            {isFileUploading ? <Loader /> : "Submit"}
          </button>
        </div>
      </Col>
      <Modal
        show={showSuccess}
        centered
        onHide={handleCloseSuccess}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <>
            {successFailure == "Error" ? (
              <div className="d-flex justify-content-center  text-danger pb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="55"
                  fill="currentColor"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            ) : (
              <div className="d-flex justify-content-center  text-success pb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="55"
                  fill="currentColor"
                  className="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
            )}
          </>
          <h3>{successFailure}</h3>
          <p>{documentSuccessMsg}</p>
          {successFailure == "Error" && (
            <>
              {errorItems?.length > 0 && (
                <p className="my-4">
                  {errorItems?.map((item) => (
                    <Row className="fs-18">
                      <Col md={4}>{item?.item_code}</Col>
                      <Col md={4}>{item?.pedimento_a4}</Col>
                      <Col md={4}>{item?.error}</Col>
                    </Row>
                  ))}
                </p>
              )}

              <p>
                <Button
                  variant="danger"
                  className="mt-1"
                  onClick={handleCloseSuccess}
                >
                  Close
                </Button>
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Document?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setfiles([]);
                handleCloseVendorNew();
              }}
            >
              {"Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showpendingTagPopup}
        onHide={() => {
          setshowpendingTagPopup(false);
        }}
        centered
        scrollable={true}
      >
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">Pending Tags Found</p>
          <div className="new-tbl">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-start">SKU</th>
                  <th className="text-start">Authorization Number</th>
                  <th className="text-start">Starting Tag</th>
                  <th className="text-start">Ending Tag</th>
                </tr>
              </thead>
              <tbody>
                {pendingTagItems?.map((item, index) => (
                  <tr>
                    <td>{item?.item_code}</td>
                    <td>{item?.tax_authority_no}</td>
                    <td>{item?.starting_tags}</td>
                    <td>{item?.ending_tags}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="fs-4 mt-2 mb-2">
            <strong>Disclaimer:</strong> If you submit then all these Marbete will be frozen and
            you can't report damage marbete for these SKUs.
          </div>
          {submittingPoError && (
            <div className="ms-auto text-danger text-center pb-2">
              {submittingPoError}
            </div>
          )}
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={() => {
                navigate("/manage-daily");
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={submittingPoList}
              onClick={() => {
                postDetails();
              }}
            >
              {submittingPoList ? <Loader /> : "Submit"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UploadComponent;
