import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";

function LayoutOutlet() {
    return(<>
        <NavBar></NavBar>
        <div className="wrapper">
            <Outlet></Outlet>
        </div>
    </>)
}
export default LayoutOutlet;