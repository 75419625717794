import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container,Row,Col,Tab,Tabs,Form,Modal,Button, } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Loader from "../components/Loader";
import { conditionalExp, getMomentFormatted, getUngroupedReturnOrders } from "../utils/commonFn";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import GroupReturnOrderModal from "../components/GroupReturnOrderModal";

function Group_return_orders() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleShow = (data) => {
      setShow(data)
    };
  
  
    const [isLoading, setisLoading] = useState(false);
    const [distribution, setdistribution] = useState([]);
  
    async function toggleExpensiveItems (e,id){
      try {
        const token = localStorage.getItem("mtm_user_token");
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}mtmdistribution/update/toggle_expensive_items`,
          { 
            id:id,
            contains_expensive_items:e.target.checked,
            isReturnOrder:true
          },
          {
            headers: {
              "Content-Type": "application/json",
              jwt_token: token,
            },
          }
        );
    
        if (!response?.data?.error) {
          return true;
        }
      } catch (err) {
        if (err.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/");
          return;
        }
        console.log(err);
      }
    }
    
    useEffect(() => {
        getUngroupedReturnOrders(setisLoading,setdistribution,navigate);
    }, []);
  return (
    <>
    <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
                <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="p-0 m-0 border-0 bg-white btn-back d-flex align-items-center fs-20"
                      onClick={() => navigate("/distribution_activities")}
                    >
                      <svg className="icon fs-24 me-2">
                        <use href="#icon_backarrow"></use>
                      </svg>
                      Back
                    </button>

                    <button
                        type="button"
                        className="btn-back d-flex align-items-center ms-auto"
                        onClick={() => navigate("/distribution-status")}
                    >
                        View Distribution Status
                        <svg className="icon fs-20 ms-2">
                            <use href="#icon_rightarrow"></use>
                        </svg>
                    </button>
                </div>
                <h2 className="text-center fw-700 fs-30">Group Return Orders</h2>
              <div className="py-5">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-nrml p-1 px-4 rounded-pill"
                    onClick={()=>handleShow({
                      modal_name:"Group Return Orders Based On Pickup Address",
                      ungropedOrders:distribution,
                    })}
                  >
                    Group Return Orders
                  </button>
                </div>

                <Col sm={12} className="new-tbl mt-4">
                  <table>
                    <tr>
                      <th>Order Number  </th>
                      <th>Contains Expensive Item </th>
                      <th>SKU</th>
                      <th>Total Quantity</th>
                      <th>Customer</th>
                      <th>Appointment</th>
                      <th>Pickup Address</th>
                    </tr>
                    {distribution?.map((item) => {
                      return (
                        <tr>
                          <td>
                            {item?.doc_number}
                          </td>
                          <td className="">
                            <input type="checkbox" className="form-check-input fs-24"
                            checked={item?.contains_expensive_items}
                            onChange={async e=>{
                              toggleExpensiveItems(e,item.id);
                              let data = distribution.map((i) => {
                                if (i.id == item.id) {
                                  i.contains_expensive_items = e.target.checked;
                                }
                                return i;
                              });
                              setdistribution(data);
                            }}
                            />
                          </td>
                          <td>{item?.skus}</td>
                          <td>{item?.total_qty}</td>
                          <td>{item?.organization_name}</td>
                          <td>{getMomentFormatted(item?.appointment_date,'DD/MM/YYYY | hh:mm A')}</td>
                          <td>{item?.address1 || item?.address2}</td>
                        </tr>
                      );
                    })}
                  </table>
                  {conditionalExp(
                    isLoading == false && distribution.length == 0,
                    <p className="w-full  text-center fs-1 py-5">
                      There are no orders found
                    </p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center py-5">
                      <Loader />
                    </div>
                  )}
                </Col>
              </div>
            </div>
          </Col>
        </Row>
    </Container>

      <GroupReturnOrderModal show={show} setShow={setShow} getGroups={()=>getUngroupedReturnOrders(setisLoading,setdistribution,navigate)} />
    </>
  )
}

export default Group_return_orders