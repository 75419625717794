import React from "react";

function Icondelete() {
  return (
    <>
      <svg className="icon fs-22">
        <use href="#icon_edit"></use>
      </svg>
    </>
  );
}

export default Icondelete;
