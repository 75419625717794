import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  PolylineF,
} from "@react-google-maps/api";

export default function Map({ loadError,movements,deliveryLocations,pickupLocations }) {
  const mapRef = useRef();
  if (loadError) {
    return <div>Error while loading maps</div>;
  }

  const options = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 0,

    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    icons: [
      {
        icon: {
          path: "M 0,-1 0,1",
          strokeOpacity: 1,
          scale: 4,
        },
        offset: "0",
        repeat: "20px",
      },
    ],
    zIndex: 1,
  };
  console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY,'map access token')
  return (
    <GoogleMap
      zoom={2}
      ref={mapRef}
      mapContainerStyle={{ width: "100%", height: "70vh", borderRadius: "10px" }}
      center={{
        lat: parseFloat(
          deliveryLocations[deliveryLocations?.length-1]
           ?.lat || 0.0
        ),
        lng: parseFloat(
          deliveryLocations[deliveryLocations?.length-1]
           ?.long || 0.0
        ),
      }}
    >
     
           {movements[movements?.length-1] && <MarkerF
               icon={{
                url: `https://maps.google.com/mapfiles/ms/icons/red-dot.png`,
                scaledSize: new window.google.maps.Size(32, 32), // Set the size of the marker icon
              }}
              position={{
                lat: parseFloat(movements[movements?.length-1]?.lat),
                lng: parseFloat(movements[movements?.length-1]?.long),
              }}
            />}

      
        <PolylineF
        path={movements.map((pin) => ({
          lat: parseFloat(pin?.lat),
          lng: parseFloat(pin?.long),
        }))}
        options={options}
      />

     
      {deliveryLocations?.map((deliveryLocation) => (
        <MarkerF
        key={deliveryLocation?.lat}
               icon={{
                url: `https://maps.google.com/mapfiles/ms/icons/green-dot.png`,
                scaledSize: new window.google.maps.Size(32, 32), // Set the size of the marker icon
              }}
              position={{
                lat: parseFloat(deliveryLocation?.lat),
                lng: parseFloat(deliveryLocation?.long),
              }}
            />
      ))}

        {pickupLocations?.map((pickupLocation) => (
        <MarkerF
        key={pickupLocation?.lat}
               icon={{
                url: `https://maps.google.com/mapfiles/ms/icons/yellow-dot.png`,
                scaledSize: new window.google.maps.Size(32, 32), // Set the size of the marker icon
              }}
              position={{
                lat: parseFloat(pickupLocation?.lat),
                lng: parseFloat(pickupLocation?.long),
              }}
            />
      ))}
    </GoogleMap>
  );
}
