import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Processing from "../components/Processing";

function Rejection2() {
  const [yesNo, setYesNo] = useState("no");
  const [reason, setreason] = useState("");
  const [error, seterror] = useState(false);
  const [showVendorName, setshowVendorName] = useState(false);
  const handleCloseVendorName = () => setshowVendorName(false);
  const handleShowVendorName = () => setshowVendorName(true);
  const [loadingCompleted, setloadingComplted] = useState(false);
  const [submitting, setsubmitting] = useState(false);

  // =====
  const params = useParams();
  const location = useLocation();
  console.log(location, "location...");
  const { order_uniq_id, vendor_code, vendor_name } = params;
  console.log(params, "params");
  const navigate = useNavigate();

  const getUrl = () => {
    let path = location.pathname;
    if (path.includes("afterpo")) {
      return `everest/po/reject`;
    } else if (path.includes("rejection")) {
      return `everest/vendor/order/reject`;
    } else {
      return `everest/domestic/po/reject`;
    }
  };

  const postVenderRejectList = async () => {
    if (reason?.length == 0 && yesNo == "no") {
      seterror(true);

      return;
    }
    seterror(false);
    try {
      setsubmitting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}${getUrl()}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          reject_reason: yesNo == "yes" ? "payment" : reason,
        },
      });
      if (response.status == 200) {
        setsubmitting(false);
        if (!response.data.error) {
          navigate("/saved", {
            replace: true,
            state: {
              message: "We have saved your response",
            },
          });
        } else {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const getUrlStatus = () => {
    let path = location.pathname;
    if (path.includes("afterpo")) {
      return `everest/po/status`;
    } else if (path.includes("rejection")) {
      return `everest/vendor/order/update`;
    } else {
      return `everest/domestic/po/status`;
    }
  };
  const postVenderOrderList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}${getUrlStatus()}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloadingComplted(true);
        } else {
          setloadingComplted(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    postVenderOrderList();
  }, []);

  return (
    <>
      {loadingCompleted ? (
        <>
          <Container>
            <Row>
              <Col md={12}>
                <div className="wrapper-box">
                  <h2 className="text-center fw-700 fs-30">
                    Reason For Rejection
                  </h2>
                </div>
                <div className="content-wrapper">
                  <form>
                    <div className="mb-3 py-3">
                      <p>Please Choose your reason for rejection</p>
                      <div className="form-check pt-3 pb-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          onClick={() => setYesNo("yes")}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Payment
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onClick={() => setYesNo("no")}
                          checked={yesNo == "no"}
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          Other Reason
                        </label>
                      </div>
                    </div>
                    {yesNo == "no" && (
                      <div className="mb-3 border-bottom py-3">
                        <input
                          type="text"
                          className=" w-100"
                          placeholder="Enter your reason"
                          value={reason}
                          onChange={(e) => {
                            setreason(e.target.value);
                            seterror(false);
                          }}
                        />
                      </div>
                    )}
                    {error && (
                      <div className="mt-2 text-danger ">
                        Please enter your reason
                      </div>
                    )}
                    <div className="d-flex py-4">
                      <div className="ms-auto">
                        <button
                          type="button"
                          className="py-2 px-4 grd-btn fs-20 rounded-pill fw-600 text-center w-auto"
                          onClick={() => {
                            if (reason?.length == 0 && yesNo == "no") {
                              seterror(true);

                              return;
                            }
                            handleShowVendorName();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
          <Modal show={showVendorName} onHide={handleCloseVendorName} centered>
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body className="p-4">
              <p className="fs-4 text-center m-0 p-0">
                Please reconfirm your submission
              </p>
              <div className="input-box mb-4 mt-2 p-0">
                <input
                  type="text"
                  className="w-100"
                  placeholder="Enter Description"
                  id="vender_code"
                  value={`${vendor_name} (${vendor_code})`}
                />
              </div>
              <div className="d-flex justify-content-end mt-2">
                <div>
                  <button
                    type="button"
                    disabled={submitting}
                    className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                    onClick={() => {
                      postVenderRejectList();
                    }}
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Processing />
        </>
      )}
    </>
  );
}

export default Rejection2;
