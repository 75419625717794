import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import save from "./srcimages/save.png";
import Processing from "../components/Processing";

function PartialReject() {
  const [venderOrderListArray, setvenderOrderListArray] = useState([]);

  const [deleteData, setdeleteData] = useState({
    order_id: "",
    path: "",
  });
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const [showVendorNew, setShowVendorNew] = useState(false);
  const [loadingCompleted, setloadingComplted] = useState(false);
  const [uploadedItem, setuploadedItem] = useState({});
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [vendorListTotal, setvendorListTotal] = useState({});
  const navigate = useNavigate();
  const [showVendorName, setshowVendorName] = useState(false);
  const handleCloseVendorName = () => setshowVendorName(false);
  const handleShowVendorName = () => setshowVendorName(true);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [submitError, setsubmitError] = useState({
    status: false,
    message: "",
  });
  console.log(seterror, seterrorMessage, vendorListTotal);

  const params = useParams();
  const { order_uniq_id, vendor_code, vendor_name } = params;
  const handleUPCChange = (e, group_code) => {
    const updatedData = venderOrderListArray.map((item) => {
      if (item.group_code === group_code) {
        return {
          ...item,
          upc_code: e.target.value,
        };
      }
      return item;
    });
    setvenderOrderListArray(updatedData);
  };
  const postSubmitButton = async () => {
    setisSubmitting(true);
    setsubmitError({
      status: false,
      message: "",
    });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/orderlist/submit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          orders: venderOrderListArray,
        },
      });

      if (response.status == 200) {
        if (!response.data.error) {
          postFinalSubmit();
        } else {
          setsubmitError({
            status: true,
            message: response.data.message,
          });
          setisSubmitting(false);
        }
      }
    } catch (error) {
      setisSubmitting(false);
      console.log(error);
    }
  };
  const postFinalSubmit = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/order/accept`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          navigate("/saved", {
            replace: true,
            state: {
              message: "We have saved your response",
            },
          });
        } else {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const handleUploadLabel = async (e) => {
    const files = Array.from(e.target.files);

    if (files?.length > 0) {
      const formdata = new FormData();
      formdata.append("order_uniq_id", order_uniq_id);
      formdata.append("vendor_code", vendor_code);
      formdata.append("sap_code", uploadedItem?.sap_code);
      formdata.append(
        "upc_code",
        uploadedItem?.upc_code ? uploadedItem?.upc_code : ""
      );
      formdata.append("order_id", uploadedItem?.order_id);

      for (const element of files) {
        formdata.append("files", element, element?.name);
      }

      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}everest/vendor/orderlist/addlabel`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formdata,
        });
        if (response.status == 200) {
          postGettingList();
        }
      } catch (error) {
        console.log(error);
      }
    }
    e.target.value = null; // Reset the file input value
  };

  const postGettingList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/orderlist`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          orders: venderOrderListArray,
        },
      });

      if (response.status == 200) {
        setvendorListTotal(response.data);
        setvenderOrderListArray(response?.data?.orders);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLableFn = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/orderlist/label/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          order_id: deleteData?.order_id,
          path: deleteData?.path,
        },
      });

      if (response.status == 200) {
        if (response.data.error) {
          alert("error");
        } else {
          handleCloseVendorNew();
          postGettingList();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postVenderOrderList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/order/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloadingComplted(true);
          postGettingList();
        } else {
          setloadingComplted(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    postVenderOrderList();
  }, []);

  return (
    <>
      {!loadingCompleted ? (
        <Processing />
      ) : (
        <>
          {error ? (
            <>
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="wrapper-box">
                      <div className="d-flex justify-content-center">
                        <div style={{ width: "420px" }}>
                          <img className="w-100" src={save} />
                        </div>
                      </div>

                      <p className="fw-bold fs-3 text-center py-4">
                        {errorMessage}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="wrapper-box">
                      <h2 className="text-center fw-700 fs-30">
                        {vendor_name} ({vendor_code})
                      </h2>
                    </div>
                    <div className="modify-tbl">
                      <table className="table text-center border my-4">
                        <tbody>
                          <tr>
                            <th>Serial Number</th>
                            <th>SKU</th>
                            <th>UPC</th>
                            <th>
                              Upload Label
                              <br />
                              (Can add multiple Labels)
                            </th>
                          </tr>
                          {venderOrderListArray?.map((item, index) => (
                            <tr key={item?.description}>
                              <td>{index + 1}</td>
                              <td>
                                {item.sap_code}
                                <p>{item?.description}</p>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  id="upc_code"
                                  value={item.upc_code}
                                  onChange={(e) =>
                                    handleUPCChange(e, item.group_code)
                                  }
                                />
                              </td>
                              <td>
                                <label
                                  for="files_new"
                                  className="fw-bold"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    setuploadedItem(item);
                                  }}
                                >
                                  Select Lables
                                </label>

                                <input
                                  type="file"
                                  id="files_new"
                                  style={{ display: "none" }}
                                  multiple
                                  accept=".jpg, .jpeg, .png"
                                  onChange={(e) => {
                                    handleUploadLabel(e);
                                  }}
                                />

                                {item?.lables.map((file) => (
                                  <>
                                    <div className="pb-1 ">
                                      <div className="image-container dotted-line">
                                        <img src={file} alt="Image" />
                                        <button
                                          type="button"
                                          className="tbl-delete"
                                          onClick={() => {
                                            setdeleteData({
                                              order_id: item?.order_id,
                                              path: file,
                                            });
                                            handleShowVendorNew();
                                          }}
                                        >
                                          <svg className="icon fs-18">
                                            <use href="#icon_delete"></use>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="ml-2">
                        <button
                          type="button"
                          className="mt-2 py-2 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                          onClick={() => handleShowVendorName()}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Modal
                show={showVendorNew}
                onHide={handleCloseVendorNew}
                centered
              >
                <Modal.Body className="p-4">
                  <p className="fs-4 text-center">
                    Are you sure you want to delete this Label?
                  </p>
                  <div className="d-flex justify-content-center mt-2">
                    <Button
                      variant="secondary"
                      className="me-3"
                      onClick={handleCloseVendorNew}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteLableFn();
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={showVendorName}
                onHide={handleCloseVendorName}
                centered
              >
                <Modal.Header
                  closeButton
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="p-4">
                  <p className="fs-4 text-center m-0 p-0">
                    Please reconfirm your submission
                  </p>
                  <div className="input-box mb-4 mt-2 p-0">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Enter Description"
                      id="vender_code"
                      value={`${vendor_name} (${vendor_code})`}
                    />
                  </div>
                  {submitError.status && (
                    <div className="d-flex justify-content-end mt-2 text-danger">
                      <div>{submitError.message}</div>
                    </div>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    <div>
                      <button
                        type="button"
                        disabled={isSubmitting}
                        className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                        onClick={() => {
                          postSubmitButton();
                        }}
                      >
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
}

export default PartialReject;
