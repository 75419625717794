import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Afteremail() {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <Row>
                <Col sm={12}>
                  <div className="top-80px mt-4">
                    <table>
                      <tr>
                        <td>
                          <p className="pb-4">
                            <strong>Name:</strong> lorem ipsum
                          </p>
                          <p className="pb-4">
                            <strong>Subject:</strong> Lorem ipsum dolor sit amet
                          </p>
                          <p className="pb-4">Hello, Vendor name </p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Donec tortor lorem, blandit a libero iaculis,
                            imperdiet facilisis dui. Duis tristique varius
                            purus, at facilisis sem luctus eget. Duis
                            consectetur elit nunc, ac sollicitudin massa
                            elementum sed. Praesent tempus pharetra metus, vel
                            aliquet libero tempus quis. Pellentesque accumsan
                            dolor eu mauris egestas, vel vestibulum quam dictum.
                            Vivamus lacus quam, tincidunt vitae mi sed,
                            elementum volutpat nisi. Proin a nulla eu eros
                            suscipit venenatis vel quis dui. Praesent egestas
                            finibus massa. Aliquam nisl lectus, eleifend in
                            faucibus eu, fringilla id neque. Pellentesque id
                            tincidunt massa, ac condimentum magna. Proin
                            facilisis egestas augue nec tristique. Aenean
                            pretium diam at aliquam mollis. Praesent tempor
                            ipsum ac nulla viverra, quis egestas odio dictum
                            Duis consectetur elit nunc, ac sollicitudin massa
                            elementum sed. Praesent tempus pharetra metus, vel
                            aliquet libero tempus quis. Pellentesque accumsan
                            dolor eu mauris egestas, vel vestibulum quam dictum.
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Donec tortor lorem, blandit a libero iaculis,
                            imperdiet facilisis dui. Duis tristique varius
                            purus, at facilisis sem luctus eget.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </Col>
                <Col sm={12} className="normal-tbl mt-4">
                  <table>
                    <tr>
                      <th>Serial Number</th>
                      <th>SKU</th>
                      <th>Description</th>
                      <th>PO Quantity</th>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>555</td>
                      <td>Aone</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>555</td>
                      <td>Aone</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>555</td>
                      <td>Aone</td>
                      <td>100</td>
                    </tr>
                  </table>
                </Col>
                <Col sm={12} className="d-flex py-5">
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-success px-5 rounded-pill"
                      onClick={() => navigate("/acceptpo")}
                    >
                      Accept
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger ms-4 px-5 rounded-pill"
                      onClick={() => navigate("/rejection")}
                    >
                      Reject
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Afteremail;
