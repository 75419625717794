import React from "react";

function IconFileClose() {
  return (
    <>
      <svg className="icon" style={{ width: "20px", height: "20px" }}>
        <use href="#icon_fileclose"></use>
      </svg>
    </>
  );
}

export default IconFileClose;
