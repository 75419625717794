import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Choose_activity() {
  const navigate = useNavigate();

  return (
    <>
        <Container className="vh-100">
            <div className="row w-100">
                <Row>
                    <Col md={12}>
                        <div className="wrapper-box">
                            <h2 className="text-center fw-700 fs-30">Choose Activity</h2>
                        </div>
                    </Col>
                </Row>
                <div className="row rows-cols-1 row-cols-md-3 d-flex justify-content-center g-4 py-4">
                    <div className="col">
                        <button
                            type="button"
                            className="action-btn w-100" onClick={() => navigate("/company-list")}>
                                Warehouse
                        </button>
                    </div>
                    <div className="col">
                        <button
                            type="button"
                            className="action-btn w-100 h-100" onClick={() => navigate("/distribution_activities")}>
                            Distribution
                        </button>
                    </div>
                </div>
            </div>
        </Container>

    </>
  )
}

export default Choose_activity