import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Loader from "../components/Loader";
import {
  conditionalExp,
  getMomentFormatted,
  getUngroupedOrders,
} from "../utils/commonFn";
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import GroupOrdersModal from "../components/GroupOrdersModal";
import { DatePicker, Space } from 'antd';
import moment from "moment";
import dayjs from "dayjs";

function Group_invoice() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const handleShow = (data) => {
    setShow(data);
  };
  const [selectedDate, setSelectedDate] = useState()
  const [email, setemail] = useState()
const [submitLoading, setSubmitLoading] = useState(false)
  function handleCloseAppointment() {
    setShowAppointmentModal(false);
    setSelectedDate();
    setemail()
  }

  const [isLoading, setisLoading] = useState(false);
  const [distribution, setdistribution] = useState([]);

  async function toggleExpensiveItems(e, id) {
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}mtmdistribution/update/toggle_expensive_items`,
        {
          id: id,
          contains_expensive_items: e.target.checked,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        return true;
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }
  }
  async function updateAppointementDate(e) {
    e?.preventDefault()
    setSubmitLoading(true)
    try {
      const token = localStorage.getItem("mtm_user_token");
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}mtmdistribution/update/appointment_datetime`,
        {
          id: showAppointmentModal,
          appointment_date: selectedDate?.format('YYYY-MM-DD HH:mm:ss'),
          customer_email:email
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
       await getUngroupedOrders(setisLoading, setdistribution, navigate);
        handleCloseAppointment();
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
    }finally{
      setSubmitLoading(false)
    }
  }

  useEffect(() => {
    getUngroupedOrders(setisLoading, setdistribution, navigate);
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  className="p-0 m-0 border-0 bg-white btn-back d-flex align-items-center fs-20"
                  onClick={() => navigate("/distribution_activities")}
                >
                  <svg className="icon fs-24 me-2">
                    <use href="#icon_backarrow"></use>
                  </svg>
                  Back
                </button>

                <button
                  type="button"
                  className="btn-back d-flex align-items-center ms-auto"
                  onClick={() => navigate("/distribution-status")}
                >
                  View Distribution Status
                  <svg className="icon fs-20 ms-2">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
              </div>
              <h2 className="text-center fw-700 fs-30">Group Orders</h2>
              <div className="py-5">
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn-nrml p-1 px-4 rounded-pill"
                    onClick={() =>
                      handleShow({
                        modal_name: "Group Orders Based On Delivery Address",
                        ungropedOrders: distribution,
                      })
                    }
                  >
                    Group Orders
                  </button>
                </div>

                <Col sm={12} className="new-tbl mt-4">
                  <table>
                    <tr>
                      <th>Order Number </th>
                      <th>Contains Expensive Item </th>
                      <th>SKU</th>
                      <th>Total Quantity</th>
                      <th>Customer</th>
                      <th>Appointment</th>
                      <th>Delivery Address</th>
                    </tr>
                    {distribution?.map((item) => {
                      return (
                        <tr>
                          <td>{item?.doc_number}</td>
                          <td className="">
                            <input
                              type="checkbox"
                              className="form-check-input fs-24"
                              checked={item?.contains_expensive_items}
                              onChange={async (e) => {
                                toggleExpensiveItems(e, item.id);
                                let data = distribution.map((i) => {
                                  if (i.id == item.id) {
                                    i.contains_expensive_items =
                                      e.target.checked;
                                  }
                                  return i;
                                });
                                setdistribution(data);
                              }}
                            />
                          </td>
                          <td>{item?.skus}</td>
                          <td>{item?.total_qty}</td>
                          <td>{item?.organization_name}</td>
                          <td>
                            {conditionalExp(
                              item?.appointment_date,
                              <button onClick={()=>{setShowAppointmentModal(item?.id);
                              setSelectedDate(dayjs(item?.appointment_date))
                              setemail(item?.customer_email || "")
                              }} type="button" className="tbl-edit me-2 ">
                                <svg className="icon fs-22">
                                  <use href="#icon_edit"></use>
                                </svg>
                              </button>,
                              <button onClick={()=>{setShowAppointmentModal(item?.id);
                              setemail(item?.customer_email || "")
                              }} type="button" className="tbl-edit me-2 ">
                                <svg className="icon fs-22">
                                  <use href="#icon_plus"></use>
                                </svg>
                              </button>
                            )}
                            {getMomentFormatted(
                              item?.appointment_date,
                              "DD/MM/YYYY | hh:mm A"
                            )}
                          </td>
                          <td>{item?.address1 || item?.address2}</td>
                        </tr>
                      );
                    })}
                  </table>
                  {conditionalExp(
                    isLoading == false && distribution.length == 0,
                    <p className="w-full  text-center fs-1 py-5">
                      There are no orders found
                    </p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center py-5">
                      <Loader />
                    </div>
                  )}
                </Col>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <GroupOrdersModal
        show={show}
        setShow={setShow}
        getGroups={() =>
          getUngroupedOrders(setisLoading, setdistribution, navigate)
        }
      />
      <Modal show={showAppointmentModal} size="lg" centered onHide={handleCloseAppointment}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="text-center w-100">
            Appointment Datetime
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={updateAppointementDate}>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 g-4 ">
                <div className="col">
                  <label className="form-label">Appointment Datetime</label>
                <Space direction="vertical">
                <DatePicker popupClassName="datepicker" showTime needConfirm size="large" value={selectedDate} onChange={(e)=>setSelectedDate(e)}/>
              </Space>
                </div>
                <div className="col">
                  <label className="form-label">Verification Code Email</label>
                <input required  type="email" placeholder="Enter Verification Code Email" className="form-control"  value={email} onChange={(e)=>setemail(e.target.value)}/>
                </div>
                
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button
                type="submit"
                className="accor-btn px-5"
                disabled={ !selectedDate || !email }
              >
                {submitLoading ? <Loader /> : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Group_invoice;
