import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
function ManageDaily() {
  const navigate = useNavigate();
  const [showopen, setShowopen] = useState(false);
  const handleCloseopen = () => setShowopen(false);
  const [accordion, setAccordion] = useState([]);
  const [skuModalData, setSkuModalData] = useState({});
  const handleShowopen = () => {
    setShowopen(true);
  };
  const [showEyeIcon, setshowEyeIcon] = useState(false);
  const handleShowopenEye = (data) => {
    setshowEyeIcon(true);
    setSkuModalData(data);
  };
  const handleShowcloseEye = () => {
    setshowEyeIcon(false);
  };
  const [showEyeIcondamage, setshowEyeIcondamage] = useState(false);
  const handleShowopenEyedamage = (data) => {
    setshowEyeIcondamage(true);
    setSkuModalData(data);
  };
  const handleShowcloseEyedamage = () => {
    setshowEyeIcondamage(false);
  };
  const [confirmationPopup, setconfirmationPopup] = useState(false);
  const handleShowPopup = () => {
    setconfirmationPopup(true);
  };
  const handleClosePopup = () => {
    setconfirmationPopup(false);
  };
  const [gettingDetails, setgettingDetails] = useState(false);
  const [details, setdetails] = useState([]);
  const [submittingPoList, setsubmittingPoList] = useState(false);
  const [submittingPoError, setsubmittingPoError] = useState(null);
  const marbeteRef = useRef();
  const [selectedMarbeteValues, setselectedMarbeteValues] = useState([]);
  const [errorMessageFalse, seterrorMessageFalse] = useState({});
  const [errorMessageFalseArray, seterrorMessageFalseArray] = useState([]);
  const [selectedwarehouseId, setselectedwarehouseId] = useState("");
  const [wareHouses, setwareHouses] = useState([]);
  const [selectedTagRange, setselectedTagRange] = useState(true);
  const [startingRange, setstartingRange] = useState("");
  const [endingRange, setendingRange] = useState("");
  const damageAddRef = useRef();
  console.log(selectedMarbeteValues, "slsls");
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const getTodayDateNormal = (date) => {
    const today = new Date(date);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(today.getDate()).padStart(2, "0");
    return `${month}-${day}-${year}`;
  };
  const [itemsMessage, setitemsMessage] = useState("");
  const [selectedDate, setSelectedDate] = useState(getTodayDate());
  const [selectedItem, setselectedItem] = useState({});
  const [editSave, seteditSave] = useState(true);
  const [loginData, setloginData] = useState({});

  const checkItemOldOrNew = (selectedItem, value) => {
    let damage_marbete_number_old = selectedItem.damage_marbete_number || "";
    let damage_marbete_number_oldArray =
      damage_marbete_number_old?.split(",") || [];

    if (damage_marbete_number_oldArray?.includes(value)) {
      return false;
    } else {
      return true;
    }
  };
  const checkNewlyAdded = (selectedItem, selectedMarbeteValues) => {
    // only need count of newly added
    let newItems = selectedMarbeteValues.filter((item) => {
      return checkItemOldOrNew(selectedItem, item.value);
    });
    return newItems.length;
  };
  // console.log(
  //   checkNewlyAdded(selectedItem, selectedMarbeteValues),
  //   "newly added length"
  // );
  // let remaining =
  //   selectedItem?.priority_tags_qty -
  //   checkNewlyAdded(selectedItem, selectedMarbeteValues);
  // console.log(remaining, "remaining...");
  // let start = parseInt(startingRange);
  // let end = parseInt(endingRange);
  // let diff = 0;
  // if(start==end){
  //   diff = end - start;
  // }else{
  //   diff = end - start + 1;
  // }
  // console.log(diff, "diff");
  const getDetails = async (date, warehouse_id) => {
    seterrorMessageFalseArray([]);
    seterrorMessageFalse({});
    try {
      setgettingDetails(false);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/daily/sku/tag/itemlist`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          date: date,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          warehouse_id: warehouse_id,
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
          company_name: localStorage.getItem("company_name"),
        },
      });
      if (response.status == 200) {
        getWareHouses();
        setgettingDetails(true);
        if (!response.data.error) {
          // console.log(response.data, "res data");
          let data = response.data.items;
          let items = data?.map((item) => {
            return {
              ...item,
              damage_marbete_number_new: item.damage_marbete_number || "",
              damage_tag_qty_new: item.damage_tag_qty || "",
              priority_tags_qty_new: item.priority_tags_qty || 0,
            };
          });

          setdetails(items || []);
          setitemsMessage(response.data.message);
        } else {
          setdetails([]);
        }
      }
    } catch (error) {
      console.log(error);
      setgettingDetails(true);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  console.log(errorMessageFalseArray, "error red");
  const postDetails = async () => {
    seterrorMessageFalseArray([]);
    seterrorMessageFalse({});
    setsubmittingPoError(null);
    setsubmittingPoList(true);
    let arraydata = details
      .filter(
        (item) =>
          (item.tags_qty_new && item.tags_qty_new != "") ||
          (item.starting_tags && item.starting_tags != "") ||
          (item.ending_tags && item.ending_tags != "")
      )
      .map((item) => {
        let damage_marbete_number_old = item.damage_marbete_number || "";
        let damage_marbete_number_oldArray =
          damage_marbete_number_old?.split(",") || [];
        let damage_marbete_number_newArray =
          item.damage_marbete_number_new?.split(",") || [];
        let damage_marbete_number_new = damage_marbete_number_newArray.filter(
          (item) => {
            return damage_marbete_number_oldArray.indexOf(item) == -1;
          }
        );
        let damage_marbete_number_newJoin =
          damage_marbete_number_new?.join(",") || "";
        return {
          item_code: item.item_code,
          tags_qty: parseInt(item.tags_qty_new) || null,
          damage_marbete_number: damage_marbete_number_newJoin || "",
          damage_tag_qty: damage_marbete_number_new?.length || null,
          starting_tags: parseInt(item?.starting_tags) || null,
          ending_tags: parseInt(item?.ending_tags) || null,
          custom_declration_number: item?.custom_declration_number,
          extraction_date: item?.extraction_date,
          tags_priority: item?.tags_priority,
        };
      });
    let data = {
      user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
      customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
        ?.customer_id,
      company_name: localStorage.getItem("company_name"),
      items: arraydata,
      priority_date: selectedDate,
      warehouse_id: selectedwarehouseId,
    };
    console.log(data, "data");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/daily/sku/tag/tagQty/submit`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: data,
      });
      if (response.status == 200) {
        setsubmittingPoList(false);
        handleClosePopup();
        if (!response.data.error) {
          // navigate("/actions");
          if (
            response?.data?.error_items &&
            response?.data?.error_items?.length > 0
          ) {
            let data = response?.data?.error_items;
            let array = [];
            for (const element of data) {
              array.push(element?.item_code);
            }
            seterrorMessageFalseArray(array || []);
            seterrorMessageFalse(response?.data?.error_items || {});
          } else {
            seteditSave(true);
            getDetails(selectedDate, selectedwarehouseId);
          }
        } else {
          setsubmittingPoError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setsubmittingPoList(false);
      setsubmittingPoError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  console.log(selectedMarbeteValues, "selectedMarbeteValues");
  const postDetailsDamageQty = async (item, string, length) => {
    // seterrorMessageFalseArray([]);
    // seterrorMessageFalse({});
    // setsubmittingPoError(null);
    setsubmittingPoList(true);
    let selectedItem = details?.find((detail) => {
      return detail.id == item.id;
    });
    console.log(selectedItem, "selectedItem id...");
    let data = {
      damage_marbete_number: string || "",
      damage_tag_qty: length || null,
      id: item?.id,
    };
    console.log(data, "data");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/daily/sku/tag/damageQty/update`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: data,
      });
      if (response.status == 200) {
        setsubmittingPoList(false);
        if (!response.data.error) {
          handleCloseopen();
          getDetails(selectedDate, selectedwarehouseId);
        } else {
          setsubmittingPoError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setsubmittingPoList(false);
      setsubmittingPoError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const getWareHouses = () => {
    let wareHouses = JSON.parse(
      localStorage.getItem("mtm_user_data")
    )?.warehouses;
    wareHouses = wareHouses?.filter(
      (item) => item?.company_name == localStorage.getItem("company_name")
    );
    setwareHouses(wareHouses);
  };
  const convertDateFormat = (selectedDate) => {
    const dateObj = new Date(selectedDate);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month because it's zero-based
    const year = dateObj.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };
  const tagsCheck = (item) => {
    return (
      parseInt(item?.priority_tags_qty || 0) -
        parseInt(item?.tags_qty || 0) -
        parseInt(item?.tags_qty_new || 0) <
      0
    );
  };
  const checkStartingEndingTags = (item) => {
    return item?.tags_qty_new && item?.tags_qty_new != "";
  };
  const checkDisabled = () => {
    for (const element of details) {
      if (tagsCheck(element)) {
        return true;
      }
      if (
        checkStartingEndingTags(element) &&
        (!element?.starting_tags || element?.starting_tags == "")
      ) {
        return true;
      }
      if (
        checkStartingEndingTags(element) &&
        (!element?.ending_tags || element?.ending_tags == "")
      ) {
        return true;
      }
      if (
        element?.ending_tags &&
        element?.ending_tags != "" &&
        parseInt(element?.ending_tags) < parseInt(element?.starting_tags)
      ) {
        return true;
      }
    }
    return false;
  };
  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    // Call getDetails function with the selected date when the date changes
    // getDetails(newDate);
  };
  console.log(details, "details");
  useEffect(() => {
    setloginData(JSON.parse(localStorage.getItem("mtm_user_data")));
    getWareHouses();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      if (selectedDate && selectedwarehouseId) {
        getDetails(selectedDate, selectedwarehouseId);
      }
    }
  }, [selectedwarehouseId, selectedDate]);

  console.log(skuModalData, "skuModalData...");

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-between align-items-center">
                <BackButton navi="actions" />
                <div>
                  <h2 className="text-center fw-700 fs-30">
                    <div>{localStorage.getItem("company_name")}</div>
                    Manage Daily Tagged SKUs For
                  </h2>
                  <h2 className="text-center fw-700 fs-30 pt-2">
                    {convertDateFormat(selectedDate)}
                  </h2>
                </div>
                <div></div>
              </div>
            </div>
          </Col>

          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="fs-20 pb-1">Select Date</h4>
                <input
                  type="date"
                  className="form-control"
                  value={selectedDate}
                  onChange={handleDateChange}
                  max={getTodayDate()} // Restrict future dates
                />
              </div>
              <div className="px-2">
                <h4 className="fs-20 pb-1">Please choose warehouse</h4>
                <div className="">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    style={{ width: "200px" }}
                    value={selectedwarehouseId}
                    onChange={(e) => setselectedwarehouseId(e.target.value)}
                  >
                    <option value="">Choose Warehouse</option>
                    {wareHouses?.map((item, index) => (
                      <option value={item?.warehouse_id} key={index}>
                        {item?.warehouse_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="mt-4">
              {gettingDetails && details?.length != 0 && (
                <button
                  type="button"
                  className="edit-btn "
                  style={{ borderRadius: "4px" }}
                  onClick={() => {
                    seteditSave(!editSave);
                  }}
                >
                  {editSave ? "Edit" : "View"}
                </button>
              )}
            </div>
          </div>
          {!gettingDetails && selectedwarehouseId != "" && (
            <div className="w-100 mt-5 text-center">
              <Loader />
            </div>
          )}
          {gettingDetails &&
            details?.length == 0 &&
            selectedwarehouseId != "" && (
              <div className="w-100 mt-5 text-center fw-600 fs-30">
                No Items found for {convertDateFormat(selectedDate)}
              </div>
            )}
          {selectedwarehouseId == "" && (
            <div className="w-100 mt-5 text-center fw-500 fs-30">
              Please Choose Warehouse
            </div>
          )}
        </Row>
        <Row>
          {details?.length > 0 &&
            gettingDetails &&
            selectedwarehouseId != "" && (
              <>
                <Col>
                  <div
                    className="modify-tbl"
                    style={{
                      overflowX: "scroll",
                    }}
                  >
                    <table className="table text-center border my-4">
                      <tbody>
                        <tr>
                          <th>SKU</th>
                          <th>Description</th>
                          <th>Custom Declaration Number</th>
                          <th>Tag Priority</th>
                          <th>Requested Quantity</th>
                          <th></th>
                          <th>Starting Tag</th>
                          <th>Ending Tag</th>
                          {/* <th>First Damage Quantity </th> */}
                          <th>Missing/Damaged Marbete</th>
                        </tr>
                        {details.map((item, index) => (
                          <tr>
                            <td
                              style={{
                                color:
                                  errorMessageFalseArray.includes(
                                    item?.item_code
                                  ) && "red",
                              }}
                            >
                              {item?.item_code}
                            </td>
                            <td>{item?.item_description}</td>
                            <td>{item?.custom_declration_number}</td>
                            <td>{item?.tags_priority}</td>
                            <td>{item?.priority_tags_qty}</td>

                            <td>
                              <button
                                type="button"
                                className="p-0 m-0 border-0"
                                onClick={() => handleShowopenEye(item)}
                                style={{ background: "none" }}
                              >
                                <svg className="icon fs-18">
                                  <use href="#icon_eye"></use>
                                </svg>
                              </button>
                            </td>
                            <td>{item?.starting_tags}</td>
                            <td>{item?.ending_tags}</td>
                            {/* <td>
                              <span className="mr-2">
                                {item?.damage_tag_qty}
                              </span>
                              <span className="ml-2 pl-2">
                                {item?.damage_tag_qty > 0 && (
                                  <button
                                    type="button"
                                    className="p-0 m-0 border-0"
                                    onClick={() =>
                                      handleShowopenEyedamage(item)
                                    }
                                    style={{ background: "none" }}
                                  >
                                    <svg className="icon fs-18">
                                      <use href="#icon_eye"></use>
                                    </svg>
                                  </button>
                                )}
                              </span>
                            </td> */}
                            <td>
                              <div className="eye-btn2">
                                <button
                                  type="button"
                                  disabled={editSave}
                                  className="p-0 m-0 border-0"
                                  onClick={() => {
                                    setselectedItem(item);
                                    if (
                                      !item.damage_marbete_number_new ||
                                      item.damage_marbete_number_new == ""
                                    ) {
                                      setselectedMarbeteValues([]);
                                    } else {
                                      let array =
                                        item.damage_marbete_number_new.split(
                                          ","
                                        );
                                      let pushArray = [];
                                      for (let i = 0; i < array.length; i++) {
                                        let obj = {};
                                        obj["id"] = i;
                                        obj["value"] = array[i];
                                        pushArray.push(obj);
                                      }
                                      setselectedMarbeteValues(pushArray);
                                    }
                                    setselectedTagRange(false);
                                    setstartingRange("");
                                    setendingRange("");
                                    handleShowopen();
                                  }}
                                >
                                  {item?.damage_tag_qty_new ? (
                                    <svg className="icon fs-18">
                                      <use href="#icon_edit"></use>
                                    </svg>
                                  ) : (
                                    <svg className="icon fs-18">
                                      <use href="#icon_plus"></use>
                                    </svg>
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
                {!editSave && (
                  <Col
                    md={12}
                    className="d-flex py-3"
                    style={{
                      background: "white",
                      position: "sticky",
                      bottom: 0,
                    }}
                  >
                    <div className="ms-auto">
                      {submittingPoError && (
                        <div className="ms-auto text-danger pb-2">
                          {submittingPoError}
                        </div>
                      )}
                      {errorMessageFalseArray.length > 0 && (
                        <div className="ms-auto text-danger pb-2">
                          This Item code not have tags information
                        </div>
                      )}
                      {/* {details &&
                        details.length > 0 &&
                        details[0]?.priority_tags_qty !=
                          details[0]?.tags_qty && ( */}
                      <div>
                        <button
                          type="button"
                          className="normal-btn rounded-pill me-4"
                          onClick={() => {
                            seteditSave(!editSave);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="submit-btn rounded-pill"
                          onClick={() => {
                            handleShowPopup();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                      {/* )} */}
                    </div>
                  </Col>
                )}
              </>
            )}
        </Row>
      </Container>
      {/* Modal */}
      <Modal
        size="lg"
        show={showopen}
        centered
        onHide={handleCloseopen}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <form
            className="px-5"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <h4 className="text-center pb-3 fs-24 fw-700">
              Missing/Damage Marbete For {selectedItem?.item_code}
            </h4>

            <div className="mb-3 input-box pt-3">
              <label className=" fs-16 pb-1">
                <span className="fw-600"> Enter Marbete Numbers </span>
                <div className="pt-3 d-flex">
                  <div>
                    <input
                      type="checkbox"
                      id="validationDefault01"
                      placeholder="Enter Number"
                      required
                      onClick={() => setselectedTagRange(!selectedTagRange)}
                      checked={selectedTagRange}
                    />
                  </div>
                  <div>
                    {" "}
                    <span>Enter Range</span>
                  </div>
                </div>
              </label>
              {selectedTagRange && (
                <>
                  <Row className="d-flex align-items-center">
                    <Col md={5}>
                      {" "}
                      <input
                        type="number"
                        id="validationDefault01"
                        className="w-100"
                        placeholder="Enter Starting Range"
                        required
                        onChange={(e) => setstartingRange(e.target.value)}
                        value={startingRange}
                      />
                    </Col>
                    <Col md={5}>
                      {" "}
                      <input
                        type="number"
                        id="validationDefault01"
                        className="w-100"
                        placeholder="Enter Ending Range"
                        required
                        value={endingRange}
                        onChange={(e) => setendingRange(e.target.value)}
                      />
                    </Col>
                    <Col md={2}>
                      <div
                        className="eye-btn2"
                        onClick={() => {
                          if (startingRange == "" || endingRange == "") {
                            return;
                          }
                          let start = parseInt(startingRange);
                          let end = parseInt(endingRange);
                          let diff = end - start + 1;
                          
                          console.log(diff, "diff");
                          let remaining =
                            selectedItem?.priority_tags_qty -
                            checkNewlyAdded(
                              selectedItem,
                              selectedMarbeteValues
                            );
                          // console.log(remaining, "remaining...");
                          if (diff > remaining) {
                            damageAddRef.current.innerHTML =
                              "You can not add new damaged marbete more than the current requested quantity";
                            return;
                          } else {
                            damageAddRef.current.innerHTML = "";
                          }
                          let id = 0;
                          let selectedValues = [...selectedMarbeteValues];
                          if (selectedMarbeteValues.length > 0) {
                            for (const element of selectedMarbeteValues) {
                              id = Math.max(id, element.id);
                            }
                          }
                          id=id+1;
                          for (let i = start; i <= end; i++) {
                            let found = false;
                            for (const element of selectedMarbeteValues) {
                              if (element.value == i) {
                                found = true;
                              }
                            }
                            if (!found) {
                              let obj = {
                                value: i,
                                id: id,
                              };
                              id++;
                              selectedValues.push(obj);
                            }
                          }
                          setstartingRange("");
                          setendingRange("");
                          setselectedMarbeteValues(selectedValues);
                        }}
                      >
                        <button type="button" className="p-0 m-0 border-0">
                          <svg className="icon fs-18">
                            <use href="#icon_plus"></use>
                          </svg>
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center text-danger">
                    <Col>
                      <div ref={damageAddRef}></div>
                    </Col>
                  </Row>
                </>
              )}
              {!selectedTagRange && (
                <>
                  <div>(Enter a number and click add button)</div>
                  <Row className="d-flex align-items-center">
                    <Col md={10}>
                      {" "}
                      <input
                        type="number"
                        id="validationDefault01"
                        className="w-100"
                        placeholder="Enter Number"
                        required
                        ref={marbeteRef}
                      />
                    </Col>
                    <Col md={2}>
                      <div
                        className="eye-btn2"
                        onClick={() => {
                          if (marbeteRef.current.value == "") {
                            return;
                          }
                          let start = parseInt(marbeteRef.current.value);
                          let end = parseInt(marbeteRef.current.value);
                          let diff = end - start + 1;
                          
                          console.log(diff, "diff");
                          let remaining =
                            selectedItem?.priority_tags_qty -
                            checkNewlyAdded(
                              selectedItem,
                              selectedMarbeteValues
                            );
                          // console.log(remaining, "remaining...");
                          if (diff > remaining) {
                            damageAddRef.current.innerHTML =
                              "You can not add new damaged marbete more than the current requested quantity";
                            return;
                          } else {
                            damageAddRef.current.innerHTML = "";
                          }
                          for (const element of selectedMarbeteValues) {
                            if (element.value == marbeteRef.current.value) {
                              marbeteRef.current.value = "";
                              return;
                            }
                          }
                          let obj = {};
                          if (selectedMarbeteValues.length == 0) {
                            obj["value"] = marbeteRef.current.value;
                            obj["id"] = 0;
                            setselectedMarbeteValues([obj]);
                          } else {
                            let id = 0;
                            for (const element of selectedMarbeteValues) {
                              id = Math.max(id, element.id);
                            }
                            id = id + 1;
                            obj["value"] = marbeteRef.current.value;
                            obj["id"] = id;
                            setselectedMarbeteValues([
                              ...selectedMarbeteValues,
                              obj,
                            ]);
                          }
                          marbeteRef.current.value = "";
                        }}
                      >
                        <button type="button" className="p-0 m-0 border-0">
                          <svg className="icon fs-18">
                            <use href="#icon_plus"></use>
                          </svg>
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="d-flex align-items-center text-danger">
                    <Col>
                      <div ref={damageAddRef}></div>
                    </Col>
                  </Row>
                </>
              )}
            </div>

            <div className="pb-5">
              <div className="fw-600 fs-16 pb-2">Entered Numbers</div>
              <div className="damage-card-container">
                {selectedMarbeteValues
                  ?.sort((a, b) => a.value - b.value)
                  ?.map((item) => (
                    <div className="damage-card">
                      <div>{item.value}</div>

                      {checkItemOldOrNew(selectedItem, item?.value) && (
                        <div className="">
                          <button
                            type="button"
                            className="p-0 m-0 border-0 cross-btn"
                            onClick={() => {
                              setselectedMarbeteValues((prev) =>
                                prev.filter((marbete) => marbete.id != item.id)
                              );
                            }}
                          >
                            <svg className="icon fs-22 cross-btn">
                              <use href="#icon_cross"></use>
                            </svg>
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="normal-btn rounded-pill me-4 py-1"
                onClick={handleCloseopen}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="submit-btn rounded-pill py-1"
                // disabled={
                //   selectedMarbeteValues?.length == 0 || submittingPoList
                // }
                onClick={(e) => {
                  e.preventDefault();
                  let string = "";
                  for (let i = 0; i < selectedMarbeteValues.length; i++) {
                    const element = selectedMarbeteValues[i];
                    if (i == selectedMarbeteValues.length - 1) {
                      string += element.value;
                    } else {
                      string += element.value + ",";
                    }
                  }
                  setdetails((prev) =>
                    prev?.map((item) => {
                      if (item?.id == selectedItem?.id) {
                        return {
                          ...item,
                          damage_marbete_number_new: string,
                          damage_tag_qty_new: selectedMarbeteValues?.length,
                        };
                      } else {
                        return item;
                      }
                    })
                  );
                  setselectedItem({
                    ...selectedItem,
                    damage_marbete_number_new: string,
                    damage_tag_qty_new: selectedMarbeteValues?.length,
                    damage_marbete_number2: string,
                    damage_tag_qty2: selectedMarbeteValues?.length,
                  });
                  // if (
                  //   details &&
                  //   details.length > 0 &&
                  //   details[0]?.priority_tags_qty == details[0]?.tags_qty
                  // ) {
                  //   console.log("Ready to call from modal...");
                  //   postDetailsDamageQty(
                  //     selectedItem,
                  //     string,
                  //     selectedMarbeteValues?.length
                  //   );
                  // } else {
                  //   setselectedMarbeteValues([]);
                  //   handleCloseopen();
                  // }
                  setselectedMarbeteValues([]);
                  handleCloseopen();
                }}
              >
                {submittingPoList ? <Loader /> : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        scrollable={true}
        show={showEyeIcon}
        centered
        onHide={handleShowcloseEye}
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <h4 className="text-center m-0 pb-3 fs-22 fw-700">
            {skuModalData?.item_code}
          </h4>
          <div className="new-tbl">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-start">Authorization Number</th>
                  <th className="text-start">Batch Number</th>
                  <th className="text-start">Starting Tag</th>
                  <th className="text-start">Ending Tag</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{skuModalData?.tax_authority_no}</td>
                  <td>{skuModalData?.coil_no}</td>
                  <td>{skuModalData?.starting_tags}</td>
                  <td>{skuModalData?.ending_tags}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        scrollable={true}
        show={showEyeIcondamage}
        centered
        onHide={handleShowcloseEyedamage}
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <div className="new-tbl">{skuModalData?.damage_marbete_number}</div>
        </Modal.Body>
      </Modal>
      <Modal show={confirmationPopup} onHide={handleClosePopup} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to submit these damage tags?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleClosePopup}
            >
              Close
            </Button>
            <Button
              variant="primary"
              disabled={submittingPoList}
              onClick={() => {
                postDetails();
              }}
            >
              {submittingPoList ? <Loader text="text-white" /> : "Submit"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ManageDaily;
