import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Modal, Button, Navbar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import Form from "react-bootstrap/Form";
import Processing from "../components/Processing";

function PickupConfirmation() {
  const navigate = useNavigate();
  const params = useParams();
  const fileInputRef = useRef(null);
  const [loadingCompleted, setloadingComplted] = useState(false);
  const { po: doc_number, booking_id, customer_id } = params;
  const [isFileUploading, setIsFileUploading] = useState(true);
  const [isDeclarationSending, setisDeclarationSending] = useState(false);
  const [documentSuccessMsg, setdocumentSuccessMsg] = useState("");
  const [documentErrorMsg, setdocumentErrorMsg] = useState(null);
  const [showVendorNew, setShowVendorNew] = useState(false);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [customDocuments, setcustomDocuments] = useState([]);
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [selectedDocId, setselectedDocId] = useState("");
  const [deleting, setdeleting] = useState(false);

  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  const [documentName, setdocumentName] = useState("");
  console.log(
    documentName,
    handleShow,
    setdocumentErrorMsg,
    setisDeclarationSending,
    setfileUploadLimitRxError
  );

  const handleChangeFileFun = async (e) => {
    const files = Array.from(e.target.files);
    if (files?.length > 0) {
      setIsFileUploading(true);
      const formdata = new FormData();
      formdata.append("booking_id", booking_id);
      formdata.append("doc_number", doc_number);
      formdata.append("customer_id", customer_id);

      for (const element of files) {
        formdata.append("files", element, element?.name);
      }

      try {
        let response = await axios({
          url: `${process.env.REACT_APP_OPERATION_BASE_URL}mtmpo/clickup/pickup/confirm/upload`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formdata,
        });
        if (response.status == 200) {
          getDocumentsFn();
          if (!response.data.error) {
            setdocumentSuccessMsg(response.data.message);
            handleShowSuccess();
            setTimeout(() => {
              handleCloseSuccess();
            }, 2000);
            setdocumentName("");
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    e.target.value = null; // Reset the file input value
  };
  const getDocumentsFn = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_OPERATION_BASE_URL}mtmpo/clickup/pickup/confirm/prooflist`,
        method: "POST",
        data: {
          booking_id: booking_id,
          doc_number: doc_number,
          customer_id: customer_id,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setcustomDocuments(response.data.proofs);
        } else {
          setcustomDocuments([]);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  const deleteLableFn = async () => {
    try {
      setdeleting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_OPERATION_BASE_URL}mtmpo/clickup/confirm/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          id: selectedDocId,
          proof_type: "pickup",
          customer_id: customer_id,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        getDocumentsFn();
        if (response.data.error) {
          console.log(response.data.message);
        } else {
          handleCloseVendorNew();
          setdocumentSuccessMsg(response.data.message);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  console.log(customDocuments, "custom doucments");
  const customDeclarationFn = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_OPERATION_BASE_URL}mtmpo/clickup/pickup/confirm`,
        method: "POST",
        data: {
          booking_id: booking_id,
          doc_number: doc_number,
          customer_id: customer_id,
        },
      });
      console.log(response, "response");
    } catch (error) {
      console.log(error);

      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        return;
      }
    }
  };
  const getTypeDoc = (path) => {
    if (!path) {
      return "";
    }
    let type = path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return (
        <img
          src={window.location.origin + "/images/pdf.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/excel.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/doc.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }

    return (
      <img
        src={path}
        style={{
          width: "40px",
          height: "30px",
          margin: "5px",
          marginRight: "10px",
        }}
        alt="doc"
      />
    );
  };
  const checkRxType = () => {
    if (!rawFile) {
      return (
        <img
          src={previewUrl}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFile?.name?.split(".");
    if (type[type.length - 1] == "pdf") {
      return (
        <img
          src={window.location.origin + "/images/pdf.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/excel.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/doc.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }

    return (
      <img
        src={previewUrl}
        style={{
          width: "40px",
          height: "30px",
          margin: "5px",
          marginRight: "10px",
        }}
        alt="doc"
      />
    );
  };
  const handleShowFileUpload = () => {
    fileInputRef.current.click();
  };
  const checkStatus = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_OPERATION_BASE_URL}mtmpo/clickup/pickup/status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          booking_id: booking_id,
          doc_number: doc_number,
          customer_id: customer_id,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloadingComplted(true);
          customDeclarationFn();
          getDocumentsFn();
        } else {
          setloadingComplted(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    if (!rawFile) {
      setpreviewUrl(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
  }, [rawFile]);

  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Navbar expand="lg" style={{ zIndex: 100 }}>
            <Container>
              <Navbar.Brand onClick={() => navigate("/")} className="d-flex">
                <img
                  src={window.location.origin + "/images/logo.png"}
                  style={{ width: "140px", height: "40px", maxHeight: "52px" }}
                ></img>
              </Navbar.Brand>

              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Form className="d-flex ms-auto"></Form>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {!loadingCompleted ? (
            <Processing />
          ) : (
            <>
              <Col md={12}>
                <div className="wrapper-box">
                  <h2 className="text-center fw-700 fs-30">
                    Pickup Confirmation
                  </h2>
                  <h3 className="text-center fw-500 fs-30 mt-3">
                    Uploading Evidence is optional
                  </h3>

                  <div className="py-3 w-50 mt-5">
                    <div className="d-flex align-items-center ">
                      <p className="fw-700 fs-20">Upload Evidences </p>
                      <div className="ms-auto">
                        <input
                          type="file"
                          accept="image/png,image/jpg,image/jpeg,.doc, .docx,.pdf,.csv,.xls,.xlsx"
                          id="fileUpload2"
                          ref={fileInputRef}
                          multiple
                          style={{ display: "none" }} // Hide the input element
                          onChange={handleChangeFileFun}
                        />
                        <label htmlFor="fileUpload2">
                          <button
                            type="button"
                            className="grd-btn fs-20 rounded-pill px-4 py-1"
                            onClick={handleShowFileUpload}
                          >
                            + Upload Evidences
                          </button>
                        </label>
                      </div>
                    </div>
                    {customDocuments?.map((item, index) => (
                      <>
                        <div
                          className="bg-gray border py-1 px-3 rounded-3 mt-4 d-flex align-items-center"
                          key={item?.document_path}
                        >
                          <div className="d-flex align-items-center">
                            {getTypeDoc(item?.document_path)}

                            <div className="ms-3">
                              <p className="fw-400 fs-16 py-2">
                                {item.document_name}
                              </p>
                            </div>
                          </div>
                          <div
                            className="ms-auto"
                            onClick={() => {
                              setselectedDocId(item?.id);
                              handleShowVendorNew();
                            }}
                          >
                            <button type="button" className="tbl-delete">
                              <svg className="icon fs-22">
                                <use href="#icon_delete"></use>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div className="d-flex">
                    <div className="ms-auto">
                      <button
                        type="button"
                        disabled={isDeclarationSending}
                        onClick={() => {
                          navigate("/saved", {
                            replace: true,
                            state: {
                              message: "We have saved your response",
                            },
                          });
                        }}
                        className="grd-btn fs-20 rounded-pill px-4 py-1 ms-4"
                      >
                        {isDeclarationSending ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>

      {/* ===Modle */}
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">Upload Evidences</h4>

          <div className="upload-box p-3">
            <label className="drag-box input-file">
              <img
                className="img-fluid rounded-start"
                width={80}
                height={55}
                src="/images/uploader.png"
                alt="..."
              />
              <input
                type="file"
                accept="image/png,image/jpg,image/jpeg,.doc, .docx,.pdf,.csv,.xls,.xlsx"
                id="fileUpload2"
                onChange={handleChangeFileFun}
              />
              <span>
                Drag and drop your image or <a href="">browse file</a> on your
                computer
              </span>
            </label>
          </div>
          <div className="d-block rounded-2 my-4">
            {previewUrl ? (
              <div
                className="file-import d-flex ai-center mt-10"
                id="remove_${inputid}"
              >
                {checkRxType()}
                <span style={{ marginRight: "auto" }}>{rawFile?.name}</span>
                <label htmlFor="fileUpload2" className="mr-10">
                  <svg
                    className="icon"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <use href="#icon_edit"></use>
                  </svg>
                </label>
                <button
                  onClick={() => {
                    setpreviewUrl(null);
                    setrawFile(null);
                  }}
                >
                  <svg
                    className="icon"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <use href="#icon_fileclose"></use>
                  </svg>
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {fileUploadLimitRxError && (
            <div className="mb-2 w-100 text-danger text-center">
              Please upload file less than 6MB
            </div>
          )}
          {documentErrorMsg && (
            <div className="mb-2 w-100 text-danger text-center">
              {documentErrorMsg}
            </div>
          )}

          <div className="mt-2">
            <button className="button button-blue upload-btn w-100 py-2 fs-20">
              {isFileUploading ? <Loader /> : "Upload"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSuccess}
        centered
        onHide={handleCloseSuccess}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <div className="d-flex justify-content-center  text-success pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <h3>Success</h3>
          <p>{documentSuccessMsg}</p>
        </Modal.Body>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Evidence?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteLableFn();
              }}
            >
              {deleting ? <Loader /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PickupConfirmation;
