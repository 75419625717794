import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Processing() {
  console.log("this page rendered");
  return (
    <Container>
      <Row>
        <Col md={12}>
          <div
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              width: "80vw",
              height: "100vh",
            }}
          >
            <h4 className="fw-500 fs-18">
              We are processing your data please wait .
            </h4>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Processing;
