import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import uploader from "./srcimages/uploader.png";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Processing from "../components/Processing";
import IconEditLabel from "../components/IconEditLabel";
import IconFileClose from "../components/IconFileClose";
function Acceptpo() {
  const [show, setShow] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [value, setValue] = React.useState(null);
  const [loadingCompleted, setloadingComplted] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const params = useParams();
  const { order_uniq_id, vendor_code, vendor_name } = params;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const handleCloseCalender = () => setShowCalender(false);
  const handleShowCalender = () => setShowCalender(true);
  const [showVendorName, setshowVendorName] = useState(false);
  const handleCloseVendorName = () => setshowVendorName(false);
  const handleShowVendorName = () => setshowVendorName(true);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [fileUploadSuccess, setfileUploadSuccess] = useState(false);
  const [estimatedTimeSuccess, setestimatedTimeSuccess] = useState(false);
  const [error, seterror] = useState({
    status: false,
    data: "",
  });
  const [fileError, setfileError] = useState({
    status: false,
    data: "",
  });
  const [valueError, setvalueError] = useState({
    status: false,
    data: "",
  });
  const [afterUploadMessage, setafterUploadMessage] = useState("");
  const [savingTime, setsavingTime] = useState(false);
  const [submitting, setsubmitting] = useState(false);
  const navigate = useNavigate();
  // upload
  const handleChangeFileFun = (e) => {
    let file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      setfileUploadLimitRxError(true);
      return;
    }
    setfileUploadLimitRxError(false);
    setrawFile(file);
    e.target.value = "";
  };
  const [rawFile, setrawFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);
  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxError, setfileUploadLimitRxError] = useState(false);
  const checkRxType = () => {
    return !rawFile || rawFile?.name?.split(".")?.pop() !== "pdf";
  };

  console.log(previewUrl, "test");
  console.log(rawFile, "file");

  // end upload
  const Tomorrow = dayjs().add(1, "day");
  const shouldDisableDate = (date) => {
    return date.isBefore(Tomorrow, "day"); // Disable dates before tomorrow
  };

  useEffect(() => {
    if (!rawFile) {
      setpreviewUrl(null);
      return;
    }

    const objectURL = URL.createObjectURL(rawFile);
    setpreviewUrl(objectURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFile]);
  const showVendorNameModel = () => {
    if (!rawFile && !value) {
      seterror({
        status: true,
        data: "Please Upload Proforma and Add Estimated Pickup Date",
      });
    } else if (!rawFile) {
      seterror({
        status: true,
        data: "Please Upload Proforma",
      });
    } else if (!value) {
      seterror({
        status: true,
        data: "Please Add Estimated Pickup Date",
      });
    } else {
      seterror({
        status: false,
        data: "",
      });
      handleShowVendorName();
    }
  };

  const acceptApi = async () => {
    try {
      setsubmitting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/po/accept`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setsubmitting(false);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        } else {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const checkStatusApi = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/po/status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloadingComplted(true);
        } else {
          setloadingComplted(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  //function to upload Purchase Order file
  async function handleFileUpload() {
    if (rawFile) {
      setIsFileUploading(true);
      setfileError({
        status: false,
        data: "",
      });
      try {
        const uploadFormData = new FormData();
        uploadFormData.append("invoice", rawFile, rawFile?.name);
        uploadFormData.append("order_uniq_id", order_uniq_id);
        uploadFormData.append("vendor_code", vendor_code);
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}everest/po/accept/upload/invoice`,
          method: "POST",
          data: uploadFormData,
        });

        if (response.status == 200) {
          setfileUploadSuccess(true);
          setIsFileUploading(false);
          if (!response.data.error) {
            setafterUploadMessage(response.data.message);
            handleShowSuccess();
            setTimeout(() => {
              handleCloseSuccess();
            }, 2000);
            handleClose();
          } else {
            handleClose();
          }
        }
      } catch (error) {
        alert("error uploading document: " + error.message);
        setIsFileUploading(false);
      }
    } else {
      setfileError({
        status: true,
        data: "Please select a file",
      });
    }
  }
  const addEstimatedDateApi = async () => {
    if (!value) {
      setvalueError({
        status: true,
        data: "Please select Date",
      });
      return;
    }
    setvalueError({
      status: false,
      data: "",
    });
    try {
      setsavingTime(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/po/accept/upload/date`,
        method: "POST",
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          pickup_date: dayjs(value).format("YYYY-MM-DD"),
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setestimatedTimeSuccess(true);
          handleCloseCalender();
          setafterUploadMessage(response.data.message);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
          setsavingTime(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    checkStatusApi();
  }, []);

  console.log(value, "value");
  return (
    <>
      {loadingCompleted ? (
        <>
          <Container>
            <Row>
              <Col md={12}>
                <div className="wrapper-box">
                  <div className="d-flex justify-content-center"></div>
                  <p className="fw-bold fs-3 text-center py-4 mt-5">
                    Thank you for accepting the PO, now please provide below
                    details to successfully process your information
                  </p>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn-line"
                      onClick={handleShow}
                    >
                      Upload Proforma
                    </button>
                    <div>
                      {fileUploadSuccess && (
                        <>
                          <img
                            src={
                              !checkRxType()
                                ? window.location.origin + "/images/pdf.png"
                                : previewUrl
                            }
                            width={100}
                            height={100}
                          />
                          <p className="fw-bold">{rawFile?.name}</p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <button
                      type="button"
                      className="btn-line"
                      onClick={handleShowCalender}
                    >
                      Add Estimated Pickup Date
                    </button>
                    <div className="fw-bold">
                      {value && estimatedTimeSuccess && (
                        <>
                          The Estimated pickup date is{" "}
                          {dayjs(value)?.format("DD-MM-YYYY")}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="d-flex py-4 mt-5">
                    {error?.status && (
                      <div className="ms-auto text-danger">{error.data}</div>
                    )}
                  </div>
                  <div className="d-flex " onClick={showVendorNameModel}>
                    <div className="ms-auto ">
                      <button
                        type="button"
                        className="py-2 px-4 grd-btn fs-20 rounded-pill fw-600 text-center w-auto"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          {/* ===Modle */}
          <Modal
            show={show}
            centered
            onHide={() => {
              if (!fileUploadSuccess) {
                setpreviewUrl(null);
                setrawFile(null);
              }
              handleClose();
            }}
            className="tracking-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-5">
              <h4 className="text-center pb-3 fs-22">Please upload proforma</h4>

              <div className="upload-box p-3">
                <label className="drag-box input-file">
                  <img
                    className="img-fluid rounded-start"
                    width={80}
                    height={55}
                    src={uploader}
                    alt="..."
                  />
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    id="fileUpload2"
                    onChange={handleChangeFileFun}
                  />
                  <span>
                    Drag and drop your image or <a href="">browse file</a> on
                    your computer
                  </span>
                </label>
              </div>
              <div className="d-block rounded-2 my-4">
                {previewUrl ? (
                  <div
                    className="file-import d-flex ai-center mt-10"
                    id="remove_${inputid}"
                  >
                    <img
                      src={
                        !checkRxType()
                          ? window.location.origin + "/images/pdf.png"
                          : previewUrl
                      }
                      style={{
                        width: "30px",
                        height: "30px",
                        margin: "5px",
                        marginRight: "10px",
                      }}
                      alt="doc"
                    />

                    <span style={{ marginRight: "auto" }}>{rawFile?.name}</span>
                    <IconEditLabel />
                    <button
                      onClick={() => {
                        setpreviewUrl(null);
                        setrawFile(null);
                      }}
                    >
                      <IconFileClose />
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {fileUploadLimitRxError && (
                <div className="mb-2 w-100 text-danger text-center">
                  Please upload file less than 6MB
                </div>
              )}
              <div className="mt-2 w-100 text-danger text-center">
                {fileError?.status && (
                  <div className="text-danger">{fileError?.data}</div>
                )}
              </div>
              <div className="">
                <label
                  disabled={isFileUploading}
                  className="my-4 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                  onClick={() => {
                    handleFileUpload();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {isFileUploading ? "Uploading..." : "Upload"}
                </label>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            show={showSuccess}
            centered
            onHide={handleCloseSuccess}
            className="tracking-modal"
          >
            <Modal.Body className="p-4 text-center">
              <div className="d-flex justify-content-center  text-success pb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="55"
                  height="55"
                  fill="currentColor"
                  className="bi bi-check-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
              </div>
              <h3>Success</h3>
              <p>{afterUploadMessage}</p>
            </Modal.Body>
          </Modal>
          <Modal
            show={showCalender}
            centered
            onHide={handleCloseCalender}
            className="tracking-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <h2 className="fw-600 fs-26 text-center">
                Please Suggest pickup date
              </h2>
              <div className="p-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                    <DemoItem>
                      <DateCalendar
                        value={value}
                        shouldDisableDate={shouldDisableDate}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="d-flex me-4 mb-4 text-danger">
                <div className="ms-auto ">
                  {valueError?.status && <>{valueError?.data}</>}
                </div>
              </div>
              <div className="d-flex me-4 mb-4">
                <div
                  className="ms-auto "
                  disabled={savingTime}
                  onClick={() => {
                    addEstimatedDateApi();
                  }}
                >
                  <button
                    type="button"
                    className="py-1 px-4 grd-btn fs-20 rounded-pill fw-600 text-center w-auto"
                  >
                    {savingTime ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={showVendorName} onHide={handleCloseVendorName} centered>
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body className="p-4">
              <p className="fs-4 text-center m-0 p-0">
                Please reconfirm your submission
              </p>
              <div className="input-box mb-4 mt-2 p-0">
                <input
                  type="text"
                  className="w-100"
                  placeholder="Enter Description"
                  id="vender_code"
                  value={`${vendor_name} (${vendor_code})`}
                />
              </div>
              <div className="d-flex justify-content-end mt-2">
                <div>
                  <button
                    type="button"
                    disabled={submitting}
                    className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                    onClick={acceptApi}
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Processing />
        </>
      )}
    </>
  );
}

export default Acceptpo;
