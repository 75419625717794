import React from 'react'
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import {Container, Row, Col} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Distribution_activities() {
  const navigate = useNavigate()

  return (
    <>
    <Container>
        <Row>
          <Col md={12}>
            <div className='wrapper-box'>
              <button
                type="button"
                className="p-0 m-0 border-0 bg-white btn-back d-flex align-items-center fs-20"
                onClick={() => navigate("/")}
              >
                <svg className="icon fs-24 me-2">
                  <use href="#icon_backarrow"></use>
                </svg>
                Back
              </button>
              <h2 className='text-center fw-700 fs-30 mt-4'>Distribution Activities</h2>
              <div className='py-5'>
                <button type='button' className='my-5 position-relative py-4 btn-card fs-20 fw-600 w-100 d-flex text-center' onClick={() => navigate("/grpup-invoice")}>
                    Group Orders
                  <svg className="icon btn-arrow">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
                
                <button type='button' className='my-5 position-relative py-4 btn-card fs-20 fw-600 w-100 d-flex text-center' onClick={() => navigate("/distribution-status")}>
                    Distribution Status 
                  <svg className="icon btn-arrow">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>

                <button type='button' className='my-5 position-relative py-4 btn-card fs-20 fw-600 w-100 d-flex text-center' onClick={() => navigate("/grpup-return-orders")}>
                  Group Return Orders
                  <svg className="icon btn-arrow">
                    <use href="#icon_rightarrow"></use>
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
    </Container>
    </>
  )
}

export default Distribution_activities