import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import BackButton from "../components/BackButton";

function ArrivalDetails() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [showopen, setShowopen] = useState(false);
  const handleCloseopen = () => setShowopen(false);
  const handleShowopen = () => setShowopen(true);
  const [gettingContainersList, setgettingContainersList] = useState(false);
  const [containersTotal, setcontainersTotal] = useState({});
  const [containers, setcontainers] = useState([]);
  const [poList, setpoList] = useState([]);
  const [documentSuccessMsg, setdocumentSuccessMsg] = useState("");
  const [selectedContainerId, setselectedContainerId] = useState("");
  const [containerIdSelected, setcontainerIdSelected] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [showVendorNew, setShowVendorNew] = useState(false);
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const [selectedDocId, setselectedDocId] = useState("");
  const [deleting, setdeleting] = useState(false);
  const [selectedDocItem, setselectedDocItem] = useState("");
  const [selectedDocNumberItems, setselectedDocNumberItems] = useState([]);
  const [showCustomerComments, setshowCustomerComments] = useState(false);
  const handleCloseCustomerComments = () => setshowCustomerComments(false);
  const handleShowCustomerComments = () => setshowCustomerComments(true);
  const [selectedLabel, setselectedLabel] = useState({});
  const [submittingPoList, setsubmittingPoList] = useState(false);
  const [submittingPoError, setsubmittingPoError] = useState(null);
  const [submittingFinalApi, setsubmittingFinalApi] = useState(false);
  const [submittingFinalApiError, setsubmittingFinalApiError] = useState(false);
  const [checkingFinalArrivalDate, setcheckingFinalArrivalDate] =
    useState(null);
  const [loginData, setloginData] = useState({});
  const location = useLocation();
  const handleCheckBoxChange = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setcontainerIdSelected([
        ...containerIdSelected,
        { container_id: id, arrival_date: "" },
      ]);
    } else {
      setcontainerIdSelected((prev) =>
        prev.filter((item) => item.container_id != id)
      );
    }
  };
  const getDateValue = (container_id) => {
    for (const element of containerIdSelected) {
      if (element.container_id == container_id) {
        return element.arrival_date;
      }
    }
    return "";
  };
  const checkCheckedOrNot = (container_id) => {
    for (const element of containerIdSelected) {
      if (element.container_id == container_id) {
        return true;
      }
    }
    return false;
  };
  const checkDisableOrNot = (container_id) => {
    for (const element of containerIdSelected) {
      if (element.container_id == container_id && element.already_selected) {
        return true;
      }
    }
    return false;
  };
  const getButtonClassName = (container_id) => {
    let found = false;
    for (const element of containerIdSelected) {
      if (element.container_id == container_id) {
        found = true;
        break;
      }
    }
    return found;
  };
  console.log(loginData, "mtm_user_data login data");

  const handleShowFileUpload = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };
  console.log(containerIdSelected, "container id selected");

  const fileInputRef = useRef(null);
  const getTypeDoc = (path) => {
    if (!path) {
      return "";
    }
    let type = path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return (
        <img
          src={window.location.origin + "/images/pdf.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/excel.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/doc.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }

    return (
      <img
        src={path}
        style={{
          width: "40px",
          height: "30px",
          margin: "5px",
          marginRight: "10px",
        }}
        alt="doc"
      />
    );
  };

  const getContainersList = async () => {
    try {
      setgettingContainersList(false);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/container/po/list`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          booking_id: location.state.booking_id,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          company_name: localStorage.getItem("company_name"),
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
        },
      });
      if (response.status == 200) {
        setgettingContainersList(true);
        if (!response.data.error) {
          console.log(response.data, "res data");
          setcontainersTotal(response.data);
          let containers = response.data.containers;
          let selected = [];
          for (let i = 0; i < containers.length; i++) {
            const element = containers[i];
            if (element?.arrival_date && element?.arrival_date != "") {
              selected.push({
                container_id: element?.container_id,
                arrival_date: element?.arrival_date,
                already_selected: true,
              });
            }
          }
          setcontainerIdSelected(selected);
          setcontainers(response.data.containers);
          setpoList(response.data.pos);
        }
      }
    } catch (error) {
      console.log(error);
      setgettingContainersList(true);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const postPoList = async () => {
    setsubmittingPoError(null);
    setsubmittingPoList(true);
    let filteredData = selectedDocNumberItems?.filter(
      (item) => item.ware_house_qty_new && item.ware_house_qty_new != ""
    );
    filteredData = filteredData.map((item) => {
      let obj = { ...item };
      obj.ware_house_qty = obj.ware_house_qty_new;
      delete obj.ware_house_qty_new;
      return obj;
    });
    console.log(filteredData, "filtered data");
    // return;
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/po/qty/update`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          doc_number: selectedDocItem?.doc_number,
          items: filteredData,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          company_name: localStorage.getItem("company_name"),
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
        },
      });
      if (response.status == 200) {
        setsubmittingPoList(false);
        if (!response.data.error) {
          getContainersList();
          handleClose();
        } else {
          setsubmittingPoError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setsubmittingPoList(false);
      setsubmittingPoError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const postFinalList = async () => {
    setsubmittingFinalApiError(null);
    setsubmittingFinalApi(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/containers/pos/submit`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          booking_id: location.state.booking_id,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          arrived_containers: containerIdSelected,
          warehouse_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.warehouses[0].warehouse_id,
        },
      });
      if (response.status == 200) {
        setsubmittingFinalApi(false);
        if (!response.data.error) {
          handleCloseopen();
          navigate("/arrival-booking-list");
        } else {
          setsubmittingFinalApiError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setsubmittingFinalApi(false);
      setsubmittingFinalApiError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };

  const handleChangeFileFun = async (e) => {
    const files = Array.from(e.target.files);
    if (files?.length > 0) {
      setIsFileUploading(true);
      const formdata = new FormData();
      formdata.append("booking_id", location.state.booking_id);
      formdata.append("container_id", selectedContainerId);
      formdata.append(
        "user_id",
        JSON.parse(localStorage.getItem("mtm_user_data"))?.id
      );
      formdata.append(
        "warehouse_id",
        JSON.parse(localStorage.getItem("mtm_user_data"))?.warehouses[0]
          .warehouse_id
      );

      for (const element of files) {
        formdata.append("files", element, element?.name);
      }

      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/containers/proof/upload`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
            jwt_token: localStorage.getItem("mtm_user_token"),
          },
          data: formdata,
        });
        if (response.status == 200) {
          if (!response.data.error) {
            getContainersList();
            setdocumentSuccessMsg(response.data.message);
            handleShowSuccess();
            setTimeout(() => {
              handleCloseSuccess();
            }, 2000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    e.target.value = null; // Reset the file input value
  };
  const deleteLableFn = async () => {
    try {
      setdeleting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/containers/proof/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          id: selectedDocId,
          proof_type: "arrival",
          container_id: selectedContainerId,
          booking_id: location.state.booking_id,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          warehouse_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.warehouses[0].warehouse_id,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        if (response.data.error) {
          alert("error");
        } else {
          handleCloseVendorNew();
          getContainersList();
          setdocumentSuccessMsg(response.data.message);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1; // January is 0
    let day = today.getDate();

    // Add leading zeros if month or day is less than 10
    if (month < 10) {
      month = `0${month}`;
    }

    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  }

  // const checkDisabled = () => {
  //   for (const element of selectedDocNumberItems) {
  //     if (
  //       parseFloat(element?.qty || 0) -
  //         parseFloat(element?.ware_house_qty || 0) -
  //         parseFloat(element?.ware_house_qty_new || 0) <
  //       1
  //     ) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };
  useEffect(() => {
    setloginData(JSON.parse(localStorage.getItem("mtm_user_data")));
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      getContainersList();
    }
  }, []);

  return (
    <>
      <Container>
        <Row className="pb-2">
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-between align-items-center">
                <BackButton navi="arrival-booking-list" />
                <div>
                  <h2 className="text-center fw-700 fs-30">
                    Arrival Confirmation
                  </h2>
                </div>
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
        {!gettingContainersList && (
          <div className="w-100 mt-5 text-center">
            <Loader />
          </div>
        )}
        {gettingContainersList && (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 py-4">
            <div className="">
              <h4 className="fw-700 fs-24 text-center pb-4">
                List Of Containers
              </h4>
              {containers.map((item, index) => (
                <>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <input
                        className="form-check-input me-3"
                        type="checkbox"
                        checked={checkCheckedOrNot(item?.container_id)}
                        id={item?.container_id}
                        disabled={checkDisableOrNot(item?.container_id)}
                        onChange={handleCheckBoxChange}
                      />
                      <span>
                        Container {index + 1}: {item?.container_id}
                      </span>
                    </div>
                    <div>
                      <input
                        type="date"
                        id={item?.container_id}
                        className="form-control"
                        name={item?.container_id}
                        value={getDateValue(item?.container_id)}
                        // disabled={checkDisableOrNot(item?.container_id)}
                        onChange={(e) => {
                          setcontainerIdSelected((prev) =>
                            prev.map((container) => {
                              if (
                                container.container_id == item?.container_id
                              ) {
                                return {
                                  ...container,
                                  arrival_date: e.target.value,
                                };
                              } else {
                                return container;
                              }
                            })
                          );
                        }}
                        disabled={
                          !getButtonClassName(item?.container_id) ||
                          checkDisableOrNot(item?.container_id)
                        }
                        max={getCurrentDate()}
                      />
                    </div>
                    <div>
                      <input
                        type="file"
                        accept="image/png,image/jpg,image/jpeg,.doc, .docx,.pdf,.csv,.xls,.xlsx"
                        id="fileUpload2"
                        ref={fileInputRef}
                        multiple
                        style={{ display: "none" }} // Hide the input element
                        onChange={(e) => {
                          handleChangeFileFun(e);
                        }}
                      />
                      <label htmlFor="fileupload2">
                        <button
                          type="button"
                          className={`${
                            getButtonClassName(item?.container_id) &&
                            !checkDisableOrNot(item?.container_id)
                              ? "grd-btn"
                              : "disable-btn"
                          } fs-10 rounded py-2 px-3 btn-file d-flex align-items-center`}
                          onClick={() => {
                            setselectedContainerId(item?.container_id);
                            handleShowFileUpload();
                          }}
                          disabled={
                            !getButtonClassName(item?.container_id) ||
                            checkDisableOrNot(item?.container_id)
                          }
                        >
                          <svg
                            className="icon me-2"
                            style={{ fontSize: "12px" }}
                          >
                            <use href="#icon_plus"></use>
                          </svg>
                          Upload Proof
                        </button>
                      </label>
                    </div>
                  </div>
                  <div className="row rows-cols-3 row-cols-md-3 row-cols-lg-3 g-3 pt-4">
                    {item?.proofs?.map((item, index) => (
                      <div className="col" key={item?.document_path}>
                        <div className="border py-1 px-1 rounded-3 d-flex align-items-center">
                          <div className="d-flex align-items-center">
                            <div>
                              {/* <svg className="icon fs-18">
                              <use href="#icon_img"></use>
                            </svg> */}
                              {getTypeDoc(item?.document_path)}
                            </div>
                            <div className="ms-2">
                              <p
                                className="fw-400 fs-16"
                                style={{ textOverflow: "ellipsis" }}
                              >
                                {item.document_name}
                              </p>
                            </div>
                          </div>
                          <div
                            className="ms-auto"
                            onClick={() => {
                              setselectedDocId(item?.id);
                              setselectedContainerId(item?.container_id);
                              handleShowVendorNew();
                            }}
                          >
                            <button type="button" className="tbl-delete">
                              <svg className="icon fs-18">
                                <use href="#icon_delete"></use>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr />
                </>
              ))}
            </div>
            <div className="br-left">
              <h4 className="fw-700 fs-24 text-center pb-4">
                Confirm Received SKU
              </h4>
              {poList?.map((item, index) => (
                <button
                  type="button"
                  className="w-100 rounded d-flex justify-content-between align-items-center px-3 py-1 sku-btn mb-4"
                  onClick={() => {
                    setselectedDocItem(item);
                    setselectedDocNumberItems(item?.poItems);
                    handleShow();
                  }}
                >
                  <span>PO Number: {item?.doc_number}</span>
                  <span>Total SKU: {item?.total_sku}</span>
                  <span>Total Quantity: {item?.total_qty}</span>
                  <svg className="icon fs-18">
                    <use href="#icon_circlearrow"></use>
                  </svg>
                </button>
              ))}

              <div className="d-flex">
                {checkingFinalArrivalDate && (
                  <div className="ms-auto text-danger pb-2">
                    {checkingFinalArrivalDate}
                  </div>
                )}
              </div>
              <div className="d-flex">
                <div className="ms-auto">
                  <button
                    type="button"
                    className="normal-btn rounded-pill me-4 py-1"
                    onClick={() => {
                      navigate("/arrival-booking-list");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`submit-btn rounded-pill py-1`}
                    disabled={containerIdSelected?.length == 0}
                    onClick={() => {
                      for (const element of containerIdSelected) {
                        if (element.arrival_date == "") {
                          setcheckingFinalArrivalDate(
                            "Please select arrival date"
                          );
                          return;
                        }
                      }
                      setcheckingFinalArrivalDate(null);
                      handleShowopen();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>

      {/* ===Modle */}
      <Modal
        size="xl"
        show={show}
        centered
        onHide={() => {
          setsubmittingPoError(null);
          handleClose();
        }}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-24 fw-700">
            PO Number: {selectedDocItem?.doc_number}
          </h4>
          <div className="modify-tbl">
            <table className="table text-center border my-4">
              <tbody>
                <tr>
                  <th>SKU</th>
                  <th>Description</th>
                  <th>Order&nbsp;Quantity</th>
                  <th>Already&nbsp;Received&nbsp;Quantity</th>
                  <th>Received&nbsp;Quantity</th>
                  <th>Pending&nbsp;Quantity</th>
                  <th>Comment</th>
                  <th></th>
                </tr>
                {selectedDocNumberItems?.map((item, index) => (
                  <tr>
                    <td>{item?.item_code}</td>
                    <td>{item?.item_description}</td>
                    <td>{item?.qty}</td>
                    <td>{item?.ware_house_qty}</td>
                    <td width="100px">
                      <input
                        type="text"
                        className="text-center"
                        placeholder=""
                        style={{ maxWidth: "80px" }}
                        value={item?.ware_house_qty_new || ""}
                        disabled={
                          parseFloat(item?.qty || 0) -
                            parseFloat(item?.ware_house_qty || 0) <=
                          0
                        }
                        onChange={(e) => {
                          setselectedDocNumberItems((prev) =>
                            prev.map((doc) => {
                              if (doc.id == item?.id) {
                                return {
                                  ...doc,
                                  ware_house_qty_new: parseInt(e.target.value),
                                };
                              } else {
                                return doc;
                              }
                            })
                          );
                        }}
                      />
                      {/* <p className="text-danger">
                        {parseFloat(item?.qty || 0) -
                          parseFloat(item?.ware_house_qty || 0) -
                          parseFloat(item?.ware_house_qty_new || 0) <
                          1 &&
                          "Recieved quantity should be less than order quantity"}
                      </p> */}
                    </td>
                    <td>
                      {parseFloat(item?.qty || 0) -
                        parseFloat(item?.ware_house_qty || 0) -
                        parseFloat(item?.ware_house_qty_new || 0) || 0}
                    </td>
                    <td>
                      {!item?.comment || item?.comment == "" ? (
                        <button
                          type="button"
                          className="p-0 m-0 border-0 plus-btn"
                          onClick={() => {
                            setselectedLabel(item);
                            handleShowCustomerComments();
                          }}
                        >
                          <svg className="icon fs-22">
                            <use href="#icon_circleplus"></use>
                          </svg>
                        </button>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center align-items-center gap-2">
                            <p>{item?.comment}</p>
                            <button
                              type="button"
                              className="p-0 m-0 border-0 plus-btn "
                              onClick={() => {
                                setselectedLabel(item);
                                handleShowCustomerComments();
                              }}
                            >
                              <svg className="icon fs-18">
                                <use href="#icon_edit"></use>
                              </svg>
                            </button>
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex">
            <div className="ms-auto">
              <div className="ms-auto text-danger pb-2 ">
                <p>{submittingPoError}</p>
              </div>
              <button
                type="button"
                className="normal-btn rounded-pill me-4 py-1"
                onClick={() => {
                  setsubmittingPoError(null);
                  handleClose();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="submit-btn rounded-pill py-1"
                disabled={submittingPoList}
                onClick={postPoList}
              >
                {submittingPoList ? <Loader /> : "Submit"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showopen}
        centered
        onHide={() => {
          setsubmittingFinalApiError(null);
          handleCloseopen();
        }}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-24 fw-700">Submission</h4>
          <p className="fw-500 fs-20 text-center pb-4">
            Are you <strong>sure</strong> you want to <strong>submit</strong>{" "}
            this!
          </p>
          {submittingFinalApiError && (
            <div className="text-center text-danger pb-3">
              {submittingFinalApiError}
            </div>
          )}

          <div className="text-center pb-3">
            <button type="button" className="normal-btn rounded-pill me-4 py-1">
              Cancel
            </button>
            <button
              type="button"
              className="submit-btn rounded-pill py-1"
              disabled={submittingFinalApi}
              onClick={postFinalList}
            >
              {submittingFinalApi ? <Loader /> : "Submit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSuccess}
        centered
        onHide={handleCloseSuccess}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <div className="d-flex justify-content-center  text-success pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <h3>Success</h3>
          <p>{documentSuccessMsg}</p>
        </Modal.Body>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Proof?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteLableFn();
              }}
            >
              {deleting ? <Loader /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showCustomerComments}
        centered
        onHide={handleCloseCustomerComments}
        className="tracking-modal"
      >
        <Modal.Header closeButton style={{ border: "none" }}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">Add Comments</h2>
          <form className="p-4">
            <div className="input-box mb-4">
              <textarea
                required
                type="text"
                className="w-100 border-bottom pb-3"
                placeholder="Add Comment"
                id="comments"
                value={selectedLabel?.comment}
                onChange={(e) => {
                  setselectedLabel({
                    ...selectedLabel,
                    comment: e.target.value,
                  });
                }}
              />
            </div>

            <div className="d-flex justify-content-end">
              <div>
                <button
                  type="submit"
                  className="mt-3 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                  onClick={(e) => {
                    e.preventDefault();
                    setselectedDocNumberItems((prev) =>
                      prev.map((item) => {
                        if (item?.id == selectedLabel?.id) {
                          return { ...item, comment: selectedLabel?.comment };
                        } else {
                          return item;
                        }
                      })
                    );
                    handleCloseCustomerComments();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ArrivalDetails;
