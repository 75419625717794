import axios from "axios";
import moment from "moment";
 
export function getStatusIcon(status,size) {
  switch (status) {
    case 0:
      return<div style={{ width: "min-content" }} className={`bg-lightpurple ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-purple rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_origin"></use>
        </svg>
      </div>
      <span className="fw-600 text-purple">
        Order Synced
      </span>
    </div> 
  
    case 1:
      return <div style={{ width: "min-content" }} className={`bg-lightcyan ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-cyan rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_order-group"></use>
        </svg>
      </div>
      <span className="fw-600 text-cyan">
          Order Grouped
      </span>
    </div> 
  
    case 2:
      return <div style={{ width: "min-content" }} className={`bg-lightblue ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-blue rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_transit"></use>
        </svg>
      </div>
      <span className="fw-600 text-blue">
       Driver Assigned
      </span>
    </div>
  
    case 3:
      return <div style={{ width: "min-content" }} className={`bg-lightorange ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-orange rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_pickup-confirm"></use>
        </svg>
      </div>
      <span className="fw-600 text-orange">
        Pickup confirm
      </span>
    </div>
  
    case 4:
      return <div style={{ width: "min-content" }} className={`bg-lightred ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-red rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_delivery-processing"></use>
        </svg>
      </div>
      <span className="fw-600 text-red">
        Delivery Processing
      </span>
    </div>
  
    case 5:
      return <div style={{ width: "min-content" }} className={`bg-lightyellow ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-yellow rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_delivery-completed"></use>
        </svg>
      </div>
      <span className="fw-600 text-yellow">
        Delivery Completed
      </span>
    </div>
  
    case 6:
      return <div style={{ width: "min-content" }} className={`bg-lightgreen ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-green rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
        <use href="#icon_delivered"></use>
        </svg>
      </div>
      <span className="fw-600 text-green">
          Delivered
      </span>
    </div>
  
    default:
      return <div style={{ width: "min-content" }} className={`bg-lightpink ${size == 's' ? 'p-1' : 'p-2'} rounded-2 d-flex align-items-center text-nowrap icon-box`}>
      <div className="w30px h30px bg-pink rounded-2 p-1 d-flex align-items-center justify-content-center me-2">
        <svg className="icon fs-22 text-white">
          <use href="#icon_empty-transit"></use>
        </svg>
      </div>
      <span className="fw-600 text-pink">
        " "
      </span>
    </div>;
     
  }
}

export function conditionalExp(condition,a,b){
  if(condition){
    return a
  }else{
    return b
  }
}

export async function getUngroupedOrders(setisLoading,setdistribution,navigate) {
  const token = localStorage.getItem("mtm_user_token");
  const company_name = localStorage.getItem("company_name");
 setisLoading && setisLoading(true);
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}mtmdistribution/get/ungrouped_distribution_orders`,
      { company_name: company_name,customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
      ?.customer_id, },
      {
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      }
    );

    if (!response?.data?.error) {
     setisLoading && setisLoading(false);
     setdistribution && setdistribution(response.data?.distribution_orders);
    } else {
     setdistribution && setdistribution([]);
     setisLoading && setisLoading(false);

    }
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
      return;
    }
    console.log(err);
   setisLoading && setisLoading(false);
  }
}

export async function getUngroupedReturnOrders(setisLoading,setdistribution,navigate) {
  const token = localStorage.getItem("mtm_user_token");
  const company_name = localStorage.getItem("company_name");
 setisLoading && setisLoading(true);
  try {
    let response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}mtmdistribution/get/ungrouped_distribution_return_orders`,
      { company_name: company_name,customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
      ?.customer_id, },
      {
        headers: {
          "Content-Type": "application/json",
          jwt_token: token,
        },
      }
    );

    if (!response?.data?.error) {
     setisLoading && setisLoading(false);
     setdistribution && setdistribution(response.data?.distribution_orders);
    } else {
     setdistribution && setdistribution([]);
     setisLoading && setisLoading(false);
    }
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.removeItem("mtm_user_token");
      localStorage.removeItem("mtm_user_data");
      navigate("/");
      return;
    }
    console.log(err);
   setisLoading && setisLoading(false);
  }
}

export function getMomentFormatted(date,format){
    if(date){
      return moment(date).format(format)
    }else {
     return "";
    }
}