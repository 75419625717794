import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";

function Missingdocument() {
  const navigate = useNavigate();
  const params = useParams();
  const { document_uniq_id } = params;
  const [isDeclarationSendingMissingDoc, setisDeclarationSendingMissingDoc] =
    useState(false);
  const [missing_document, setmissing_document] = useState("");
  const [declarationMessage, setdeclarationMessage] = useState(null);
  const [
    declarationMessageErrorMissingDoc,
    setdeclarationMessageErrorMissingDoc,
  ] = useState(null);
  const customDeclarationFn = async () => {
    if (missing_document.length == 0) {
      setdeclarationMessageErrorMissingDoc("Please Enter Suggestions");
      return;
    }
    setisDeclarationSendingMissingDoc(true);
    setdeclarationMessage(null);
    setdeclarationMessageErrorMissingDoc(null);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmcustom/upload/missing`,
        method: "POST",
        data: {
          booking_id: document_uniq_id,
          missing_document: missing_document,
        },
      });
      if (response.status == 200) {
        setisDeclarationSendingMissingDoc(false);
        setmissing_document("");
        if (!response.data.error) {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        } else {
          setdeclarationMessageErrorMissingDoc(response.data.message);
        }
        setTimeout(() => {
          setdeclarationMessage(null);
          setdeclarationMessageErrorMissingDoc(null);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setdeclarationMessageErrorMissingDoc(error.message);
      setTimeout(() => {
        setdeclarationMessage(null);
        setdeclarationMessageErrorMissingDoc(null);
      }, 2000);
      setisDeclarationSendingMissingDoc(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <h2 className="text-center fw-700 fs-30">
                Suggest missing documents
              </h2>
            </div>
            <div className="content-wrapper">
              <label for="">Enter Your Suggestions</label>
              <div className="form-floating pt-4">
                <textarea
                  className="form-control"
                  value={missing_document}
                  onChange={(e) => {
                    setmissing_document(e.target.value);
                    setdeclarationMessageErrorMissingDoc(null);
                  }}
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                ></textarea>

                <div className="d-flex justify-content-end">
                  {declarationMessage && (
                    <div className="input-box mb-4 pt-2 fw-600 text-danger  ms-auto">
                      {declarationMessage}
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                  {declarationMessageErrorMissingDoc && (
                    <div className="input-box mb-4 pt-2 fw-600 text-danger  ms-auto">
                      {declarationMessageErrorMissingDoc}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex py-4">
                <button
                  type="button"
                  disabled={isDeclarationSendingMissingDoc}
                  onClick={customDeclarationFn}
                  className="ms-auto rounded-pill btn btn-success px-4"
                >
                  {isDeclarationSendingMissingDoc ? (
                    <Loader />
                  ) : (
                    "Save Response"
                  )}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Missingdocument;
