import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import LayoutOutlet from "./LayoutOutlet";
import Save from "../pages/Save";
import Modify from "../pages/Modify";
import Afteremail from "../pages/Afteremail";
import Acceptpo from "../pages/Acceptpo";
import Aftersave from "../pages/Aftersave";
import Save2 from "../pages/Save2";
import Accept from "../pages/Accept";
import Reject from "../pages/Reject";
import Rejection2 from "../pages/Rejection2";
import PageAfterAccept from "../pages/PageAfterAccept";
import PartialReject from "../pages/PartialReject";
import NewItemUpdate from "../pages/NewItemUpdate";
import UpdatedOrderAccept from "../pages/UpdatedOrderAccept";
import Uploaddocument from "../pages/Uploaddocument";
import Missingdocument from "../pages/Missingdocument";
import DomesticAccept from "../pages/DomesticAccept";
import PickupConfirmation from "../pages/PickupConfirmation";
import Login from "../pages/Login";
import Home from "../pages/Home";
import ExtractionFile from "../pages/ExtractionFile";
import Marktax from "../pages/Marktax";
import UploadDaily from "../pages/UploadDaily";
import ManageDaily from "../pages/ManageDaily";
import ActionTaken from "../pages/ActionTaken";
import ArrivalConformation from "../pages/ArrivalConformation";
import ArrivalDetails from "../pages/ArrivalDetails";
import LayoutOutlet2 from "./LayoutOutlet2";
import CompanyList from "../pages/CompanyList";
import Distribution_activities from "../pages/Distribution_activities";
import Group_invoice from "../pages/Group_invoice";
import Distribution_status from "../pages/Distribution_status";
import Choose_activity from "../pages/Choose_activity";
import Group_return_orders from "../pages/Group_return_orders";

function Routing() {
  const newVendorDetails = {
    order_uniq_id: "",
    vendor_code: "",
    description: "",
    qty: "",
    upc: "",
    alcohol: "",
    box: "",
    price: "",
    moq: "",
    barcode: "",
    item_code: "",
    item_id: "",
    ItemName: "",
    U_PROVEEDOR: "",
    U_GVINOS: "",
    U_MARCA: "",
    U_PAIS: "",
    U_FACTOR: "",
    U_CAPACIDAD: "",
    U_COSTO_ORI: "",
    U_GRADO_ALC: "",
    MinOrderQuantity: "",
    LeadTime: "",
    U_NCA_TARIMA: "",
    U_B_TARIMAS: "",
    U_TIPO_TARIMA: "",
    U_DUN: "",
    U_INCOTERM: "",
    U_LONGITUD: "",
    U_ANCHO: "",
    U_ALTURA: "",
    U_PESO: "",
    U_LONGITUD_C: "",
    U_ANCHO_C: "",
    U_ALTURA_C: "",
    U_ANADA: "",
    Mainsupplier: "",
    ArTaxCode: "",
    APTaxCode: "",
    ItemsGroupCode: "",
    VatLiable: "",
    IndirectTax: "",
    ManageBatchNumbers: "",
    UoMGroupEntry: "",
    PurchaseItem: "",
    SalesItem: "",
    InventoryItem: "",
    InventoryUOM: "",
    DefaultSalesUoMEntry: "",
    SalesItemsPerUnit: "",
    DefaultPurchasingUoMEntry: "",
    PurchaseItemsPerUnit: "",
    U_Art_Importacion: "",
    U_TIPO: "",
  };
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<LayoutOutlet />}>
            <Route path="/" element={<Home />} />
            <Route
              path="/save/:order_uniq_id/:vendor_code"
              element={<Save />}
            ></Route>
            <Route
              path="/modify/:order_uniq_id/:vendor_code/:vendor_name"
              element={<Modify />}
            ></Route>
            <Route
              path="/rejection/:order_uniq_id/:vendor_code/:vendor_name"
              element={<Rejection2 />}
            ></Route>
            <Route
              path="/afterpo/rejection/:order_uniq_id/:vendor_code/:vendor_name"
              element={<Rejection2 />}
            ></Route>
            <Route
              path="/accept/:order_uniq_id/:vendor_code/:vendor_name"
              element={<Accept />}
            ></Route>
            <Route
              path="/reject/:order_uniq_id/:vendor_code"
              element={<Reject />}
            ></Route>
            <Route
              path="/partial-reject/:order_uniq_id/:vendor_code/:vendor_name/itemlist"
              element={<PartialReject />}
            ></Route>
            <Route
              path="/new-item-update/:order_uniq_id/:vendor_code/:vendor_name/itemlist"
              element={<NewItemUpdate newVendorDetails={newVendorDetails} />}
            ></Route>

            <Route
              path="/updated-order-accept/:order_uniq_id/:vendor_code/itemlist"
              element={<UpdatedOrderAccept />}
            ></Route>

            <Route
              path="/onaccept/:order_uniq_id/:vendor_code/:vendor_name"
              element={<PageAfterAccept />}
            ></Route>
            <Route path="/saved" element={<Save2 />}></Route>

            <Route path="/afteremail" element={<Afteremail />}></Route>
            <Route
              path="/afterpo/accept/:order_uniq_id/:vendor_code/:vendor_name"
              element={<Acceptpo />}
            ></Route>
            <Route path="/aftersave" element={<Aftersave />}></Route>
            {/* new pages */}
            <Route
              path="/upload-document/:document_uniq_id/:broker_name"
              element={<Uploaddocument />}
            ></Route>
            <Route
              path="/missing-document/:document_uniq_id/:broker_name"
              element={<Missingdocument />}
            ></Route>
            <Route
              path="/domestic-accept/:order_uniq_id/:vendor_code/:vendor_name"
              element={<DomesticAccept />}
            ></Route>
            <Route
              path="/domestic-reject/:order_uniq_id/:vendor_code/:vendor_name"
              element={<Rejection2 />}
            ></Route>
            <Route
              path="/pickup-confirmation/:po/:booking_id/:customer_id"
              element={<PickupConfirmation />}
            ></Route>
            <Route
              path="/container-arrival-confirmation/:booking_id"
              element={<ArrivalConformation />}
            ></Route>
          </Route>
          {/* warehouse*/}
          <Route path="/" element={<LayoutOutlet2 />}>
            <Route path="/extraction-file" element={<ExtractionFile />}></Route>
            <Route path="/plan-de-marbeteo" element={<Marktax />}></Route>
            <Route path="/upload-daily" element={<UploadDaily />}></Route>
            <Route path="/manage-daily" element={<ManageDaily />}></Route>
            <Route path="/actions" element={<ActionTaken />}></Route>
            <Route path="/company-list" element={<CompanyList />}></Route>
            <Route path="/choose_activity" element={<Choose_activity />}></Route>
            <Route path="/distribution_activities" element={<Distribution_activities />}></Route>
            <Route path="/grpup-invoice" element={<Group_invoice />}></Route>
            <Route path="/grpup-return-orders" element={<Group_return_orders />}></Route>
            <Route path="/distribution-status" element={<Distribution_status />}></Route>
            <Route
              path="/arrival-booking-list"
              element={<ArrivalConformation />}
            ></Route>
            <Route path="/arrival-details" element={<ArrivalDetails />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
