import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../pages/srcimages/logo.png";
function NavBar() {
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} style={{ width: "140px", maxHeight: "52px" }}></img>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
