import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../pages/srcimages/logo.png";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loader from "../components/Loader";
import axios from "axios";
import moment from "moment";
function NavBar2() {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const [greeting, setGreeting] = useState("");

  const updateGreeting = () => {
    const currentTime = moment();
    const currentHour = currentTime.hour();
    let newGreeting = "";

    if (currentHour >= 5 && currentHour < 12) {
      newGreeting = "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      newGreeting = "Good Afternoon";
    } else {
      newGreeting = "Good Evening";
    }

    // You can replace 'name' with the actual name variable from your state or props
    newGreeting +=
      " " + JSON.parse(localStorage.getItem("mtm_user_data"))?.user_full_name;

    setGreeting(newGreeting);
  };
  async function logoutButton() {
    setIsLoading(true);
    try {
      let email = JSON.parse(localStorage.getItem("mtm_user_data"))?.email;

      const data = {
        email: email,
        app_type: "everest",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}mtmuser/logout`,
        data
      );

      setIsLoading(false);
      if (response.status == 200 && response.data.success) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
      } else {
        alert("Error", response.data.msg);
        console.log("Error", response.data.msg);
      }
    } catch (error) {
      setIsLoading(false);

      alert("Error: " + error?.response?.data?.msg);
      console.log("Error", error);
    }
  }
  useEffect(() => {
    updateGreeting(); // Initial update when the component mounts
  }, []);
  return (
    <>
      <Navbar expand="lg">
        <Container>
          <div className="d-flex align-items-center">
            <Navbar.Brand href="/choose_activity">
              <img
                src={logo}
                style={{ width: "140px", maxHeight: "52px" }}
              ></img>
            </Navbar.Brand>
            <div className="fw-600 mt-1 fs-18" style={{ fontSize: "18px" }}>
              {greeting}
            </div>
          </div>
          <div>
            <button
              className="p-0 m-0 border-0"
              style={{ background: "transparent" }}
              onClick={logoutButton}
            >
              {loading ? (
                <Loader />
              ) : (
                <svg className="icon fs-24">
                  <use href="#icon_logout"></use>
                </svg>
              )}
            </button>
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar2;
