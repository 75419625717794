import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Processing from "../components/Processing";
import Icondelete from "../components/Icondelete";

function PartialReject() {
  const [vendorListTotal, setvendorListTotal] = useState({});
  const params = useParams();
  const { order_uniq_id, vendor_code, vendor_name } = params;
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const [showVendorNew, setShowVendorNew] = useState(false);
  const [selectedIdNew, setselectedIdNew] = useState({});
  const [selectedType, setselectedType] = useState("");
  const navigate = useNavigate();
  const [showVendorName, setshowVendorName] = useState(false);
  const handleCloseVendorName = () => setshowVendorName(false);
  const handleShowVendorName = () => setshowVendorName(true);
  const [loading, setloading] = useState(false);
  const [submitting, setsubmitting] = useState(false);
  const [submitError, setsubmitError] = useState({
    status: false,
    message: "",
  });
  const postGettingList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/partial-reject/itemlist`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });

      if (response.status == 200) {
        console.log(response, "response");
        setvendorListTotal(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postSubmitButton = async () => {
    try {
      setsubmitting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/partial-reject/submit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });

      if (response.status == 200) {
        if (!response.data.error) {
          setsubmitting(false);
          navigate("/saved", {
            replace: true,
            state: {
              message: response?.data?.message,
            },
          });
        } else {
          setsubmitError({
            status: true,
            message: response.data.message,
          });
          setsubmitting(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const checkStatusApi = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloading(true);
          postGettingList();
        } else {
          setloading(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    checkStatusApi();
  }, []);
  const deleteVendorNew = async (selectedId) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/partial-reject/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          group_code: selectedId?.group_code,
          item_type: selectedType,
          item_id: selectedType == "old" ? "" : selectedId?.item_id,
        },
      });
      if (response.status == 200) {
        postGettingList();
        setShowVendorNew(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Container>
            <Row>
              <Col md={12}>
                <div className="wrapper-box">
                  <h2 className="text-center fw-700 fs-30">
                    {vendor_name} ({vendor_code})
                  </h2>
                </div>

                <div className="modify-tbl">
                  <table className="table text-center border my-4">
                    <tbody>
                      <tr>
                        <th>Serial Number</th>
                        <th>Description</th>
                        <th>SKU</th>
                        <th>Vendor code</th>
                        <th>Original Buy Quantity</th>
                        <th>Vendor quantity</th>
                        <th>Unit cost</th>
                        <th>Total cost</th>
                      </tr>
                      {vendorListTotal?.orders?.map(
                        (vendorpr, vendor_indexpr) => (
                          <tr key={vendorpr?.description}>
                            <td>
                              <button
                                type="button"
                                className="tbl-delete me-3"
                                onClick={() => {
                                  setselectedIdNew(vendorpr);
                                  setselectedType("old");
                                  handleShowVendorNew();
                                }}
                              >
                                <svg className="icon fs-22">
                                  <use href="#icon_delete"></use>
                                </svg>
                              </button>
                              {vendor_indexpr + 1}
                            </td>
                            <td>{vendorpr?.description}</td>
                            <td>{vendorpr?.sap_code}</td>
                            <td>{vendor_code}</td>
                            <td>{vendorpr?.original_buy_qty}</td>
                            <td>{vendorpr?.vendor_qty}</td>
                            <td>{vendorpr?.unit_cost}</td>
                            <td>{vendorpr?.total_cost}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                {vendorListTotal?.newitem_orders?.length > 0 && (
                  <h2 className="d-flex pt-1">Newly Added Records</h2>
                )}

                {vendorListTotal?.newitem_orders?.length > 0 && (
                  <div className="modify-tbl">
                    <table className="table text-center border my-4">
                      <tbody>
                        <tr>
                          <th>Serial Number</th>
                          <th>Description</th>
                          <th>% Alcohol</th>
                          <th>Quantity</th>
                          <th>#Bottles per box</th>
                          <th>price</th>
                          <th>UPC</th>
                          <th>MOQ</th>
                          <th>Barcode</th>
                        </tr>
                        {vendorListTotal?.newitem_orders?.map(
                          (vendor, vendor_index) => (
                            <tr key={vendor?.description}>
                              <td>
                                <button
                                  type="button"
                                  className="tbl-delete me-3"
                                  onClick={() => {
                                    setselectedIdNew(vendor);
                                    setselectedType("new");
                                    handleShowVendorNew();
                                  }}
                                >
                                  <svg className="icon fs-22">
                                    <use href="#icon_delete"></use>
                                  </svg>
                                </button>
                                {vendor_index + 1}
                              </td>
                              <td>{vendor?.description}</td>
                              <td>{vendor?.alcohol}</td>
                              <td>{vendor?.qty}</td>
                              <td>{vendor?.box}</td>
                              <td>{vendor?.price}</td>
                              <td>{vendor?.upc}</td>
                              <td>{vendor?.moq}</td>
                              <td>{vendor?.barcode}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="d-flex justify-content-end">
                  <div className="ml-2">
                    <button
                      type="button"
                      className="mt-2 py-2 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                      onClick={() => handleShowVendorName()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
            <Modal.Body className="p-4">
              <p className="fs-4 text-center">
                Are you sure you want to delete this Record ?
              </p>
              <div className="d-flex justify-content-center mt-2">
                <Button
                  variant="secondary"
                  className="me-3"
                  onClick={handleCloseVendorNew}
                >
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={() => {
                    deleteVendorNew(selectedIdNew);
                  }}
                >
                  Delete
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={showVendorName} onHide={handleCloseVendorName} centered>
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body>
              <p className="fs-4 text-center m-0 p-0">
                Are you sure you want to submit?
              </p>

              {submitError.status && (
                <div className="d-flex justify-content-end mt-2 text-danger">
                  <div>{submitError.message}</div>
                </div>
              )}
              <div className="d-flex justify-content-end mt-4">
                <div>
                  <button
                    type="button"
                    disabled={submitting}
                    className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                    onClick={() => {
                      postSubmitButton();
                    }}
                  >
                    {submitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          <Processing />
        </>
      )}
    </>
  );
}

export default PartialReject;
