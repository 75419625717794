import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import save from "./srcimages/save.png";
import { useParams } from "react-router-dom";
import axios from "axios";
import Processing from "../components/Processing";

function Accept() {
  const params = useParams();
  const { order_uniq_id, vendor_code } = params;
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  console.log(params, "params");

  const postVenderOrderList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/accept`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        setloading(true);
        seterrorMessage(response.data.message);
      }
    } catch (error) {
      setloading(true);
      console.log(error, "error");
    }
  };
  useEffect(() => {
    if ((order_uniq_id, vendor_code)) {
      postVenderOrderList();
    }
  }, []);

  return (
    <>
      {loading ? (
        <Container>
          <Row>
            <Col md={12}>
              <div className="wrapper-box">
                <div className="d-flex justify-content-center">
                  <div style={{ width: "420px" }}>
                    <img className="w-100" src={save} />
                  </div>
                </div>

                <p className="fw-bold fs-3 text-center py-4">{errorMessage}</p>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Processing />
        </>
      )}
    </>
  );
}

export default Accept;
