import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import BackButton from "../components/BackButton";

function ArrivalConformation() {
  const navigate = useNavigate();
  const [selectedTab, setselectedTab] = useState("Upcoming");
  const [gettingBookingList, setgettingBookingList] = useState(false);
  const [postBookingList, setpostBookingList] = useState(false);
  const [bookingList, setbookingList] = useState([]);
  const [selectedBookingItem, setselectedBookingItem] = useState({});
  const [postBookingListError, setpostBookingListError] = useState(null);

  const [showopen, setShowopen] = useState(false);
  const handleCloseopen = () => setShowopen(false);
  const handleShowopen = () => setShowopen(true);
  const [loginData, setloginData] = useState({});
  const getAllBookingList = async () => {
    setgettingBookingList(false);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/getall/bookings`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          warehouse_id:JSON.parse(localStorage.getItem("mtm_user_data"))?.warehouses[0].warehouse_id,
        },
      });
      if (response.status == 200) {
        setgettingBookingList(true);
        if (!response.data.error) {
          console.log(response.data, "res data");
          setbookingList(response.data.bookings);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const postBookingListFn = async () => {
    setpostBookingListError(null);
    setpostBookingList(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/booking/mark/completed`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          booking_id: selectedBookingItem?.booking_id,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          warehouse_id:JSON.parse(localStorage.getItem("mtm_user_data"))?.warehouses[0].warehouse_id,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setpostBookingList(false);
          handleCloseopen();
          getAllBookingList();
        } else {
          setpostBookingListError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setpostBookingList(false);
      setpostBookingListError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const convertDateFormat = (inputDate) => {
    const dateObj = new Date(inputDate);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month because it's zero-based
    const year = dateObj.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    setloginData(JSON.parse(localStorage.getItem("mtm_user_data")));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      getAllBookingList();
    }
  }, []);
  return (
    <>
      <Container>
        <Row className="pb-2">
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-between align-items-center">
                <BackButton navi="actions" />
                <div>
                  <h2 className="text-center fw-700 fs-30">
                    Arrival Confirmation
                  </h2>
                </div>
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="w-100 tabs-row pt-4">
          <ul
            className="nav nav-pills mb-3 w-100"
            id="pills-tab"
            role="tablist"
          >
            <li className={`nav-item`} role="presentation">
              <button
                className={`nav-link ${selectedTab == "Upcoming" && "active"}`}
                id="pills-Upcoming-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Upcoming"
                type="button"
                role="tab"
                aria-controls="pills-Upcoming"
                aria-selected="true"
                onClick={() => {
                  setselectedTab("Upcoming");
                }}
              >
                <svg className="icon fs-22 me-2">
                  <use href="#icon_upcoming"></use>
                </svg>
                Upcoming
              </button>
            </li>
            <li className={`nav-item`} role="presentation">
              <button
                className={`nav-link ${selectedTab == "Past" && "active"}`}
                id="pills-Past-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Past"
                type="button"
                role="tab"
                aria-controls="pills-Past"
                aria-selected="false"
                onClick={() => {
                  setselectedTab("Past");
                }}
              >
                <svg className="icon fs-22 me-2">
                  <use href="#icon_checkcircle"></use>
                </svg>
                Past
              </button>
            </li>
          </ul>
        </div>
        {!gettingBookingList && (
          <div className="w-100 mt-5 text-center">
            <Loader />
          </div>
        )}
        {gettingBookingList &&
          selectedTab == "Upcoming" &&
          bookingList
            ?.filter((item) => !item?.isAllProcessDone)
            ?.map((item, index) => (
              <div className="row rows-cols-1 row-cols-md-1 g-4 py-4">
                <div className="col">
                  <div className="row arriv-box d-flex justify-content-center border-boxs">
                    <div className="px-3 col-auto">
                      <p>Booking Number</p>
                      <p className="fw-bold pt-2">{item?.booking_id}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>Total Containers</p>
                      <p className="fw-bold pt-2">{item?.totalContainers}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>POL</p>
                      <p className="fw-bold pt-2">{item?.port_of_loading}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>POD</p>
                      <p className="fw-bold pt-2">{item?.port_of_discharge}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>Pedimento</p>
                      <p className="fw-bold pt-2">
                        {item?.custom_declration_number}
                      </p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>Vendors</p>
                      <p className="fw-bold pt-2">{item?.vendor_names}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>All Task Completed</p>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheck"
                        checked={item?.checked}
                        onClick={() => {
                          setbookingList((prev) =>
                            prev.map((b) => {
                              if ((b.booking_id = item.booking_id)) {
                                return { ...b, checked: true };
                              } else {
                                return { ...b, checked: false };
                              }
                            })
                          );
                          setselectedBookingItem(item);
                          handleShowopen();
                        }}
                      ></input>
                    </div>
                    <div className="px-3 col-auto d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        className="arrow-btn"
                        onClick={() =>
                          navigate("/arrival-details", {
                            state: {
                              booking_id: item.booking_id,
                            },
                          })
                        }
                      >
                        <svg className="icon fs-24">
                          <use href="#icon_circlearrow"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        {gettingBookingList &&
          selectedTab == "Upcoming" &&
          bookingList?.filter((item) => !item?.isAllProcessDone) == 0 && (
            <div className="w-100 mt-5 text-center fw-600 fs-30">
              No Bookings Available
            </div>
          )}

        {gettingBookingList &&
          selectedTab == "Past" &&
          bookingList
            ?.filter((item) => item?.isAllProcessDone)
            ?.map((item, index) => (
              <div className="row rows-cols-1 row-cols-md-1 g-4 py-4">
                <div className="col">
                  <div className="row arriv-box d-flex justify-content-center border-boxs">
                    <div className="px-3 col-auto">
                      <p>Booking Number</p>
                      <p className="fw-bold pt-2">{item?.booking_id}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>Total Containers</p>
                      <p className="fw-bold pt-2">{item?.totalContainers}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>POL</p>
                      <p className="fw-bold pt-2">{item?.port_of_loading}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>POD</p>
                      <p className="fw-bold pt-2">{item?.port_of_discharge}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>Pedimento</p>
                      <p className="fw-bold pt-2">
                        {item?.custom_declration_number}
                      </p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>Vendors</p>
                      <p className="fw-bold pt-2">{item?.vendor_names}</p>
                    </div>
                    <div className="px-3 col-auto">
                      <p>Task Completed On</p>
                      <p className="fw-bold pt-2">
                        {convertDateFormat(item?.arrival_confirmed_date)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        {gettingBookingList &&
          selectedTab == "Past" &&
          bookingList?.filter((item) => item?.isAllProcessDone) == 0 && (
            <div className="w-100 mt-5 text-center fw-600 fs-30">
              No Bookings Available
            </div>
          )}
      </Container>

      <Modal
        size="lg"
        show={showopen}
        centered
        onHide={() => {
          setpostBookingListError(null);
          setbookingList((prev) =>
            prev.map((b) => {
              return { ...b, checked: false };
            })
          );
          handleCloseopen();
        }}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="fw-600 fs-24 text-center pb-3 text-capitalize">
            Are you <strong>sure</strong> All Tasks <strong>Completed</strong>{" "}
            for The booking Number
          </p>
          <div className="pb-4">
            <p className="text-center fs-30 fw-700">
              {selectedBookingItem?.booking_id}
            </p>
          </div>
          {postBookingListError && (
            <div className="text-center text-danger pb-3">
              {postBookingListError}
            </div>
          )}

          <div className="text-center pb-3">
            <button
              type="button"
              className="normal-btn rounded-pill me-4 py-1"
              onClick={() => {
                setpostBookingListError(null);
                setbookingList((prev) =>
                  prev.map((b) => {
                    return { ...b, checked: false };
                  })
                );
                handleCloseopen();
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="submit-btn rounded-pill py-1"
              disabled={postBookingList}
              onClick={postBookingListFn}
            >
              {postBookingList ? <Loader /> : "Submit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ArrivalConformation;
