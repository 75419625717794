import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import save from "./srcimages/save.png";
import { useParams } from "react-router-dom";
import axios from "axios";

function Reject() {
  const params = useParams();
  const { order_uniq_id, vendor_code } = params;
  const [error, seterror] = useState(true);
  const [errorMessage, seterrorMessage] = useState("");
  console.log(params, "params");

  const postVenderOrderList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/reject`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          seterror(false);
        } else {
          seterror(true);
          seterrorMessage(response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    if ((order_uniq_id, vendor_code)) {
      postVenderOrderList();
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-center">
                <div style={{ width: "420px" }}>
                  <img className="w-100" src={save} />
                </div>
              </div>
              {error ? (
                <p className="fw-bold fs-3 text-center py-4">{errorMessage}</p>
              ) : (
                <p className="fw-bold fs-3 text-center py-4">
                  We have saved your response
                </p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Reject;
