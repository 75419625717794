import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Routing from "./components/Routing";
import Icons from "./components/Icons";
import UserState from "./context/UserState";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserState>
      <Routing></Routing>
      <Icons></Icons>
    </UserState>
  </React.StrictMode>
);
reportWebVitals();
