import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";

function ActionTaken() {
  const navigate = useNavigate();
  const location = useLocation();

  // location.search contains the query string, e.g., "?param1=value1&param2=value2"
  const queryString = location.search;

  // Use a utility function to parse the query string into an object

  const [bookingList, setbookingList] = useState([]);
  const [gettingBookingList, setgettingBookingList] = useState(false);
  const [loginData, setloginData] = useState({});
  const [wareHouses, setwareHouses] = useState([]);
  const [selectedwarehouseId, setselectedwarehouseId] = useState("");
  const [gettingDetails, setgettingDetails] = useState(false);

  console.log(location, "location");
  const getDetails = async () => {
    try {
      setgettingDetails(false);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/get/warehouse_list`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          company_name: localStorage.getItem("company_name"),
        },
      });
      if (response.status == 200) {
        setgettingDetails(true);
        if (!response.data.error) {
          console.log(response.data.warehouses, "response data");
          let warehouseLogin = JSON.parse(
            localStorage.getItem("mtm_user_data")
          );
          delete warehouseLogin["warehouses"];
          localStorage.setItem("mtm_user_data", JSON.stringify(warehouseLogin));
          setTimeout(() => {
            warehouseLogin["warehouses"] = response.data.warehouses;
            localStorage.setItem(
              "mtm_user_data",
              JSON.stringify(warehouseLogin)
            );
            getWareHouses(response.data.warehouses);
          }, 500);
        }
      }
    } catch (error) {
      console.log(error);
      setgettingDetails(true);

      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const getAllBookingList = async () => {
    setgettingBookingList(false);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/getall/bookings`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          warehouse_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.warehouses[0].warehouse_id,
        },
      });
      if (response.status == 200) {
        setgettingBookingList(true);

        if (!response.data.error) {
          console.log(response.data, "res data");
          setbookingList(response.data.bookings);
        }
      }
    } catch (error) {
      console.log(error);
      setgettingBookingList(true);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const getWareHouses = (wareHouses) => {
    wareHouses = wareHouses?.filter(
      (item) => item?.company_name == localStorage.getItem("company_name")
    );
    setwareHouses(wareHouses);
  };

  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      getAllBookingList();
      setloginData(JSON.parse(localStorage.getItem("mtm_user_data")));

      getDetails();
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <BackButton navi={"company-list"} />
                </div>
                <h2 className="text-center fw-700 fs-30">
                  <div>{localStorage.getItem("company_name")}</div>
                  Actions To Be Taken in
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {wareHouses?.map((item, index) => (
                      <div>
                        {item?.warehouse_name}
                        <span>{index != wareHouses?.length - 1 && ","}</span>
                      </div>
                    ))}
                  </div>
                </h2>

                <div></div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="row rows-cols-1 row-cols-md-3 g-4 py-4">
          <div className="col">
            <button
              type="button"
              className="action-btn w-100"
              onClick={() => navigate("/arrival-booking-list")}
            >
                Arrival Confirmation <br/> 
                {gettingBookingList && (
                  <>
                    (Pending:{" "}
                    {
                      bookingList?.filter((item) => !item?.isAllProcessDone)
                        ?.length
                    }
                    )
                  </>
                )}
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              className="action-btn w-100"
              onClick={() => navigate("/extraction-file")}
            >
              Upload Extraction file
            </button>
          </div>
          <div className="col">
            <button
              type="button"
              className="action-btn w-100"
              onClick={() => navigate(`/plan-de-marbeteo`)}
            >
              Plan De Marbeteo
            </button>
          </div>
          {/* <div className="col">
            <button
              type="button"
              className="action-btn w-100"
              onClick={() => navigate("/upload-daily")}
            >
              Upload Daily Priority<br/>
              SKUs
            </button>
          </div> */}
          <div className="col">
            <button
              type="button"
              className="action-btn w-100"
              onClick={() => navigate("/manage-daily")}
            >
              Manage Daily Tagged<br/>
              SKUs
            </button>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ActionTaken;
