import { Outlet } from "react-router-dom";
import NavBar2 from "./Navbar2";

function LayoutOutlet2() {
  return (
    <>
      <NavBar2></NavBar2>
      <div className="wrapper">
        <Outlet></Outlet>
      </div>
    </>
  );
}
export default LayoutOutlet2;
