import React from "react";

function Loader({text="text-primary"}) {
  return (
    <div
      className={`spinner-border ${text}`}
      role="status"
      title="loading..."
    >
      <span className="visually-hidden ">Loading...</span>
    </div>
  );
}

export default Loader;
