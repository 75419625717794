import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import BackButton from "../components/BackButton";

function Marktax() {
  const navigate = useNavigate();
  const [gettingDetails, setgettingDetails] = useState(false);
  const [details, setdetails] = useState([]);
  const [submittingPoList, setsubmittingPoList] = useState(false);
  const [submittingPoError, setsubmittingPoError] = useState(null);
  const [greaterThanError, setgreaterThanError] = useState(false);
  const [wareHouses, setwareHouses] = useState([]);
  const [selectedwarehouseId, setselectedwarehouseId] = useState("");
  const location = useLocation();

  // location.search contains the query string, e.g., "?param1=value1&param2=value2"
  const queryString = location.search;
  const getDetails = async () => {
    try {
      setgettingDetails(false);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/labling/plan/itemlist`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
          warehouse_id: selectedwarehouseId,
          company_name: localStorage.getItem("company_name"),
        },
      });
      if (response.status == 200) {
        setgettingDetails(true);
        if (!response.data.error) {
          console.log(response.data, "res data");

          setdetails(response.data.items || []);
        } else {
          setdetails([]);
        }
      }
    } catch (error) {
      console.log(error);
      setgettingDetails(true);
      setdetails([]);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const getWareHouses = () => {
    let wareHouses = JSON.parse(
      localStorage.getItem("mtm_user_data")
    )?.warehouses;
    wareHouses = wareHouses?.filter(
      (item) => item?.company_name == localStorage.getItem("company_name")
    );
    setwareHouses(wareHouses);
  };
  const postDetails = async () => {
    setsubmittingPoError(null);
    setsubmittingPoList(true);
    let arraydata = details
      .filter((item) => item?.tax_paid_qty_new && item?.tax_paid_qty_new != "")
      .map((item) => {
        return {
          item_code: item.item_code,
          tax_paid_qty: parseInt(item.tax_paid_qty_new) || null,
          pedimento_a4: item?.pedimento_a4,
          extraction_date: item?.priority_date,
        };
      });
    let data = {
      items: arraydata,
      customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
        ?.customer_id,
      user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
      warehouse_id: selectedwarehouseId,
      company_name: localStorage.getItem("company_name"),
    };
    // console.log(data, "data...");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/labling/plan/taxQty/submit`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: data,
      });
      if (response.status == 200) {
        setsubmittingPoList(false);
        if (!response.data.error) {
          getDetails();
        } else {
          setsubmittingPoError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setsubmittingPoList(false);
      setsubmittingPoError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const checkDisabled = () => {
    for (const element of details) {
      if (
        parseInt(element?.extraction_qty || 0) -
          parseInt(element?.tax_paid_qty || 0) -
          parseInt(element?.tax_paid_qty_new || 0) <
        0
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      if (selectedwarehouseId != "") {
        getDetails();
      }
      getWareHouses();
    }
  }, [selectedwarehouseId]);

  console.log(wareHouses, "ware houses");

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-between align-items-center">
                <BackButton navi="actions" />
                <div>
                  <h2 className="text-center fw-700 fs-30">
                    <div>{localStorage.getItem("company_name")}</div>
                    Plan De Marbeteo
                  </h2>
                </div>
                <div></div>
              </div>
            </div>
            <div>
              <div className="me-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{ width: "200px" }}
                  value={selectedwarehouseId}
                  onChange={(e) => setselectedwarehouseId(e.target.value)}
                >
                  <option value="">Choose Warehouse</option>
                  {wareHouses?.map((item, index) => (
                    <option value={item?.warehouse_id} key={index}>
                      {item?.warehouse_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Col>
          {!gettingDetails && selectedwarehouseId != "" && (
            <div className="w-100 mt-5 text-center">
              <Loader />
            </div>
          )}
          {selectedwarehouseId == "" && (
            <div className="w-100 mt-5 text-center fw-500 fs-30">
              Please Choose Warehouse
            </div>
          )}
          {gettingDetails &&
            details?.length == 0 &&
            selectedwarehouseId != "" && (
              <div className="w-100 mt-5 text-center fw-500 fs-30">
                No Items Found
              </div>
            )}
          {details?.length > 0 &&
            gettingDetails &&
            selectedwarehouseId != "" && (
              <>
                {" "}
                <Col md={12}>
                  <div className="modify-tbl">
                    <table className="table text-center border my-4">
                      <tbody>
                        <tr>
                          <th>SKU</th>
                          <th>Description</th>
                          <th>Custom Declaration Number</th>
                          <th>Extraction Date</th>
                          <th>Total Tax Quantity</th>
                          <th>Already Paid Tax Quantity</th>
                          <th>Tags Priority</th>
                          <th>Paid Tax Quantity</th>
                          <th>Pending Quantity</th>
                        </tr>
                        {details.map((item, index) => (
                          <tr key={item?.id}>
                            <td>{item?.item_code}</td>
                            <td>{item?.item_description}</td>
                            <td>{item?.pedimento_a4}</td>
                            <td>{item?.priority_date}</td>
                            <td>{item?.extraction_qty}</td>
                            <td>{item?.tax_paid_qty}</td>
                            <td>{item?.tags_priority}</td>
                            <td width="100px">
                              <input
                                type="number"
                                className="text-center"
                                value={item?.tax_paid_qty_new}
                                onChange={(e) => {
                                  setdetails((prev) =>
                                    prev.map((detail) => {
                                      if (detail.id == item.id) {
                                        return {
                                          ...detail,
                                          tax_paid_qty_new: e.target.value,
                                        };
                                      } else {
                                        return detail;
                                      }
                                    })
                                  );
                                }}
                                disabled={
                                  parseInt(item?.extraction_qty || 0) -
                                    parseInt(item?.tax_paid_qty || 0) ==
                                  0
                                }
                              ></input>
                              <p className="text-center text-danger position-relative">
                                {parseInt(item?.extraction_qty || 0) -
                                  parseInt(item?.tax_paid_qty || 0) -
                                  parseInt(item?.tax_paid_qty_new || 0) <
                                  0 &&
                                  "Paid Tax Quantity cannot be greater than Total Tax Quantity"}
                              </p>
                            </td>
                            <td>
                              {parseInt(item?.extraction_qty || 0) -
                                parseInt(item?.tax_paid_qty || 0) -
                                parseInt(item?.tax_paid_qty_new || 0) || 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
                <Col
                  md={12}
                  className="d-flex py-4"
                  style={{
                    background: "white",
                    position: "sticky",
                    bottom: 0,
                  }}
                >
                  <div className="ms-auto">
                    {submittingPoError && (
                      <div className="ms-auto text-danger pb-2">
                        {submittingPoError}
                      </div>
                    )}
                    <div>
                      <button
                        type="button"
                        className="normal-btn rounded-pill me-4"
                        onClick={() => {
                          navigate("/actions");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="submit-btn rounded-pill"
                        disabled={submittingPoList || checkDisabled()}
                        onClick={postDetails}
                      >
                        {submittingPoList ? <Loader /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </Col>
              </>
            )}
        </Row>
      </Container>
    </>
  );
}

export default Marktax;
