import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import UploadComponent from "../components/UploadComponent";
import BackButton from "../components/BackButton";
import { useNavigate } from "react-router-dom";

function UploadDaily() {
  const navigate = useNavigate();
  const convertDateFormat = () => {
    const dateObj = new Date();
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month because it's zero-based
    const year = dateObj.getFullYear().toString();

    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-between align-items-center">
                <BackButton navi="actions" />
                <div>
                  <h2 className="text-center fw-700 fs-30">
                    Upload Daily Priority SKUs To Be Tagged For
                  </h2>
                  <h2 className="text-center fw-700 fs-30 pt-2">
                    {convertDateFormat()}
                  </h2>
                </div>
                <div></div>
              </div>
            </div>
          </Col>
          <UploadComponent
            api={"mtmwarehose/daily/priority/upload"}
            from="daily"
            href="daily_tag_upload_min.xlsx"
          />
        </Row>
      </Container>
    </>
  );
}

export default UploadDaily;
