import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Drawer, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Processing from "../components/Processing";
import Icondelete from "../components/Icondelete";

function NewItemUpdate({ newVendorDetails }) {
  const [vendorListTotal, setvendorListTotal] = useState({});
  const params = useParams();
  const { order_uniq_id, vendor_code, vendor_name } = params;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedIdNew, setselectedIdNew] = useState({});
  const [showVendorName, setshowVendorName] = useState(false);
  const handleCloseVendorName = () => setshowVendorName(false);
  const handleShowVendorName = () => setshowVendorName(true);
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState(false);
  const [error2, seterror2] = useState(false);
  const [loading, setloading] = useState(false);
  const [errorWhileSaving, seterrorWhileSaving] = useState({
    status: false,
    data: "",
  });
  const [saving, setsaving] = useState(false);
  const [submitting, setsubmitting] = useState(false);
  console.log(show, selectedIdNew, handleShow);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [addNewVendor, setaddNewVendor] = useState({ ...newVendorDetails });

  let addNewVendorMappingNames = {
    description: "Description",
    qty: "Quantity",
    upc: "UPC",
    alcohol: "% Alcohol",
    box: "#Bottles per box",
    price: "Price",
    moq: "MOQ",
    barcode: "Código de Barras de PIEZA -UPC  (botella) (Barcode)",
    item_code: "Codigo del articulo SAP (Item code / SKU)",
    ItemName: "Descripcion del articulo SAP (Item Name)",
    U_PROVEEDOR: "Bodega / Proveedor (U_PROVEEDOR)",
    U_GVINOS: "Grandes Vinos (U_GVINOS)",
    U_MARCA: "Marca (U_MARCA)",
    U_PAIS: "PAIS (U_PAIS)",
    U_FACTOR: "Pieza/botellas por caja (U_FACTOR)",
    U_CAPACIDAD: "Mililitros (U_CAPACIDAD)",
    U_COSTO_ORI: "Costo Origen Proveedor (U_COSTO_ORI)",
    U_GRADO_ALC: "Grado alcohólico (U_GRADO_ALC)",
    MinOrderQuantity: "MOQ - mínimo de compra (estándar) (MinOrderQuantity)",
    LeadTime:
      "Tiempo de producción of order - disponibilidad (días) (LeadTime)",
    U_NCA_TARIMA: "Numero de cajas por tarima (U_NCA_TARIMA)",
    U_B_TARIMAS: "Botellas por tarima (U_B_TARIMAS)",
    U_TIPO_TARIMA: "Tipo de Tarima (U_TIPO_TARIMA)",
    U_DUN: "Código de Barras de CAJA (DUN-14) (U_DUN)",
    U_INCOTERM: "Incoterm (EXW, FOB, CIF) (U_INCOTERM)",
    U_LONGITUD: "Longitud (mm) botella (U_LONGITUD)",
    U_ANCHO: "Ancho (mm) botella (U_ANCHO)",
    U_ALTURA: "Altura (mm) botella (U_ALTURA)",
    U_PESO: "Peso (gr) botella (U_PESO)",
    U_LONGITUD_C: "Longitud Caja (mm) (U_LONGITUD_C)",
    U_ANCHO_C: "Ancho Caja (mm) (U_ANCHO_C)",
    U_ALTURA_C: "Altura Caja (mm) (U_ALTURA_C)",
    U_ANADA: "Añada (U_ANADA)",
    Mainsupplier: "Codigo de Proveedor en SAP (Mainsupplier)",
    ArTaxCode: "IEPS VENTA (ArTaxCode)",
    APTaxCode: "IEPS COMPRA (APTaxCode)",
    ItemsGroupCode: "GRUPO DE ARTUCLO (ItemsGroupCode)",
    VatLiable: "SUJETO A IMPUESTO (VatLiable)",
    IndirectTax: "IMPUESTO INDIRECTO (IndirectTax)",
    ManageBatchNumbers: "SUJETO A LOTE (ManageBatchNumbers)",
    UoMGroupEntry: "UNIDAD DE MEDIDA (UoMGroupEntry)",
    PurchaseItem: "ARTICULO DE COMPRA (PurchaseItem)",
    SalesItem: "ARTICULO DE VENTA (SalesItem)",
    InventoryItem: "ARTICULO INVENTARIABLE (InventoryItem)",
    InventoryUOM: "GRUPO DE UNIDAD DE MEDIDA (InventoryUOM)",
    DefaultSalesUoMEntry: "CODIGO UNIDAD DE VENTA (DefaultSalesUoMEntry)",
    SalesItemsPerUnit: "NUMERO DE UNIDAD DE VENTA (SalesItemsPerUnit)",
    DefaultPurchasingUoMEntry:
      "CODIGO UNIDAD DE COMPRA (DefaultPurchasingUoMEntry)",
    PurchaseItemsPerUnit: "NUMERO DE UNIDAD DE COMPRA (PurchaseItemsPerUnit)",
    U_Art_Importacion: "ARTICULO IMPORTACION/ NACIONAL (U_Art_Importacion)",
    U_TIPO: "TIPO DE ARTICULO (U_TIPO)",
  };
  let typeNumber = ["qty", "alcohol", "box", "price"];
  const postGettingList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/partial-reject/finalitemlist`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });

      if (response.status == 200) {
        console.log(response, "response");
        setvendorListTotal(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postSubmitButton = async () => {
    try {
      setsubmitting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/partial-reject/finalitem/submit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        setsubmitting(false);
        console.log(response, "response");
        navigate("/saved", {
          replace: true,
          state: {
            message: response?.data?.message,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postSaveButton = async () => {
    if (!addNewVendor?.item_code || addNewVendor?.item_code == "") {
      seterror(true);
      return;
    } else {
      seterror(false);
    }
    seterrorWhileSaving({
      status: false,
      data: "",
    });
    try {
      setsaving(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/partial-reject/finalitem/save`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ...addNewVendor,
          order_uniq_id,
          vendor_code,
          qty: parseInt(addNewVendor.qty),
          alcohol: parseInt(addNewVendor.alcohol),
          box: parseInt(addNewVendor?.box),
          price: parseInt(addNewVendor?.price),
        },
      });

      if (response.status == 200) {
        setsaving(false);
        if (!response.data.error) {
          handleClose();
          onClose();
          postGettingList();
          setaddNewVendor({ ...newVendorDetails });
        } else {
          seterrorWhileSaving({
            status: true,
            data: response.data.message,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleVendorDetails = (e) => {
    setaddNewVendor({ ...addNewVendor, [e.target.id]: e.target.value });
  };
  const checkStatus = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          page: "update",
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloading(true);
          postGettingList();
        } else {
          setloading(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  console.log(addNewVendor, "add new vendor");
  useEffect(() => {
    checkStatus();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Container>
            <Row>
              <Col md={12}>
                <div className="wrapper-box">
                  <h2 className="text-center fw-700 fs-30">
                    Newly Added Records By {vendor_name} ({vendor_code})
                  </h2>
                </div>

                <div className="modify-tbl">
                  <table className="table text-center border my-4">
                    <tbody>
                      <tr>
                        <th>Serial Number</th>
                        <th>Description</th>
                        <th>% Alcohol</th>
                        <th>Quantity</th>
                        <th>#Bottles per box</th>
                        <th>price</th>
                        <th>UPC</th>
                        <th>MOQ</th>
                        <th>Barcode</th>
                        <th>Item code/SKU</th>
                      </tr>
                      {vendorListTotal?.neworders?.map(
                        (vendor, vendor_index) => (
                          <tr key={vendor?.description}>
                            <td>
                              <button
                                type="button"
                                className="tbl-delete me-3"
                                onClick={() => {
                                  setselectedIdNew(vendor);
                                  setaddNewVendor({
                                    description: vendor?.description,
                                    qty: vendor?.qty,
                                    upc: vendor?.upc,
                                    alcohol: vendor?.alcohol,
                                    box: vendor?.box,
                                    price: vendor?.price,
                                    moq: vendor?.moq,
                                    barcode: vendor?.barcode,
                                    item_code: vendor?.item_code,
                                    item_id: vendor?.item_id,
                                    ItemName: vendor?.item_name,
                                    U_PROVEEDOR: vendor?.u_proveedor,
                                    U_GVINOS: vendor.u_gvinos,
                                    U_MARCA: vendor.u_marca,
                                    U_PAIS: vendor.u_pais,
                                    U_FACTOR: vendor.u_factor,
                                    U_CAPACIDAD: vendor.u_capacidad,
                                    U_COSTO_ORI: vendor.u_costo_ori,
                                    U_GRADO_ALC: vendor.u_grado_alc,
                                    MinOrderQuantity: vendor.min_order_quantity,
                                    LeadTime: vendor.lead_time,
                                    U_NCA_TARIMA: vendor.u_nca_tarima,
                                    U_B_TARIMAS: vendor.u_b_tarimas,
                                    U_TIPO_TARIMA: vendor.u_tipo_tarima,
                                    U_DUN: vendor.u_dun,
                                    U_INCOTERM: vendor.u_incoterm,
                                    U_LONGITUD: vendor.u_longitud,
                                    U_ANCHO: vendor.u_ancho,
                                    U_ALTURA: vendor.u_altura,
                                    U_PESO: vendor.u_peso,
                                    U_LONGITUD_C: vendor.u_longitud_c,
                                    U_ANCHO_C: vendor.u_ancho_c,
                                    U_ALTURA_C: vendor.u_altura_c,
                                    U_ANADA: vendor.u_anada,
                                    Mainsupplier: vendor.main_supplier,
                                    ArTaxCode: vendor.ar_tax_code,
                                    APTaxCode: vendor.ap_tax_code,
                                    ItemsGroupCode: vendor.items_group_code,
                                    VatLiable: vendor.vat_liable,
                                    IndirectTax: vendor.indirect_tax,
                                    ManageBatchNumbers:
                                      vendor.manage_batch_numbers,
                                    UoMGroupEntry: vendor.uom_group_entry,
                                    PurchaseItem: vendor.purchase_item,
                                    SalesItem: vendor.sales_item,
                                    InventoryItem: vendor.inventory_item,
                                    InventoryUOM: vendor.inventory_uom,
                                    DefaultSalesUoMEntry:
                                      vendor.default_sales_uom_entry,
                                    SalesItemsPerUnit:
                                      vendor.sales_items_per_unit,
                                    DefaultPurchasingUoMEntry:
                                      vendor.default_purchasing_uom_entry,
                                    PurchaseItemsPerUnit:
                                      vendor.purchase_items_per_unit,
                                    U_Art_Importacion: vendor.u_art_importacion,
                                    U_TIPO: vendor.u_tipo,
                                  });
                                  showDrawer();
                                }}
                              >
                                <Icondelete />
                              </button>
                              {vendor_index + 1}
                            </td>
                            <td>{vendor?.description}</td>
                            <td>{vendor?.alcohol}</td>
                            <td>{vendor?.qty}</td>
                            <td>{vendor?.box}</td>
                            <td>{vendor?.price}</td>
                            <td>{vendor?.upc}</td>
                            <td>{vendor?.moq}</td>
                            <td>{vendor?.barcode}</td>
                            <td>{vendor?.item_code}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                {error2 && (
                  <div className="d-flex justify-content-end text-danger">
                    Please enter Item code / SKU
                  </div>
                )}
                <div className="d-flex justify-content-end">
                  <div className="ml-2">
                    <button
                      type="button"
                      className="mt-2 py-2 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                      onClick={() => {
                        let every = false;

                        let dataAll = vendorListTotal?.neworders;

                        for (const element of dataAll) {
                          if (!element.item_code || element.item_code == "") {
                            every = true;
                            break;
                          }
                        }

                        if (every) {
                          seterror2(true);
                          return;
                        } else {
                          seterror2(false);
                        }
                        handleShowVendorName();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Modal show={showVendorName} onHide={handleCloseVendorName} centered>
            <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
            <Modal.Body>
              <p className="fs-4 text-center m-0 p-0">
                Are you sure you want to submit?
              </p>

              <div className="d-flex justify-content-end mt-4">
                <div>
                  <button
                    type="button"
                    disabled={submitting}
                    className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                    onClick={() => {
                      postSubmitButton();
                    }}
                  >
                    {submitting ? "Submitting..." : " Submit"}
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Drawer
            title="Edit Record"
            width={1020}
            onClose={() => {
              seterror(false);
              seterrorWhileSaving({
                status: false,
                data: "",
              });
              setaddNewVendor({
                order_uniq_id: "",
                vendor_code: "",
                description: "",
                qty: "",
                upc: "",
                alcohol: "",
                box: "",
                price: "",
                moq: "",
                barcode: "",
                item_code: "",
                item_id: "",
                ItemName: "",
                U_PROVEEDOR: "",
                U_GVINOS: "",
                U_MARCA: "",
                U_PAIS: "",
                U_FACTOR: "",
                U_CAPACIDAD: "",
                U_COSTO_ORI: "",
                U_GRADO_ALC: "",
                MinOrderQuantity: "",
                LeadTime: "",
                U_NCA_TARIMA: "",
                U_B_TARIMAS: "",
                U_TIPO_TARIMA: "",
                U_DUN: "",
                U_INCOTERM: "",
                U_LONGITUD: "",
                U_ANCHO: "",
                U_ALTURA: "",
                U_PESO: "",
                U_LONGITUD_C: "",
                U_ANCHO_C: "",
                U_ALTURA_C: "",
                U_ANADA: "",
                Mainsupplier: "",
                ArTaxCode: "",
                APTaxCode: "",
                ItemsGroupCode: "",
                VatLiable: "",
                IndirectTax: "",
                ManageBatchNumbers: "",
                UoMGroupEntry: "",
                PurchaseItem: "",
                SalesItem: "",
                InventoryItem: "",
                InventoryUOM: "",
                DefaultSalesUoMEntry: "",
                SalesItemsPerUnit: "",
                DefaultPurchasingUoMEntry: "",
                PurchaseItemsPerUnit: "",
                U_Art_Importacion: "",
                U_TIPO: "",
              });
              onClose();
            }}
            open={open}
            bodyStyle={{
              paddingBottom: 80,
            }}
            footer={
              <div className="ms-auto">
                <Space>
                  {error && (
                    <div className="text-danger mt-2">
                      Please enter Item code
                    </div>
                  )}
                  {errorWhileSaving?.status && (
                    <div className="text-danger mt-2">
                      {errorWhileSaving.data}
                    </div>
                  )}
                  <button
                    type="button"
                    disabled={saving}
                    className="mt-2 py-1 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                    onClick={postSaveButton}
                  >
                    {saving ? "Saving..." : "Save"}
                  </button>
                </Space>
              </div>
            }
            footerStyle={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div className="p-4">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "10px",
                  gridColumnGap: "30px",
                }}
              >
                {Object.entries(addNewVendorMappingNames).map(
                  ([key, value]) => (
                    <div className="input-box mb-4" key={key}>
                      <label className="fs-18 fw-600">
                        {value} {key == "item_code" && "*"}
                      </label>
                      <input
                        type={typeNumber.includes(key) ? "number" : "text"}
                        className="w-100"
                        // placeholder={`Enter ${value}`}
                        id={key}
                        value={addNewVendor[key]}
                        onChange={handleVendorDetails}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          <Processing />
        </>
      )}
    </>
  );
}

export default NewItemUpdate;
