import { useMemo, useRef, useState } from "react";
import UserContext from "./UserContext";
import axios from "axios";

const UserState = (props) => {
  // const navigate = useNavigate();
  const [dailyPriorityTags, setdailyPriorityTags] = useState([]);
  const [selectedWarehouseDailyLimit, setselectedWarehouseDailyLimit] =
    useState(null);
  const [calculatedWarehouseLimit, setcalculatedWarehouseLimit] = useState("");
  const [selectedwarehouseId, setselectedwarehouseId] = useState("");

  const getDailyPriorityTagDetails = async () => {
    setdailyPriorityTags([]);
    setselectedWarehouseDailyLimit(null);
    setcalculatedWarehouseLimit("");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/priority/tag_items`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          company_name: localStorage.getItem("company_name"),
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setdailyPriorityTags(response.data.priority_tags);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const contextValue = useMemo(
    () => ({
      dailyPriorityTags,
      setdailyPriorityTags,
      selectedWarehouseDailyLimit,
      setselectedWarehouseDailyLimit,
      calculatedWarehouseLimit,
      setcalculatedWarehouseLimit,
      getDailyPriorityTagDetails,
    }),
    [
      dailyPriorityTags,
      setdailyPriorityTags,
      selectedWarehouseDailyLimit,
      setselectedWarehouseDailyLimit,
      calculatedWarehouseLimit,
      setcalculatedWarehouseLimit,
      getDailyPriorityTagDetails,
    ]
  );
  return (
    <UserContext.Provider value={contextValue}>
      {props?.children}
    </UserContext.Provider>
  );
};
export default UserState;
