import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ErrorModal from "../components/ErrorModal";
import { SuccessModal } from "../components/SuccessModal";
import Select from "react-select";
import Loader from "../components/Loader";
import { conditionalExp, getMomentFormatted, getUngroupedOrders } from "../utils/commonFn";
import axios from 'axios';
function GroupOrdersModal({show,setShow,getGroups}) {
    const [selectedOrdersTogroup, setSelectedOrdersTogroup] = useState([])
    const [selectedTruckType, setSelectedTruckType] = useState()
    const [truckTypes, setTruckTypes] = useState([])
    const [selectedTruckLine, setSelectedTruckLine] = useState()
    const [truckLine, setTruckLine] = useState([])
    const [groupName, setGroupName] = useState()
    const [pickupAddress, setPickupAddress] = useState("")
    const [selectDate, setSelectDate] = useState()
    const [selectTime, setSelectTime] = useState()
    const [submitLoading, setSubmitLoading] = useState(false);
    const [ungropedOrders, setUngropedOrders] = useState([])
  // modal
  // Add state variable for success modal visibility
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  // Function to handle opening of the success modal
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  const [error, setError] = useState();
  const [showErrModal, setShowErrModal] = useState(false);
  const [sucessMsg, setSucessMsg] = useState("");

    const handleClose = () => {
        setShow(false)
        setSelectedOrdersTogroup([])
        setSelectedTruckType()
        setSelectedTruckLine()
        setGroupName()
        setPickupAddress("")
        setSelectDate()
        setSelectTime()
        setUngropedOrders([])
      };

    const navigate = useNavigate();
    const truckLineData = async () => {
        const token = localStorage.getItem("mtm_user_token");
        try {
          let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}mtmdistribution/get/carrier_list`,
            {
              headers: {
                "Content-Type": "application/json",
                jwt_token: token,
              },
            }
          );
          if (!response?.data?.error) {
            setTruckLine(response.data.carriers);
          } else {
            setTruckLine([]);
          }
        } catch (err) {
          if (err.response.status == 401) {
            localStorage.removeItem("mtm_user_token");
            localStorage.removeItem("mtm_user_data");
            navigate("/");
            return;
          }
          // console.log(err);
        }
      };
    
      const truckTypeData = async () => {
        const token = localStorage.getItem("mtm_user_token");
        try {
          let response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}mtmdistribution/get/truck_types`,
            // { truckTypes: truckTypes },
            {
              headers: {
                "Content-Type": "application/json",
                jwt_token: token,
              },
            }
          );
          if (!response?.data?.error) {
            setTruckTypes(response.data.truckTypes);
          } else {
            setTruckTypes([]);
          }
        } catch (err) {
          if (err.response.status == 401) {
            localStorage.removeItem("mtm_user_token");
            localStorage.removeItem("mtm_user_data");
            navigate("/");
            return;
          }
          // console.log(err);
        }
      };

      async function groupOrders() {
        const token = localStorage.getItem("mtm_user_token");
        const company_name = localStorage.getItem("company_name");
        setSubmitLoading(true);
    
        try {
          let response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}mtmdistribution/group_distribution_orders`,
            {
            customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id, 
            pickup_date: selectDate,
            pickup_time: selectTime,
            pickup_address: pickupAddress,
            truck_type: selectedTruckType?.value,
            career_id:  selectedTruckLine?.value,
            group_name: groupName,
            orders: selectedOrdersTogroup?.map(item=>({id:item?.value})),
            group_id:show?.group_id || null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                jwt_token: token,
              },
            }
          );
    
          if (!response?.data?.error) {
            setSubmitLoading(false);
            //
            handleClose();
            handleShowSuccessModal();
            setTimeout(() => {
              setSucessMsg("");
              setShowSuccessModal(false);
            }, 2000);
            getUngroupedOrders()
            getGroups && getGroups();
          } else {
            setSubmitLoading(false);
            setError({ title: "Error", err: response.data.message });
            setShowErrModal(true);
    
          }
        } catch (err) {
          if (err.response.status == 401) {
            localStorage.removeItem("mtm_user_token");
            localStorage.removeItem("mtm_user_data");
            navigate("/");
            return;
          }
          // console.log(err);
          setSubmitLoading(false);
        }
      }



  useEffect(() => {
    truckTypeData();
    truckLineData();
  }, []);

  useEffect(() => {
    if(typeof show == 'object'){
        // console.log(show);
    setSelectedOrdersTogroup(show?.orders?.map(ord=>({
      label:ord?.doc_number  + " | " + ord?.address1 + getMomentFormatted(ord?.appointment_date,'DD/MM/YYYY | hh:mm A'),
      value:ord?.id
    })) || [])
    let truck_type = truckTypes.find(item=>item.container_type == show?.truck_type)
    if(truck_type){
      setSelectedTruckType({label:truck_type?.clickup_product_name,value:truck_type?.container_type})
    }
    let truck_line = truckLine.find(item=>item.career_id == show?.career_id)
    if(truck_line){
      setSelectedTruckLine({label:truck_line?.career_name + " | " + truck_line?.career_scac,value:truck_line?.career_id})
    }
    setGroupName(show?.group_name || null)
    setSelectDate(show?.pickup_date || null)
    setSelectTime(show?.pickup_time || null)
    setPickupAddress(show?.pickup_address || "")
    if(show?.ungropedOrders?.length){
        setUngropedOrders(show?.ungropedOrders)
    }else{
        getUngroupedOrders(null,setUngropedOrders,navigate)
    }
    }
},[show])
  return (
    <>
    <Modal show={show} size="lg" centered onHide={handleClose}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="text-center w-100">
            {show?.modal_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className=" pb-3">
              <Select
                isMulti
                isSearchable
                isClearable
                options={ungropedOrders?.map(order=>({
                  label: order?.doc_number + " | " + order?.address1 + getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A'),
                  value: order?.id
                }))}
                value={selectedOrdersTogroup}
                onChange={(selected) => {
                  setSelectedOrdersTogroup(selected);
                }}
              />
            </div>
            <div className="pb-3">
                <h5>Pickup Date & Time</h5>
            </div>
            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 g-4">
                <div className="col">
                  <input type="date" className="form-control" id="date" name="date" value={selectDate} onChange={(e)=>setSelectDate(e.target.value)}/>
                </div>
                <div className="col">
                  <input type="time" className="form-control" id="time" name="time" value={selectTime} onChange={(e)=>setSelectTime(e.target.value)}/>
                </div>
                <div className="col">
                <textarea placeholder='Enter Pickup Address' className="form-control" value={pickupAddress} onChange={(e)=>setPickupAddress(e.target.value)}/>

                </div>
                <div className="col">
                  <Select
                    isSearchable
                    placeholder="Choose Truck Type"
                    options={truckTypes?.map(order=>({
                      label: order?.clickup_product_name,
                      value: order?.container_type
                    }))}
                    value={selectedTruckType}
                    onChange={(selected) => {
                      setSelectedTruckType(selected);
                    }}
                  />
                </div>
                <div className="col">
                  <Select
                      isSearchable
                      placeholder="Choose Truck Line"
                      options={truckLine?.map(order=>({
                        label: order?.career_name + " | " + order?.career_scac,
                        value: order?.career_id
                      }))}
                      value={selectedTruckLine}
                      onChange={(selected) => {
                        setSelectedTruckLine(selected);
                      }}
                    />
                </div>
                <div className="col">
                    <input type="text" className="form-control" id="InputGroupName" placeholder="Enter group name" value={groupName} onChange={(e)=>setGroupName(e.target.value)}/>
                </div>
            </div>
            <div className="d-flex justify-content-end mt-4">
              <button
                type="button"
                className="accor-btn px-5"
                onClick={groupOrders}
                disabled={!selectedOrdersTogroup.length|| !pickupAddress || !selectDate || !selectTime || !selectedTruckType || !selectedTruckLine}
              >
                {submitLoading ? <Loader /> : "Submit"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
     
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
      <SuccessModal
        handleCloseSuccess={() => setShowSuccessModal(false)}
        showSuccess={showSuccessModal}
        successMessagePopup={sucessMsg}
      />
     </> 
  )
}

export default GroupOrdersModal