import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import save from "./srcimages/save.png";
import Processing from "../components/Processing";
import Icondelete from "../components/Icondelete";
function Modify() {
  const params = useParams();
  console.log(params, "params");
  const navigate = useNavigate();
  const saveResponseRef = useRef();

  const { order_uniq_id, vendor_code, vendor_name } = params;
  const [show, setShow] = useState(false);
  const [loadingCompleted, setloadingComplted] = useState(false);
  const [error, seterror] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showVendor, setShowVendor] = useState(false);
  const [showVendorName, setshowVendorName] = useState(false);
  const [showVendorNew, setShowVendorNew] = useState(false);

  const handleCloseVendor = () => setShowVendor(false);
  const handleShowVendor = () => setShowVendor(true);
  const handleCloseVendorName = () => setshowVendorName(false);
  const handleShowVendorName = () => setshowVendorName(true);

  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);

  const [selectedId, setselectedId] = useState({});
  const [selectedIdNew, setselectedIdNew] = useState({});

  const [vendorOrderListTotal, setvendorOrderListTotal] = useState({});
  const [venderOrderListArray, setvenderOrderListArray] = useState([]);
  const [moreEnteredQty, setmoreEnteredQty] = useState(false);
  const [submitError, setsubmitError] = useState({
    status: false,
    message: "",
    from: "",
  });
  const [addNewVendor, setaddNewVendor] = useState({
    order_uniq_id: "",
    vendor_code: "",
    description: "",
    qty: "",
    upc: "",
    alcohol: "",
    box: "",
    price: "",
    moq: "",
    barcode: "",
  });
  const [submittingAddnewVendor, setsubmittingAddnewVendor] = useState(false);
  const [submitting, setsubmitting] = useState(false);

  const [errorMessage, seterrorMessage] = useState("");
  const postVenderOrderList = async (from, group_code) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/order/update`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setvendorOrderListTotal(response.data);
          if (from == "from_delete") {
            let array = [...venderOrderListArray];
            array = array.filter((item) => item.group_code != group_code);
            setvenderOrderListArray(array);
          } else if (from == "from_adding") {
            setvenderOrderListArray([...venderOrderListArray]);
          } else {
            setvenderOrderListArray(response.data.orders);
          }
          setloadingComplted(true);
        } else {
          setloadingComplted(true);
          seterror(true);
          seterrorMessage(response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const deleteVendor = async (selectedId) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/order/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          group_code: selectedId?.group_code,
          item_type: "old",
        },
      });
      if (response.status == 200) {
        postVenderOrderList("from_delete", selectedId?.group_code);
        handleCloseVendor();
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const deleteVendorNew = async (selectedId) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/order/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          group_code: selectedId?.group_code,
          item_type: "new",
          item_id: selectedId?.item_id,
        },
      });
      if (response.status == 200) {
        postVenderOrderList("from_delete", selectedId?.group_code);
        setShow(false);
        setShowVendor(false);
        setShowVendorNew(false);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  const getOrderData = (venderOrderListArray, type) => {
    let order_data = [];

    order_data = venderOrderListArray?.map((item) => {
      let data = {
        group_code: item?.group_code,
        vendor_qty: item?.vendor_qty == "" ? 0 : item?.vendor_qty,
        item_type: "old",
        description: item?.description,
        unit_cost: item?.unit_cost == "" ? 0 : item?.unit_cost,
        original_unit_cost: item?.original_unit_cost,
      };
      if (type != "save") {
        data["original_unit_cost"] = item?.original_unit_cost;
      }

      return data;
    });
    return order_data;
  };
  console.log(venderOrderListArray, "venderOrderListArray");
  const getSentData = (type, order_data) => {
    let data = {
      order_uniq_id: order_uniq_id,
      vendor_code: vendor_code,
      action_type: type,
      order_details: order_data,
    };
    if (type != "save") {
      data["vendor_name"] = vendorOrderListTotal?.vendor?.vendor_name;
    }
    return data;
  };
  const handleResponse = (response, type) => {
    if (!response.data.error) {
      postVenderOrderList("from_update");
      handleCloseVendorName();
      if (type == "save") {
        saveResponseRef.current.innerHTML = "Updated Successfully";
        setTimeout(() => {
          saveResponseRef.current.innerHTML = "";
        }, 3000);
      } else {
        setsubmitting(false);
        navigate("/saved", {
          replace: true,
          state: {
            message: "We have saved your response",
          },
        });
      }
    } else {
      setsubmitting(false);
      setsubmitError({
        status: true,
        message: response.data.message,
        from: type,
      });
    }
  };
  const UpdateVendor = async (type) => {
    setsubmitError({
      status: false,
      message: "",
      from: "",
    });
    try {
      let order_data = getOrderData(venderOrderListArray, type);
      console.log(order_data, "order data");
      if (type != "save") {
        let new_orders = vendorOrderListTotal?.new_order;
        for (const orderd of new_orders) {
          let obj = {
            description: orderd.description,
            vendor_qty: orderd.qty,
            upc: orderd.upc,
            alcohol: orderd.alcohol,
            bottles: orderd.box,
            price: orderd.price,
            moq: orderd.moq,
            barcode: orderd.barcode,
            item_type: "new",
            item_id: orderd.item_id,
          };
          order_data.push(obj);
        }
      }

      let data = getSentData(type, order_data);

      type == "submit" && setsubmitting(true);

      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/order/update/save`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      if (response.status == 200) {
        handleResponse(response, type);
      }
    } catch (error) {
      setsubmitting(false);
      console.log(error, "error");
    }
  };

  const addNewVendorFn = async () => {
    console.log(addNewVendor, "add new vendor");
    let data = {
      ...addNewVendor,
      qty: parseInt(addNewVendor.qty),
      alcohol: parseInt(addNewVendor.alcohol),
      box: parseInt(addNewVendor?.box),
      price: parseInt(addNewVendor?.price),
    };
    data.order_uniq_id = order_uniq_id;
    data.vendor_code = vendor_code;
    setsubmittingAddnewVendor(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/vendor/item/add`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      });
      setsubmittingAddnewVendor(false);
      if (response.status == 200) {
        postVenderOrderList("from_adding");
        setShowVendor({
          order_uniq_id: "",
          vendor_code: "",
          description: "",
          qty: "",
          upc: "",
          alcohol: "",
          box: "",
          price: "",
          moq: "",
          barcode: "",
        });
        setShow(false);
        setShowVendor(false);
        setShowVendorNew(false);
      }
    } catch (error) {
      setsubmittingAddnewVendor(false);
      console.log(error, "error");
    }
  };

  const handleVendorChange = (e, vendor, key) => {
    setvenderOrderListArray((prev) =>
      prev?.map((item) => {
        if (item?.order_id == vendor?.order_id) {
          if (e.target.value > item?.original_buy_qty) {
            setmoreEnteredQty(true);
          }
          return { ...item, [key]: e.target.value };
        } else {
          return item;
        }
      })
    );
  };

  const handleVendorDetails = (e) => {
    setaddNewVendor({ ...addNewVendor, [e.target.id]: e.target.value });
  };
  useEffect(() => {
    if ((order_uniq_id, vendor_code)) {
      postVenderOrderList("from_first");
    }
  }, []);
  console.log(vendorOrderListTotal, "total");
  console.log(selectedId, "selected id");

  return (
    <>
      {!loadingCompleted ? (
        <Processing />
      ) : (
        <>
          {!error ? (
            <Container>
              <Row>
                <Col md={12}>
                  <div className="wrapper-box">
                    <h2 className="text-center fw-700 fs-30">
                      Modify/Add Records For&nbsp;
                      {vendorOrderListTotal?.vendor?.vendor_name}
                    </h2>
                  </div>
                  <div className="d-flex pt-5">
                    <button
                      type="button"
                      className="px-4 py-2 rounded-pill grdline-btn fs-20 fw-600 d-flex text-center align-items-center ms-auto"
                      onClick={() => {
                        setaddNewVendor({
                          order_uniq_id: "",
                          vendor_code: "",
                          description: "",
                          qty: "",
                          upc: "",
                          alcohol: "",
                          box: "",
                          price: "",
                          moq: "",
                          barcode: "",
                        });
                        handleShow();
                      }}
                    >
                      Add New Record
                    </button>
                  </div>
                  <div className="modify-tbl">
                    <table className="table text-center border my-4">
                      <tbody>
                        <tr>
                          <th>Serial Number</th>
                          <th>SKU</th>
                          <th>Description</th>
                          <th>Quantity</th>
                          <th>Modify Quantity</th>
                          <th>Unit Cost</th>
                          <th>Modify Unit Cost</th>
                        </tr>
                        {venderOrderListArray
                          ?.filter((vendor) => vendor.order_status != 4)
                          ?.map((vendor, vendor_index) => (
                            <tr key={vendor?.group_code}>
                              <td>
                                <button
                                  type="button"
                                  className="tbl-delete me-3"
                                  onClick={() => {
                                    setselectedId(vendor);
                                    handleShowVendor();
                                  }}
                                >
                                  <svg className="icon fs-22">
                                    <use href="#icon_delete"></use>
                                  </svg>
                                </button>
                                {vendor_index + 1}
                              </td>
                              <td>{vendor?.group_code}</td>
                              <td>{vendor?.description}</td>
                              <td>{vendor?.original_buy_qty}</td>
                              <td width="200px">
                                <input
                                  type="number"
                                  className="text-center"
                                  placeholder=""
                                  value={vendor?.vendor_qty}
                                  onKeyPress={(e) => {
                                    if (e.key === "-" || e.key === ".") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setmoreEnteredQty(false);
                                    const inputText = e.target.value;
                                    if (
                                      inputText === "" ||
                                      parseFloat(inputText) >= 0
                                    ) {
                                      handleVendorChange(
                                        e,
                                        vendor,
                                        "vendor_qty"
                                      );
                                    }
                                  }}
                                ></input>
                              </td>
                              <td>{vendor?.original_unit_cost}</td>

                              <td width="200px">
                                <input
                                  type="number"
                                  className="text-center"
                                  placeholder=""
                                  value={vendor?.unit_cost}
                                  onKeyPress={(e) => {
                                    if (e.key === "-") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => {
                                    const inputText = e.target.value;
                                    if (
                                      inputText === "" ||
                                      parseFloat(inputText) >= 0
                                    ) {
                                      handleVendorChange(
                                        e,
                                        vendor,
                                        "unit_cost"
                                      );
                                    }
                                  }}
                                ></input>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {vendorOrderListTotal?.new_order?.length > 0 && (
                    <>
                      <h2 className="d-flex pt-1">Newly Added Records</h2>
                      <div className="modify-tbl">
                        <table className="table text-center border my-4">
                          <tbody>
                            <tr>
                              <th>Serial Number</th>
                              <th>Description</th>
                              <th>% Alcohol</th>
                              <th>Quantity</th>
                              <th>#Bottles per box</th>
                              <th>price</th>
                              <th>UPC</th>
                              <th>MOQ</th>
                              <th>Barcode</th>
                            </tr>
                            {vendorOrderListTotal?.new_order?.map(
                              (vendorm, vendorm_index) => (
                                <tr key={vendorm?.description}>
                                  <td>
                                    <button
                                      type="button"
                                      className="tbl-delete me-3"
                                      onClick={() => {
                                        setselectedIdNew(vendorm);
                                        handleShowVendorNew();
                                      }}
                                    >
                                      <svg className="icon fs-22">
                                        <use href="#icon_delete"></use>
                                      </svg>
                                    </button>
                                    {vendorm_index + 1}
                                  </td>
                                  <td>{vendorm?.description}</td>
                                  <td>{vendorm?.alcohol}</td>
                                  <td>{vendorm?.qty}</td>
                                  <td>{vendorm?.box}</td>
                                  <td>{vendorm?.price}</td>
                                  <td>{vendorm?.upc}</td>
                                  <td>{vendorm?.moq}</td>
                                  <td>{vendorm?.barcode}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  {submitError.status && submitError.from == "save" && (
                    <div className="d-flex justify-content-end mt-2 text-danger">
                      <div>{submitError.message}</div>
                    </div>
                  )}
                  {/*
                  {moreEnteredQty && (
                    <div className="d-flex justify-content-end mt-2 text-danger">
                      <div>You can't add more quantity than requested</div>
                    </div>
                  )}
  */}
                  <div className="d-flex justify-content-end">
                    <div className="mr-2" style={{ marginRight: "20px" }}>
                      <button
                        type="button"
                        className={`mt-2 py-2 px-4 mr-4 fs-20 grd-btn rounded-pill fw-600 d-flex text-center`}
                        onClick={() => UpdateVendor("save")}
                        // disabled={moreEnteredQty}
                      >
                        Save Response
                      </button>
                      <div
                        className="pb-1 fw-600 fs-20"
                        ref={saveResponseRef}
                      ></div>
                    </div>
                    <div className="ml-2">
                      <button
                        type="button"
                        className={`mt-2 py-2 px-4 mr-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center`}
                        onClick={() => handleShowVendorName()}
                        // disabled={moreEnteredQty}
                      >
                        Submit Response
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col md={12}>
                  <div className="wrapper-box">
                    <div className="d-flex justify-content-center">
                      <div style={{ width: "420px" }}>
                        <img className="w-100" src={save} />
                      </div>
                    </div>

                    <p className="fw-bold fs-3 text-center py-4">
                      {errorMessage}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}

      {/* ===Modle */}
      <Modal show={showVendor} onHide={handleCloseVendor} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Record ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendor}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteVendor(selectedId);
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* ===Modle */}
      <Modal show={showVendorName} onHide={handleCloseVendorName} centered>
        <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center m-0 p-0">
            Please reconfirm your submission
          </p>
          <div className="input-box mb-4 mt-2 p-0">
            <input
              type="text"
              className="w-100"
              placeholder="Enter Description"
              id="vender_code"
              value={`${vendor_name} (${vendor_code})`}
            />
          </div>
          {submitError.status && submitError.from == "submit" && (
            <div className="d-flex justify-content-end mt-2 text-danger">
              <div>{submitError.message}</div>
            </div>
          )}
          <div className="d-flex justify-content-end mt-2">
            <div>
              <button
                type="button"
                disabled={submitting}
                className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                onClick={() => {
                  UpdateVendor("submit");
                }}
              >
                {submitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Vendor ?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteVendorNew(selectedIdNew);
              }}
            >
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">Add New Record</h2>
          <div className="p-4">
            <div className="input-box mb-4">
              {addNewVendor?.description && addNewVendor?.description != "" && (
                <label htmlFor="description">Description</label>
              )}
              <input
                type="text"
                className="w-100"
                placeholder="Enter Description"
                id="description"
                value={addNewVendor?.description}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="input-box mb-4">
              {addNewVendor?.qty && addNewVendor?.qty != "" && (
                <label htmlFor="qty">Quantity</label>
              )}
              <input
                type="number"
                className="w-100"
                placeholder="Enter Quantity"
                id="qty"
                value={addNewVendor?.qty}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="input-box mb-4">
              {addNewVendor?.upc && addNewVendor?.upc != "" && (
                <label htmlFor="upc">UPC</label>
              )}
              <input
                type="text"
                className="w-100"
                placeholder="Enter UPC"
                id="upc"
                value={addNewVendor?.upc}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="input-box mb-4">
              {addNewVendor?.alcohol && addNewVendor?.alcohol != "" && (
                <label htmlFor="alcohol">% Alcohol</label>
              )}
              <input
                type="number"
                className="w-100"
                placeholder="Enter % Alcohol"
                id="alcohol"
                value={addNewVendor?.alcohol}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="input-box mb-4">
              {addNewVendor?.box && addNewVendor?.box != "" && (
                <label htmlFor="box">#Bottles per box</label>
              )}
              <input
                type="number"
                className="w-100"
                placeholder="Enter #Bottles per box"
                id="box"
                value={addNewVendor?.box}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="input-box mb-4">
              {addNewVendor?.price && addNewVendor?.price != "" && (
                <label htmlFor="price">Price</label>
              )}
              <input
                type="number"
                className="w-100"
                placeholder="Enter Price"
                id="price"
                value={addNewVendor?.price}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="input-box mb-4">
              {addNewVendor?.moq && addNewVendor?.moq != "" && (
                <label htmlFor="moq">MOQ</label>
              )}
              <input
                type="text"
                className="w-100"
                placeholder="Enter MOQ"
                id="moq"
                value={addNewVendor?.moq}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="input-box mb-4">
              {addNewVendor?.barcode && addNewVendor?.barcode != "" && (
                <label htmlFor="barcode">Barcode</label>
              )}
              <input
                type="text"
                className="w-100"
                placeholder="Enter Barcode"
                id="barcode"
                value={addNewVendor?.barcode}
                onChange={handleVendorDetails}
              />
            </div>
            <div className="d-flex justify-content-end">
              <div>
                <button
                  type="button"
                  disabled={submittingAddnewVendor}
                  className="mt-2 py-2 px-4 grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                  onClick={addNewVendorFn}
                >
                  {submittingAddnewVendor ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Modify;
