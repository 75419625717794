import React from "react";

function IconEditLabel() {
  return (
    <>
      <label htmlFor="fileUpload2" className="mr-10">
        <svg className="icon" style={{ width: "18px", height: "18px" }}>
          <use href="#icon_edit"></use>
        </svg>
      </label>
    </>
  );
}

export default IconEditLabel;
