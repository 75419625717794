import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import save from "./srcimages/save.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Processing from "../components/Processing";

function UpdatedOrderAccept() {
  const params = useParams();
  const { order_uniq_id, vendor_code } = params;
  const [loadingCompleted, setloadingComplted] = useState(false); //change to false later
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const navigate = useNavigate();
  console.log(error);
  const postVenderOrderList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/accept/finalsubmit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloadingComplted(true);
          seterrorMessage(response.data.message);
        } else {
          setloadingComplted(true);
          seterror(true);
          seterrorMessage(response.data.message);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const checkStatus = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/order/status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          page: "final",
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          postVenderOrderList();
        } else {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <>
      {!loadingCompleted ? (
        <Processing />
      ) : (
        <Container>
          <Row>
            <Col md={12}>
              <div className="wrapper-box">
                <div className="d-flex justify-content-center">
                  <div style={{ width: "420px" }}>
                    <img className="w-100" src={save} />
                  </div>
                </div>

                <p className="fw-bold fs-3 text-center py-4">{errorMessage}</p>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default UpdatedOrderAccept;
