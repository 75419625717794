import axios from "axios";
import React, { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import IconEditLabel from "../components/IconEditLabel";
import IconFileClose from "../components/IconFileClose";

function Uploaddocument() {
  const navigate = useNavigate();
  const params = useParams();
  const { document_uniq_id } = params;
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isDeclarationSending, setisDeclarationSending] = useState(false);
  const [declaration, setdeclaration] = useState("");
  const [declarationMessage, setdeclarationMessage] = useState(null);
  const [declarationMessageError, setdeclarationMessageError] = useState(null);
  const [documentSuccessMsg, setdocumentSuccessMsg] = useState("");
  const [documentErrorMsg, setdocumentErrorMsg] = useState(null);
  const [showVendorNew, setShowVendorNew] = useState(false);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [customDocuments, setcustomDocuments] = useState([]);
  const handleCloseVendorNew = () => setShowVendorNew(false);
  const handleShowVendorNew = () => setShowVendorNew(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const [selectedDocId, setselectedDocId] = useState("");
  const [deleting, setdeleting] = useState(false);

  const [rawFileUploadDoc, setrawFileUploadDoc] = useState(null);
  const [previewUrlUploadDoc, setpreviewUrlUploadDoc] = useState(null);
  const MAX_FILE_SIZEUploadDoc = 6 * 1024 * 1024; // 6MB in bytes
  const [fileUploadLimitRxErrorUploadDoc, setfileUploadLimitRxErrorUploadDoc] =
    useState(false);
  const [documentNameUploadDoc, setdocumentNameUploadDoc] = useState("");
  const handleChangeFileFunUploadDocument = (e) => {
    let file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZEUploadDoc) {
      setfileUploadLimitRxErrorUploadDoc(true);
      return;
    }
    setfileUploadLimitRxErrorUploadDoc(false);
    setrawFileUploadDoc(file);
    e.target.value = "";
  };
  // File upload
  async function handleFileUpload() {
    setdocumentErrorMsg(null);
    if (rawFileUploadDoc) {
      setIsFileUploading(true);
      try {
        const uploadFormData = new FormData();
        uploadFormData.append("booking_id", document_uniq_id);
        uploadFormData.append("document", rawFileUploadDoc);
        uploadFormData.append(
          "document_name",
          documentNameUploadDoc?.length == 0
            ? rawFileUploadDoc?.name
            : documentNameUploadDoc
        );

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}mtmcustom/document/upload`,
          uploadFormData
        );

        if (response.status == 200) {
          setIsFileUploading(false);
          if (!response.data.error) {
            getDocumentsFn();
            handleShowSuccess();
            setTimeout(() => {
              handleCloseSuccess();
            }, 2000);
            setdocumentNameUploadDoc("");
            handleClose();
            setrawFileUploadDoc(null);
            setpreviewUrlUploadDoc(null);
            setfileUploadLimitRxErrorUploadDoc(false);
            setdocumentSuccessMsg(response.data.message);
          } else {
            setdocumentErrorMsg(response.data.message);
          }
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("mtm_user_token");
          localStorage.removeItem("mtm_user_data");
          navigate("/");
          return;
        }
        console.log(error);
        alert("error uploading document: " + error.message);
        setIsFileUploading(false);
      }
    } else {
      alert("Please select a file");
    }
  }
  const getDocumentsFn = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmcustom/document/all`,
        method: "GET",
        headers: {
          booking_id: document_uniq_id,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setcustomDocuments(response.data.customDocuments);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  const deleteLableFn = async () => {
    try {
      setdeleting(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmcustom/document/delete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          booking_id: document_uniq_id,
          doc_id: selectedDocId,
        },
      });

      if (response.status == 200) {
        setdeleting(false);
        if (response.data.error) {
          alert("error");
        } else {
          handleCloseVendorNew();
          getDocumentsFn();
        }
      }
    } catch (error) {
      setdeleting(false);
      console.log(error);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  console.log(customDocuments, "custom doucments");
  const customDeclarationFn = async () => {
    if (declaration.length == 0) {
      setdeclarationMessageError("Please enter Custom Decleration Number");
      return;
    }
    setisDeclarationSending(true);
    setdeclarationMessage(null);
    setdeclarationMessageError(null);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmcustom/declration`,
        method: "POST",
        data: {
          booking_id: document_uniq_id,
          custom_declration_number: declaration,
        },
      });
      if (response.status == 200) {
        setisDeclarationSending(false);
        setdeclaration("");
        if (!response.data.error) {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
          setdeclarationMessage(response.data.message);
        } else {
          setdeclarationMessageError(response.data.message);
        }
        setTimeout(() => {
          setdeclarationMessage(null);
          setdeclarationMessageError(null);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
      setdeclarationMessageError(error.message);
      setTimeout(() => {
        setdeclarationMessage(null);
        setdeclarationMessageError(null);
      }, 2000);
      setisDeclarationSending(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
    }
  };
  const getTypeDoc = (path) => {
    if (!path) {
      return "";
    }
    let type = path?.split(".");
    if (type[type.length - 1] == "pdf") {
      return (
        <img
          src={window.location.origin + "/images/pdf.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/excel.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/doc.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }

    return (
      <img
        src={path}
        style={{
          width: "40px",
          height: "30px",
          margin: "5px",
          marginRight: "10px",
        }}
        alt="doc"
      />
    );
  };
  const checkRxType = () => {
    if (!rawFileUploadDoc) {
      return (
        <img
          src={previewUrlUploadDoc}
          style={{
            width: "40px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }
    let type = rawFileUploadDoc?.name?.split(".");
    if (type[type.length - 1] == "pdf") {
      return (
        <img
          src={window.location.origin + "/images/pdf.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "xls" ||
      type[type.length - 1] == "xlsx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/excel.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    } else if (
      type[type.length - 1] == "doc" ||
      type[type.length - 1] == "docx"
    ) {
      return (
        <img
          src={window.location.origin + "/images/doc.png"}
          style={{
            width: "30px",
            height: "30px",
            margin: "5px",
            marginRight: "10px",
          }}
          alt="doc"
        />
      );
    }

    return (
      <img
        src={previewUrlUploadDoc}
        style={{
          width: "40px",
          height: "30px",
          margin: "5px",
          marginRight: "10px",
        }}
        alt="doc"
      />
    );
  };
  useEffect(() => {
    if (!rawFileUploadDoc) {
      setpreviewUrlUploadDoc(null);
      return;
    }
    const objectURL = URL.createObjectURL(rawFileUploadDoc);
    setpreviewUrlUploadDoc(objectURL);
  }, [rawFileUploadDoc]);
  useEffect(() => {
    getDocumentsFn();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <h2 className="text-center fw-700 fs-30">Upload Documents</h2>
              <div className="input-box mb-4 pt-5 col-7 d-flex align-items-center">
                <input
                  type="text"
                  className="w-100"
                  value={declaration}
                  onChange={(e) => {
                    setdeclaration(e.target.value);
                    setdeclarationMessageError(null);
                  }}
                  placeholder="Enter Custom Decleration Number"
                />
              </div>

              <div className="py-3 w-50">
                <div className="d-flex align-items-center ">
                  <p className="fw-700 fs-20">Other Clearance Document </p>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="grd-btn fs-20 rounded-pill px-4 py-1"
                      onClick={handleShow}
                    >
                      + Upload Document
                    </button>
                  </div>
                </div>
                {customDocuments?.map((item, index) => (
                  <>
                    <div
                      className="bg-gray border py-1 px-3 rounded-3 mt-4 d-flex align-items-center"
                      key={item?.document_path}
                    >
                      <div className="d-flex align-items-center">
                        {getTypeDoc(item?.document_path)}

                        <div className="ms-3">
                          <p className="fw-400 fs-16 py-2">
                            {item.document_name}
                          </p>
                        </div>
                      </div>
                      <div
                        className="ms-auto"
                        onClick={() => {
                          setselectedDocId(item?.id);
                          handleShowVendorNew();
                        }}
                      >
                        <button type="button" className="tbl-delete">
                          <svg className="icon fs-22">
                            <use href="#icon_delete"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="d-flex justigy-content-end ms-auto">
                {declarationMessage && (
                  <div className="input-box mb-4 pt-2 fw-600 text-danger  ms-auto">
                    {declarationMessage}
                  </div>
                )}
              </div>
              <div className="d-flex justigy-content-end ms-auto">
                {declarationMessageError && (
                  <div className="input-box text-danger mb-4 pt-2 fw-600  ms-auto">
                    {declarationMessageError}
                  </div>
                )}
              </div>

              <div className="d-flex">
                <div className="ms-auto">
                  <button
                    type="button"
                    disabled={isDeclarationSending}
                    onClick={customDeclarationFn}
                    className="grd-btn fs-20 rounded-pill px-4 py-1 ms-4"
                  >
                    {isDeclarationSending ? <Loader /> : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* ===Modle */}
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5">
          <h4 className="text-center pb-3 fs-22">Upload Document</h4>
          <p className="fw-600 fs-18">Document Name</p>
          <div className="input-box pb-4">
            <input
              type="text"
              className="w-100"
              placeholder=""
              value={documentNameUploadDoc}
              onChange={(e) => setdocumentNameUploadDoc(e.target.value)}
            />
          </div>
          <div className="upload-box p-3">
            <label className="drag-box input-file">
              <img
                className="img-fluid rounded-start"
                width={80}
                height={55}
                src="/images/uploader.png"
                alt="..."
              />
              <input
                type="file"
                accept="image/png,image/jpg,image/jpeg,.doc, .docx,.pdf,.csv,"
                id="fileUpload2"
                onChange={handleChangeFileFunUploadDocument}
              />
              <span>
                Drag and drop your image or <a href="">browse file</a> on your
                computer
              </span>
            </label>
          </div>
          <div className="d-block rounded-2 my-4">
            {previewUrlUploadDoc ? (
              <div
                className="file-import d-flex ai-center mt-10"
                id="remove_${inputid}"
              >
                {checkRxType()}
                <span style={{ marginRight: "auto" }}>
                  {rawFileUploadDoc?.name}
                </span>
                <IconEditLabel />
                <button
                  onClick={() => {
                    setpreviewUrlUploadDoc(null);
                    setrawFileUploadDoc(null);
                  }}
                >
                  <IconFileClose />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {fileUploadLimitRxErrorUploadDoc && (
            <div className="mb-2 w-100 text-danger text-center">
              Please upload file less than 6MB
            </div>
          )}
          {documentErrorMsg && (
            <div className="mb-2 w-100 text-danger text-center">
              {documentErrorMsg}
            </div>
          )}

          <div className="mt-2">
            <button
              className="button button-blue upload-btn w-100 py-2 fs-20"
              disabled={fileUploadLimitRxErrorUploadDoc || isFileUploading}
              onClick={handleFileUpload}
            >
              {isFileUploading ? <Loader /> : "Upload"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSuccess}
        centered
        onHide={handleCloseSuccess}
        className="tracking-modal"
      >
        <Modal.Body className="p-4 text-center">
          <div className="d-flex justify-content-center  text-success pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              fill="currentColor"
              className="bi bi-check-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
            </svg>
          </div>
          <h3>Success</h3>
          <p>{documentSuccessMsg}</p>
        </Modal.Body>
      </Modal>
      <Modal show={showVendorNew} onHide={handleCloseVendorNew} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 text-center">
            Are you sure you want to delete this Label?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <Button
              variant="secondary"
              className="me-3"
              onClick={handleCloseVendorNew}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteLableFn();
              }}
            >
              {deleting ? <Loader /> : "Delete"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Uploaddocument;
