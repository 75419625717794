import React, { useContext, useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import UploadComponent from "../components/UploadComponent";
import BackButton from "../components/BackButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DatePicker, Switch } from "antd";
import Loader from "../components/Loader";
import UserContext from "../context/UserContext";
const { RangePicker } = DatePicker;

function ExtractionFile() {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const {
    dailyPriorityTags,
    setdailyPriorityTags,
    selectedWarehouseDailyLimit,
    setselectedWarehouseDailyLimit,
    calculatedWarehouseLimit,
    setcalculatedWarehouseLimit,
    getDailyPriorityTagDetails,
  } = context;
  const [selectedDate, setselectedDate] = useState([]);
  const [selectedwarehouseId, setselectedwarehouseId] = useState("");
  const [wareHouses, setwareHouses] = useState([]);
  const [loginData, setloginData] = useState({});
  const [prioritySku, setprioritySku] = useState([]);
  const [dataNotFound, setdataNotFound] = useState(false);
  const [loading, setloading] = useState(false);
  const [savingPrioritySku, setsavingPrioritySku] = useState(false);
  const [savingPrioritySkuError, setsavingPrioritySkuError] = useState(null);
  const [accordianDates, setaccordianDates] = useState({});
  const [selectedAccordianItem, setselectedAccordianItem] = useState("");
  const [loadingDailyPendingTags, setloadingDailyPendingTags] = useState(false);
  const [loadingPendingTags, setloadingPendingTags] = useState(false);

  const today = new Date().toISOString().split("T")[0];
  const getFormattedDate = (item) => {
    const currentDate = item["$d"];
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const postDetails = async () => {
    setsavingPrioritySkuError(null);
    setsavingPrioritySku(true);

    let arraydata = prioritySku
      .filter(
        (item) =>
          item.new_tags_priority &&
          item.new_tags_priority != "" &&
          item.new_priority_tags_qty &&
          item.new_priority_tags_qty != ""
      )
      .map((item) => {
        return {
          item_code: item.item_code,
          custom_declration_number: item?.pedimento_a4,
          extraction_date: item?.priority_date,
          priority_tags_qty:
            parseInt(item?.box_factor) * parseInt(item?.new_priority_tags_qty),
          priority_tags_box: item?.new_priority_tags_qty,
          priority_tags_factor: item?.box_factor,
          old_tags_priority: item?.tags_priority,
          tags_priority: item?.new_tags_priority,
          tracking_id: item?.tracking_id,
          priority_date: today,
          extraction_qty: item?.extraction_qty,
          item_description: item?.item_description,
        };
      });
    let data = {
      customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
        ?.customer_id,
      warehouse_id: selectedwarehouseId,
      company_name: localStorage.getItem("company_name"),
      user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
      items: arraydata,
    };
    console.log(data, "data");
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/tags/change_priority`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: data,
      });
      if (response.status == 200) {
        setsavingPrioritySku(false);
        if (!response.data.error) {
          setprioritySku([]);
          getDetails();
          getDailyPriorityTagDetails();
        } else {
          setsavingPrioritySkuError(response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      setsavingPrioritySku(false);
      setsavingPrioritySkuError(error.response.data.message);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };

  const getDetails = async () => {
    try {
      setloading(true);
      setdataNotFound(false);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/priority/tags`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        // data: {
        //   warehouse_id: selectedwarehouseId,
        //   company_name: localStorage.getItem("company_name"),
        //   customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
        //     ?.customer_id,
        //   user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        //   from_date:
        //     selectedDate?.length > 0 ? getFormattedDate(selectedDate[0]) : "",
        //   to_date:
        //     selectedDate?.length > 0 ? getFormattedDate(selectedDate[1]) : "",
        // },
        data: {
          warehouse_id: selectedwarehouseId,
          company_name: localStorage.getItem("company_name"),
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
          from_date: selectedAccordianItem,
          to_date: selectedAccordianItem,
        },
      });
      setloading(false);
      if (response.status == 200) {
        if (!response.data.error) {
          // console.log(response.data, "res data");
          if (response.data.prioritysku.length == 0) {
            setdataNotFound(true);
          } else {
            setdataNotFound(false);
          }
          setprioritySku(response.data.prioritysku);
        } else {
          setdataNotFound(true);
          setprioritySku([]);
        }
      }
    } catch (error) {
      console.log(error);
      setdataNotFound(true);
      setprioritySku([]);
      setloading(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const getTotalQty = (details) => {
    let sum = 0;
    for (let i = 0; i < details.length; i++) {
      const element = details[i];
      sum += parseInt(element?.pending_priority_tags_qty);
    }
    return sum;
  };
  const getDailyTags = async () => {
    setloadingDailyPendingTags(true);
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/priority/daily_pending_tags`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          warehouse_id: selectedwarehouseId,
          company_name: localStorage.getItem("company_name"),
        },
      });

      if (response.status == 200) {
        setloadingDailyPendingTags(false);
        if (!response.data.error) {
          console.log(response.data, "response data");
          setaccordianDates(response.data.daily_priority_tags);
        }
      }
    } catch (error) {
      console.log(error);
      setloadingDailyPendingTags(false);
      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  const checkSaveButtonDisplay = () => {
    for (let i = 0; i < prioritySku.length; i++) {
      const element = prioritySku[i];
      if (
        element?.new_tags_priority &&
        element?.new_tags_priority != "" &&
        element?.new_priority_tags_qty &&
        element?.new_priority_tags_qty != ""
      ) {
        if (
          parseInt(element?.new_priority_tags_qty) +
            element?.priority_tags_qty <=
          parseInt(element?.extraction_qty)
        ) {
          return false;
        }
      }
    }
    return true;
  };
  const checkTagLimitExceeded = () => {
    if (
      calculateNewPriorityTagsQty() >
      selectedWarehouseDailyLimit?.daily_tags_limit
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getWareHouses = () => {
    let wareHouses = JSON.parse(
      localStorage.getItem("mtm_user_data")
    )?.warehouses;
    wareHouses = wareHouses?.filter(
      (item) => item?.company_name == localStorage.getItem("company_name")
    );
    setwareHouses(wareHouses);
  };
  console.log(prioritySku, "priority sku");
  const calculateNewPriorityTagsQty = () => {
    let total = 0;
    for (let i = 0; i < prioritySku.length; i++) {
      const element = prioritySku[i];
      if (
        element?.new_priority_tags_qty &&
        element?.new_priority_tags_qty != ""
      ) {
        total += parseInt(element?.box_factor) *parseInt(element?.new_priority_tags_qty);
      } else {
        total += 0;
      }
    }

    // setcalculatedWarehouseLimit(calculatedWarehouseLimit + total);
    return calculatedWarehouseLimit + total;
  };
  // console.log(dailyPriorityTags, "daily priority tags");

  const dateFormat = "DD-MM-YYYY";
  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      setloginData(JSON.parse(localStorage.getItem("mtm_user_data")));
      getWareHouses();
      getDailyPriorityTagDetails();
    }
  }, []);
  console.log(wareHouses, "warehouses/...");
  useEffect(() => {
    if (selectedwarehouseId == "") {
      setselectedWarehouseDailyLimit(null);
      return;
    }
    let warehouse = wareHouses?.find(
      (item) => item?.warehouse_id == selectedwarehouseId
    );
    // console.log(warehouse, "warehouse");
    let name = warehouse?.warehouse_name;
    let filteredDailyLimit = dailyPriorityTags?.find(
      (item) => item?.warehouse_name == name
    );
    setselectedWarehouseDailyLimit(filteredDailyLimit);
    setcalculatedWarehouseLimit(
      parseInt(filteredDailyLimit?.total_priority_tags_qty) || 0
    );
  }, [selectedwarehouseId, dailyPriorityTags?.length]);
  useEffect(() => {
    setaccordianDates([]);
    getDailyTags();
  }, [selectedwarehouseId]);

  // useEffect(() => {
  //   if (selectedDate.length == 0 || selectedwarehouseId == "") {
  //     setprioritySku([]);
  //     return;
  //   }
  //   setprioritySku([]);
  //   getDetails();
  // }, [selectedDate, selectedwarehouseId]);

  useEffect(() => {
    if (selectedAccordianItem == "" || selectedwarehouseId == "") {
      setprioritySku([]);
      return;
    }
    setprioritySku([]);
    getDetails();
  }, [selectedAccordianItem, selectedwarehouseId]);
  console.log(prioritySku, "priority sku");
  console.log(selectedWarehouseDailyLimit, "limit");
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-between align-items-center">
                <BackButton navi="actions" />
                <div>
                  <h2 className="text-center fw-700 fs-30">
                    <div>{localStorage.getItem("company_name")}</div>
                    Upload Extraction File
                  </h2>
                </div>
                <div></div>
              </div>
            </div>
          </Col>
          <UploadComponent
            api="mtmwarehose/extraction/upload"
            href={"extraction_file.xlsx"}
          />
        </Row>
        {dailyPriorityTags?.map((item) => (
          <Row className="fs-20">
            <Col md={2}>{item?.warehouse_name}</Col>
            <Col md={4}>
              {item?.total_priority_tags_qty}/{item?.daily_tags_limit}
            </Col>
          </Row>
        ))}
        <div className="pt-0">
          <form className="d-flex py-4">
            <div className="d-flex me-auto align-items-center">
              {/* <div className="me-4">
                <RangePicker
                  defaultValue={[selectedDate[0], selectedDate[1]]}
                  onChange={(value) => {
                    if (value) {
                      setselectedDate(value.map((item) => item));
                    } else {
                      setselectedDate([]);
                    }
                  }}
                  style={{ height: "38px" }}
                  format={dateFormat}
                />
              </div> */}
              <div className="me-4">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{ width: "200px" }}
                  value={selectedwarehouseId}
                  onChange={(e) => {
                    setselectedwarehouseId(e.target.value);
                    setselectedAccordianItem("");
                  }}
                >
                  <option value="">Choose Warehouse</option>
                  {wareHouses?.map((item, index) => (
                    <option value={item?.warehouse_id} key={index}>
                      {item?.warehouse_name}
                    </option>
                  ))}
                </select>
              </div>
              {selectedWarehouseDailyLimit && (
                <div className="me-4 fs-20">
                  Daily Tags Limit :{" "}
                  {selectedWarehouseDailyLimit?.daily_tags_limit}
                </div>
              )}
              {selectedWarehouseDailyLimit && (
                <div className="me-4 fs-20">
                  Used Tags : {calculateNewPriorityTagsQty()}
                </div>
              )}
              {checkTagLimitExceeded() && (
                <div className="me-4 fs-20 text-danger">
                  Daily Tag Limit Exceeded.
                </div>
              )}
            </div>
          </form>
        </div>
        {loadingDailyPendingTags && (
          <div className="w-100 mt-5 text-center">
            <Loader />
          </div>
        )}
        {selectedwarehouseId == "" && (
          <div className="w-100 mt-5 text-center fw-500 fs-30">
            Please Choose Warehouse
          </div>
        )}
        {Object.keys(accordianDates || {})?.length > 0 && (
          <Accordion>
            {Object.entries(accordianDates || {})?.filter(([date, details])=>getTotalQty(details)>0)?.map(
              ([date, details], index) => (
                <Accordion.Item
                  eventKey={date}
                  key={index}
                  onClick={() => setselectedAccordianItem(date)}
                >
                  <Accordion.Header>
                    <div className="d-flex gap-5">
                      <div>{date}</div>
                      {details?.map((item) => (
                        <>
                          <div>
                            <p>Tag Priority : {item?.tags_priority}</p>
                            Pending Quantity : {item?.pending_priority_tags_qty}
                          </div>
                        </>
                      ))}

                      <div>Total Pending Quantity : {getTotalQty(details)}</div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {loading && (
                      <div className="w-100 mt-5 text-center">
                        <Loader />
                      </div>
                    )}
                    {prioritySku?.length == 0 && !loading && dataNotFound && (
                      <div className="w-100 mt-5 text-center fw-600 fs-30">
                        No Items found
                      </div>
                    )}

                    {prioritySku?.length > 0 && (
                      <Row>
                        <Col md={12}>
                          <div className="modify-tbl">
                            <table className="table text-center border mb-4">
                              <tbody>
                                <tr>
                                  <th>SKU</th>
                                  <th>Description</th>
                                  <th>Custom Declaration Number</th>
                                  <th>Tag Priority</th>
                                  <th>Original Extraction Quantity</th>
                                  <th>Priority Date</th>
                                  <th>New Priority</th>
                                  <th>New Priority Date</th>
                                  <th>Done Extraction Quantity </th>
                                  <th>Priority Quantity (In Box)</th>
                                  <th>priority quantity (In Bottle)</th>
                                  <th>Pending Quantity</th>
                                </tr>
                                {prioritySku.map((item, index) => (
                                  <tr>
                                    <td>{item?.item_code}</td>
                                    <td>{item?.item_description}</td>
                                    <td>{item?.pedimento_a4}</td>
                                    <td>{item?.tags_priority}</td>
                                    <td>{item?.extraction_qty}</td>
                                    <td>{item?.priority_date}</td>
                                    <td>
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        style={{ width: "160px" }}
                                        value={item?.new_tags_priority}
                                        onChange={(e) => {
                                          setprioritySku((prev) =>
                                            prev.map((detail) => {
                                              if (detail.id == item.id) {
                                                return {
                                                  ...detail,
                                                  new_tags_priority:
                                                    e.target.value,
                                                };
                                              } else {
                                                return detail;
                                              }
                                            })
                                          );
                                        }}
                                      >
                                        <option value="">
                                          Choose New Priority
                                        </option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                      </select>
                                    </td>

                                    <td>
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder=""
                                        value={today}
                                        disabled
                                      ></input>
                                    </td>
                                    <td>{item?.priority_tags_qty}</td>

                                    <td>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        value={item?.new_priority_tags_qty}
                                        onChange={(e) => {
                                          setprioritySku((prev) =>
                                            prev.map((detail) => {
                                              if (detail.id == item.id) {
                                                return {
                                                  ...detail,
                                                  new_priority_tags_qty:
                                                    e.target.value,
                                                };
                                              } else {
                                                return detail;
                                              }
                                            })
                                          );
                                        }}
                                      ></input>
                                      <p className="text-center text-danger position-relative">
                                        {parseInt(item?.box_factor) *
                                          parseInt(
                                            item?.new_priority_tags_qty || 0
                                          ) >
                                          parseInt(item?.extraction_qty) &&
                                          "you cannot enter more quantity than pending quantity"}
                                      </p>
                                    </td>
                                    <td>
                                      {parseInt(item?.box_factor) *
                                        parseInt(
                                          item?.new_priority_tags_qty || 0
                                        )}
                                    </td>
                                    <td>
                                      {parseInt(item?.extraction_qty) -
                                        item?.priority_tags_qty}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                        <Col
                          md={12}
                          className="d-flex pt-5 pb-2"
                          style={{
                            background: "white",
                            position: "sticky",
                            bottom: 0,
                          }}
                        >
                          <div className="d-flex">
                            {selectedWarehouseDailyLimit && (
                              <div className="me-4 fs-20">
                                Daily Tags Limit :{" "}
                                {selectedWarehouseDailyLimit?.daily_tags_limit}
                              </div>
                            )}
                            {selectedWarehouseDailyLimit && (
                              <div className="me-4 fs-20">
                                Used Tags : {calculateNewPriorityTagsQty()}
                              </div>
                            )}
                            {checkTagLimitExceeded() && (
                              <div className="me-4 fs-20 text-danger">
                                Daily Tag Limit Exceeded.
                              </div>
                            )}
                          </div>
                          <div className="ms-auto">
                            {savingPrioritySkuError && (
                              <div className="ms-auto text-danger pb-2">
                                {savingPrioritySkuError}
                              </div>
                            )}

                            <button
                              type="button"
                              className={`submit-btn rounded-pill`}
                              disabled={
                                savingPrioritySku ||
                                checkSaveButtonDisplay() ||
                                checkTagLimitExceeded()
                              }
                              onClick={postDetails}
                            >
                              {savingPrioritySku ? <Loader /> : "Save"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              )
            )}
          </Accordion>
        )}
      </Container>
    </>
  );
}

export default ExtractionFile;
