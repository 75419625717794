import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton({ navi }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        className=""
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/${navi}`)}
      >
        <button type="button" className="back-btn">
          <svg className="icon me-2 fs-22">
            <use href="#icon_leftarrow"></use>
          </svg>
        </button>
        <strong className="fs-14 ">Back</strong>
      </div>
    </>
  );
}

export default BackButton;
