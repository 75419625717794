import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function CompanyList() {
  const navigate = useNavigate();
  const [companyList, setcompanyList] = useState([]);
  const [loginData, setloginData] = useState({});
  const [gettingDetails, setgettingDetails] = useState(false);

  const getDetails = async () => {
    try {
      setgettingDetails(false);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}mtmwarehose/get/company_list`,
        method: "POST",
        headers: {
          jwt_token: localStorage.getItem("mtm_user_token"),
        },
        data: {
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
          user_id: JSON.parse(localStorage.getItem("mtm_user_data"))?.id,
        },
      });
      if (response.status == 200) {
        setgettingDetails(true);
        if (!response.data.error) {
          setcompanyList(response.data.companies);
        }
      }
    } catch (error) {
      console.log(error);
      setgettingDetails(true);

      if (error.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/login");
        return;
      }
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("mtm_user_token");
    if (!token) {
      navigate("/login");
    } else {
      setloginData(JSON.parse(localStorage.getItem("mtm_user_data")));
      getDetails();
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <button
                type="button"
                className="p-0 m-0 border-0 bg-white btn-back d-flex align-items-center fs-20"
                onClick={() => navigate("/choose_activity")}
              >
                <svg className="icon fs-24 me-2">
                  <use href="#icon_backarrow"></use>
                </svg>
                Back
              </button>
              <h2 className="text-center fw-700 fs-30">Company List</h2>
            </div>
          </Col>
        </Row>
        {!gettingDetails && (
          <div className="w-100 mt-5 text-center">
            <Loader />
          </div>
        )}
        <div className="row rows-cols-1 row-cols-md-3 g-4 py-4">
          {companyList?.map((item, index) => (
            <div className="col">
              <button
                type="button"
                className="action-btn w-100"
                onClick={() => {
                  navigate(`/actions`);
                  localStorage.setItem("company_name", item?.company_name);
                }}
              >
                {item?.company_name}
              </button>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}

export default CompanyList;
