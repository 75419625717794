import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import save from "./srcimages/save.png";
import Processing from "../components/Processing";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

function DomesticAccept() {
  const [venderOrderListArray, setvenderOrderListArray] = useState([]);

  const [deleteData, setdeleteData] = useState({
    order_id: "",
    path: "",
  });
  const [estimatedateAddedError, setestimatedateAddedError] = useState(false);
  const [showVendorNewda, setShowVendorNewda] = useState(false);
  const handleCloseVendorNewda = () => setShowVendorNewda(false);
  const handleShowVendorNewda = () => setShowVendorNewda(true);
  const [loadingCompletedda, setloadingCompltedda] = useState(false);
  const [uploadedItemda, setuploadedItemda] = useState({});
  const [error, seterror] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [vendorListTotal, setvendorListTotal] = useState({});
  const navigate = useNavigate();
  const [showVendorNameda, setshowVendorNameda] = useState(false);
  const handleCloseVendorNameda = () => setshowVendorNameda(false);
  const handleShowVendorNameda = () => setshowVendorNameda(true);
  const [isSubmittingda, setisSubmittingda] = useState(false);
  const [estimatedTimeSuccessda, setestimatedTimeSuccessda] = useState(false);
  console.log(seterror, seterrorMessage, vendorListTotal);
  const [submitError, setsubmitError] = useState({
    status: false,
    message: "",
  });
  const [valueError, setvalueError] = useState({
    status: false,
    data: "",
  });
  const Tomorrow = dayjs().add(1, "day"); // Get tomorrow's date
  const [showSuccess, setShowSuccess] = useState(false);
  const [savingTime, setsavingTime] = useState(false);
  const [showCalender, setShowCalender] = useState(false);
  const [value, setValue] = React.useState(null);
  const [afterUploadMessage, setafterUploadMessage] = useState("");
  const handleCloseCalender = () => setShowCalender(false);
  const handleShowCalender = () => setShowCalender(true);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);
  const params = useParams();
  const { order_uniq_id, vendor_code, vendor_name } = params;

  const shouldDisableDate = (date) => {
    return date.isBefore(Tomorrow, "day"); // Disable dates before tomorrow
  };
  const postSubmitButton = async () => {
    setisSubmittingda(true);
    setsubmitError({
      status: false,
      message: "",
    });
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/domestic/po/accept`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });

      if (response.status == 200) {
        if (!response.data.error) {
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        } else {
          setsubmitError({
            status: true,
            message: response.data.message,
          });
          setisSubmittingda(false);
        }
      }
    } catch (error) {
      setisSubmittingda(false);
      console.log(error);
    }
  };
  const addEstimatedDateApi = async () => {
    if (!value) {
      setvalueError({
        status: true,
        data: "Please select Date",
      });
      return;
    } else {
      setvalueError({
        status: false,
        data: "",
      });
    }
    try {
      setsavingTime(true);
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/domestic/po/accept/upload/date`,
        method: "POST",
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          pickup_date: dayjs(value).format("YYYY-MM-DD"),
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setestimatedTimeSuccessda(true);
          handleCloseCalender();
          setafterUploadMessage(response.data.message);
          handleShowSuccess();
          setTimeout(() => {
            handleCloseSuccess();
          }, 2000);
          setestimatedateAddedError(false);
          setsavingTime(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadLabel = async (e) => {
    const files = Array.from(e.target.files);
    if (files?.length > 0) {
      const formdata = new FormData();
      formdata.append("order_uniq_id", order_uniq_id);
      formdata.append("vendor_code", vendor_code);
      formdata.append("order_id", uploadedItemda?.order_id);

      for (const element of files) {
        formdata.append("file", element, element?.name);
      }

      try {
        let response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}everest/domestic/po/accept/upload/marbete`,
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formdata,
        });
        if (response.status == 200) {
          postGettingList();
        }
      } catch (error) {
        console.log(error);
      }
    }
    e.target.value = null; // Reset the file input value
  };

  const postGettingList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/domestic/po/accept/orderlist`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });

      if (response.status == 200) {
        setvendorListTotal(response.data);
        setvenderOrderListArray(response?.data?.orders);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLableFn = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/domestic/po/accept/delete/marbete`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
          order_id: deleteData?.order_id,
        },
      });

      if (response.status == 200) {
        if (response.data.error) {
          console.log(response.data.message);
        } else {
          handleCloseVendorNewda();
          postGettingList();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postVenderOrderList = async () => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}everest/domestic/po/status`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          order_uniq_id: order_uniq_id,
          vendor_code: vendor_code,
        },
      });
      if (response.status == 200) {
        if (!response.data.error) {
          setloadingCompltedda(true);
          postGettingList();
        } else {
          setloadingCompltedda(true);
          navigate("/saved", {
            replace: true,
            state: {
              message: response.data.message,
            },
          });
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };
  useEffect(() => {
    postVenderOrderList();
  }, []);

  console.log(venderOrderListArray, "order list");

  return (
    <>
      {!loadingCompletedda ? (
        <Processing />
      ) : (
        <>
          {error ? (
            <>
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="wrapper-box">
                      <div className="d-flex justify-content-center">
                        <div style={{ width: "420px" }}>
                          <img className="w-100" src={save} />
                        </div>
                      </div>

                      <p className="fw-bold fs-3 text-center py-4">
                        {errorMessage}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="wrapper-box">
                      <div className="text-center mt-2 mb-5">
                        <h2 className="text-center fw-700 fs-30 mb-1">
                          Estimated Pickup Date
                        </h2>
                        <button
                          type="button"
                          className="btn-line"
                          onClick={handleShowCalender}
                        >
                          Add Estimated Pickup Date
                        </button>
                        <div className="fw-bold">
                          {value && estimatedTimeSuccessda && (
                            <>
                              The Estimated pickup date is{" "}
                              {dayjs(value)?.format("DD-MM-YYYY")}
                            </>
                          )}
                        </div>
                      </div>
                      <h2 className="text-center fw-700 fs-30">
                        Upload Marbete
                      </h2>
                    </div>
                    <div className="modify-tbl">
                      <table className="table text-center border my-4">
                        <tbody>
                          <tr>
                            <th>Serial Number</th>
                            <th>SKU</th>
                            <th>Description</th>
                            <th>Upload Marbete</th>
                          </tr>
                          {venderOrderListArray?.map((item, index) => (
                            <tr key={item.sap_code}>
                              <td>{index + 1}</td>
                              <td>{item.sap_code}</td>
                              <td>{item?.description}</td>
                              <td>
                                <label
                                  for="files_new"
                                  className="fw-bold"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    setuploadedItemda(item);
                                  }}
                                >
                                  Select Marbete
                                </label>

                                <input
                                  type="file"
                                  id="files_new"
                                  style={{ display: "none" }}
                                  accept=".jpg, .jpeg, .png"
                                  onChange={(e) => {
                                    handleUploadLabel(e);
                                  }}
                                />

                                {item?.marbete && (
                                  <>
                                    <div className="pb-1 ">
                                      <div className="image-container dotted-line">
                                        <img src={item?.marbete} alt="Image" />
                                        <button
                                          type="button"
                                          className="tbl-delete"
                                          onClick={() => {
                                            setdeleteData({
                                              order_id: item?.order_id,
                                            });
                                            handleShowVendorNewda();
                                          }}
                                        >
                                          <svg className="icon fs-18">
                                            <use href="#icon_delete"></use>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="ml-2 text-danger">
                        {estimatedateAddedError && "Please add Estimated date"}
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="ml-2">
                        <button
                          type="button"
                          className="mt-2 py-2 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                          onClick={() => {
                            if (!value) {
                              setestimatedateAddedError(true);
                              return;
                            }
                            handleShowVendorNameda();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              <Modal
                show={showVendorNewda}
                onHide={handleCloseVendorNewda}
                centered
              >
                <Modal.Body className="p-4">
                  <p className="fs-4 text-center">
                    Are you sure you want to delete this Marbete?
                  </p>
                  <div className="d-flex justify-content-center mt-2">
                    <Button
                      variant="secondary"
                      className="me-3"
                      onClick={handleCloseVendorNewda}
                    >
                      Close
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteLableFn();
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={showVendorNameda}
                onHide={handleCloseVendorNameda}
                centered
              >
                <Modal.Header
                  closeButton
                  style={{ border: "none" }}
                ></Modal.Header>
                <Modal.Body className="p-4">
                  <p className="fs-4 text-center m-0 p-0">
                    Please reconfirm your submission
                  </p>
                  <div className="input-box mb-4 mt-2 p-0">
                    <input
                      type="text"
                      className="w-100"
                      placeholder="Enter Description"
                      id="vender_code"
                      value={`${vendor_name} (${vendor_code})`}
                    />
                  </div>
                  {submitError.status && (
                    <div className="d-flex justify-content-end mt-2 text-danger">
                      <div>{submitError.message}</div>
                    </div>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    <div>
                      <button
                        type="button"
                        disabled={isSubmittingda}
                        className="mt-2 py-1 px-4  grd-btn fs-20 rounded-pill fw-600 d-flex text-center"
                        onClick={() => {
                          postSubmitButton();
                        }}
                      >
                        {isSubmittingda ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={showCalender}
                centered
                onHide={handleCloseCalender}
                className="tracking-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                  <h2 className="fw-600 fs-26 text-center">
                    Please Suggest a Pickup Date
                  </h2>
                  <div className="p-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DateCalendar", "DateCalendar"]}
                      >
                        <DemoItem>
                          <DateCalendar
                            value={value}
                            shouldDisableDate={shouldDisableDate}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div className="d-flex me-4 mb-4 text-danger">
                    <div className="ms-auto ">
                      {valueError?.status && <>{valueError?.data}</>}
                    </div>
                  </div>
                  <div className="d-flex me-4 mb-4">
                    <div
                      className="ms-auto "
                      disabled={savingTime}
                      onClick={() => {
                        addEstimatedDateApi();
                      }}
                    >
                      <button
                        type="button"
                        className="py-1 px-4 grd-btn fs-20 rounded-pill fw-600 text-center w-auto"
                      >
                        {savingTime ? "Saving..." : "Save"}
                      </button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={showSuccess}
                centered
                onHide={handleCloseSuccess}
                className="tracking-modal"
              >
                <Modal.Body className="p-4 text-center">
                  <div className="d-flex justify-content-center  text-success pb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="55"
                      height="55"
                      fill="currentColor"
                      className="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                  </div>
                  <h3>Success</h3>
                  <p>{afterUploadMessage}</p>
                </Modal.Body>
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
}

export default DomesticAccept;
