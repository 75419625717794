import axios from "axios";
import { useState, useEffect } from "react";
import { Col, Container, Row, Modal, Tab, Tabs, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getMomentFormatted, getStatusIcon } from "../utils/commonFn";
import Loader from "../components/Loader";
import { conditionalExp } from "../utils/commonFn";
import GroupOrdersModal from "../components/GroupOrdersModal";
import GMap from "../components/GMap";
import { DatePicker, Space } from 'antd';
import {
  useLoadScript,
} from "@react-google-maps/api";
import moment from "moment";
import ErrorModal from "../components/ErrorModal";



function Distribution_status() {
const navigate = useNavigate();
const [isloadingmarkreturn, setIsloadingmarkreturn] = useState(false)
const [errorMarkOrder, seterrorMarkOrder] = useState()
const [selectedAppointmentDate, setSelectedAppointmentDate] = useState()
const [selectedPickupAddress, setSelectedPickupAddress] = useState()

const { isLoaded, loadError } = useLoadScript({
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
});


const [error, setError] = useState();
const [showErrModal, setShowErrModal] = useState(false);
const [checkedOrders, setCheckedOrders] = useState([])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
const [showMarkAsModal, setShowMarkAsModal] = useState(false)
const [isLoadingMarkRecived, setIsLoadingMarkRecived] = useState(false)
const handleShowMarkAsModal = () => setShowMarkAsModal(true);
const handleCloseMarkAsModal = () => setShowMarkAsModal(false);
  const [showmark, setShowmark] = useState(false);
  const handleClosemark = () => {setShowmark(false)
  setSelectedAppointmentDate()
  setSelectedPickupAddress()
  setSkuModalData(
    {
      doc_number: "",
      order_items: [],
    }
  )
  };
  const handleShowmark = () => setShowmark(true);


  const handleShow = (data) => setShow(data);
  const [showGroupOrdModal, setShowGroupOrdModal] = useState(false)

  const [showopen, setShowopen] = useState(false);
  const handleCloseopen = () => {setShowopen(false)
    setSkuModalData(
      {
        doc_number: "",
        order_items: [],
      }
    )
  };
  const handleShowopen = (data,modal) => {
    if (modal=="return"){
      handleShowmark()
    }
    else {
      setShowopen(true);
    }
    setSkuModalData(data);
  };
  const [showTrackOrder, setShowTrackOrder] = useState(false);
  const handleCloseTrackOrder = () => setShowTrackOrder(false);
  const handleShowTrackOrder = (data) => setShowTrackOrder(data);

  const [rejectselected, setrejectselected] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [accordion, setAccordion] = useState([]);
  const [skuModalData, setSkuModalData] = useState({
    doc_number: "",
    order_items: [],
  })

  async function getgroupedOrders(type) {
    const token = localStorage.getItem("mtm_user_token");
    const company_name = localStorage.getItem("company_name");
    setisLoading(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}mtmdistribution/get/grouped_distribution_orders`,
        {
          customer_id: JSON.parse(localStorage.getItem("mtm_user_data"))
            ?.customer_id,
          company_name: company_name,
          type: type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        setisLoading(false);
        setAccordion(response.data?.groups || []);
      } else {
        setAccordion([]);
        setisLoading(false);
      }
      setCheckedOrders([]);
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
      setisLoading(false);
    }
  }
  async function handleMarkReturn() {
    const token = localStorage.getItem("mtm_user_token");
    setIsloadingmarkreturn(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}mtmdistribution/orders/mark_as_return`,
        {
            purchase_order_id: skuModalData?.purchase_order_id,
            items: skuModalData?.order_items?.filter(item=>item?.qtyToSend > 0)?.map(item=>({...item,qty:item?.qtyToSend})),
            appointment_date: selectedAppointmentDate?.format('YYYY-MM-DD HH:mm:ss'),
            pickup_address: selectedPickupAddress,
            isAllReturned: skuModalData?.order_items?.every(item=>parseInt(item?.qtyToSend) + parseInt(item?.return_qty || 0) >= item?.qty),
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        await getgroupedOrders("delivered");
        handleClosemark()
        setIsloadingmarkreturn(false);
      } else{
        seterrorMarkOrder(response.data?.message)
        setIsloadingmarkreturn(false)
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
      setIsloadingmarkreturn(false);
    }
  }
  async function handleMarkAsReceived() {
    const token = localStorage.getItem("mtm_user_token");
    setIsLoadingMarkRecived(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}mtmdistribution/return_orders/mark_as_received`,
        {
          orders:checkedOrders,
          group_id:checkedOrders[0]?.group_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            jwt_token: token,
          },
        }
      );

      if (!response?.data?.error) {
        await getgroupedOrders("inward");
        handleCloseMarkAsModal()
        setCheckedOrders([])
        setIsLoadingMarkRecived(false);
      } else{
        setError({ title: "Error", err: response.data?.message });
        setShowErrModal(true);
        setIsLoadingMarkRecived(false)
      }
    } catch (err) {
      if (err.response.status == 401) {
        localStorage.removeItem("mtm_user_token");
        localStorage.removeItem("mtm_user_data");
        navigate("/");
        return;
      }
      console.log(err);
      setIsLoadingMarkRecived(false);
    }
  }

  function getDeliveredStatus(orders) {
    let rejectedOrders = orders?.filter(order => order?.order_status >=6);

    if (rejectedOrders?.length > 0) {
      return `Rejected #${rejectedOrders?.map(order=>order?.doc_number).join(", ")}`;
    }else{
      return "All Orders Delivered";
    }
  }

  useEffect(() => {
    getgroupedOrders("outward");
  }, []);

  useEffect(() => {
    if (rejectselected == "Rejected") {
      handleShowopen();
    }
  }, [rejectselected]);
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <button
                type="button"
                className="p-0 m-0 border-0 bg-white btn-back d-flex align-items-center fs-20"
                onClick={() => navigate("/distribution_activities")}
              >
                <svg className="icon fs-24 me-2">
                  <use href="#icon_backarrow"></use>
                </svg>
                Back
              </button>
              <h2 className="text-center fw-700 fs-30">Distribution Status</h2>
            </div>
          </Col>
          <Col md={12}>
            <div className="w-100 three-tabs-row mt-5">
              <ul
                className="nav nav-pills mb-3 w-100"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-Outward-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Outward"
                    type="button"
                    role="tab"
                    aria-controls="pills-Outward"
                    aria-selected="true"
                    onClick={() => {
                      setAccordion([]);
                      getgroupedOrders("outward");
                    }}
                  >
                    <svg className="icon fs-20 me-2">
                      <use href="#icon_outward"></use>
                    </svg>
                    Outward
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-Inward-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Inward"
                    type="button"
                    role="tab"
                    aria-controls="pills-Inward"
                    aria-selected="false"
                    onClick={() => {
                      setAccordion([]);
                      getgroupedOrders("inward");
                    }}
                  >
                    <svg className="icon fs-20 me-2">
                      <use href="#icon_inward"></use>
                    </svg>
                    Inward
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-Delivered-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Delivered"
                    type="button"
                    role="tab"
                    aria-controls="pills-Delivered"
                    aria-selected="false"
                    onClick={() => {
                      setAccordion([]);
                      getgroupedOrders("delivered");
                    }}
                  >
                    <svg className="icon fs-20 me-2">
                      <use href="#icon_delivered"></use>
                    </svg>
                    Delivered
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-Outward"
                role="tabpanel"
                aria-labelledby="pills-Outward-tab "
                tabIndex="0"
              >
                <div className="accordion" id="accordionExample">
                  {accordion?.map((item,index) => (
                    <div className="accordion-item">
                      <h2 onClick={()=>{
                        setCheckedOrders(item?.orders?.map(i=>({id:i.id,doc_number:i.doc_number,address1:i.address1,appointment_date:i.appointment_date})))
                      }}  className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle={`collapse`}
                          // data-bs-toggle="collapse"
                          data-bs-target={`#collapseTwo${index}`}
                          // data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls={`collapseTwo${index}`}
                          // aria-controls="collapseTwo"
                        >
                          <div className="w22 fs-14">
                            {conditionalExp(item?.group_name,`${item?.group_name} (ID:${item?.group_id})`,`ID:${item?.group_id}`)}
                          </div>
                          <div className="d-flex align-items-center py-2 w-75 justify-content-center text-dark">
                            <div className="fs-14 fw-600 br-left px-2">
                              <p className="pb-2 fs-11 fw-700">
                                PickUp Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                <div className="br-right px-1">
                                {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                                </div>
                                <div className="px-1">
                                {getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                                </div>
                              </div>
                            </div>
                            {conditionalExp(item?.dispatch_date,<div className="fs-14 fw-600 br-left br-right px-2">
                              <p className="pb-2 fs-11 fw-700">
                                Dispatch Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                {/* <div className="br-right px-1">{item?.dispatch_date}</div>
                                <div className="px-1">11:02 AM</div> */}
                                {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                              </div>
                            </div>)}
                            {/* <div className="bg-lightblue p-1 rounded-2 d-flex align-items-center ms-3">
                              <div className="w25px h25px bg-blue rounded-2 d-flex align-items-center justify-content-center me-2">
                                <svg className="icon fs-20 text-white">
                                  <use href="#icon_transit"></use>
                                </svg>
                              </div>
                              <span className="fw-600 text-blue fs-14">
                                In Transit
                              </span>
                            </div> */}
                          </div>
                        </button>
                        {conditionalExp(!(item?.group_status >= 2),<button
                          type="button"
                          disabled={item?.group_status >= 2 || !checkedOrders?.find(i=> item?.orders?.find(j=>j.id==i.id))?true:false}
                          className="accor-btn z-10"
                          onClick={()=>{
                            setShowGroupOrdModal({
                              group_id:item?.group_id,
                              group_name:item?.group_name,
                              orders:checkedOrders,
                              truck_type: item?.truck_type,
                              career_id: item?.career_id,
                              pickup_date: item?.pickup_date,
                              pickup_time: item?.pickup_time,
                              pickup_address: item?.pickup_address,
                              modal_name:`Modify ${item?.group_name} (ID:${item?.group_id})`
                            })
                          }}
                          //   onClick={()=>{setBookingId(item?.booking_id);setTruckNo(item?.truck_no);handleShow({orders:item?.orders,previous_driver_id:item?.driver_id})}}
                        >
                          Modify Group
                        </button>)}
                        <button
                          type="button"
                          className="accor-track rounded-pill"
                          onClick={()=>handleShowTrackOrder({
                            group_id:item?.group_id,
                            positions:item?.positions,
                            deliveryLocations:item?.orders?.map(ord=>{
                              if(ord?.delivery_lat && ord?.delivery_long){
                                return {lat:ord.delivery_lat,long:ord.delivery_long}
                              }else{
                                return null;
                              }
                            })?.filter(ord=>ord != null)                            
                          })}
                        >
                          View Tracking
                        </button>
                      </h2>
                      <div
                          id={`collapseTwo${index}`}
                        // id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row row-cols-2 g-4">
                            {item?.orders?.map((order, ordindex) => (
                              <div className="col">
                                <div className="list-boxes">
                                  <ul>
                                    <li>
                                      {getStatusIcon(order?.order_status)}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_purchase"></use>
                                      </svg>
                                      Order Number: {order?.doc_number} 
                                      <div className="ms-auto">
                                        <input
                                          type="checkbox"
                                          className="rounded-checkbox"
                                          id="checkbox"
                                          disabled={item?.group_status >= 2}
                                          checked={checkedOrders?.find(i=>i.id==order?.id)}
                                          onChange={(e) => {
                                           if(e.target.checked){
                                            setCheckedOrders([...checkedOrders,{id:order?.id,doc_number:order.doc_number,address1:order.address1,appointment_date:order.appointment_date}])
                                           }else{
                                            setCheckedOrders(checkedOrders?.filter(i=>i.id!=order?.id))
                                           }
                                          }}
                                        />
                                      </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_total"></use>
                                      </svg>
                                      Total Quantity: {order?.total_qty}
                                      <div className="eye-btn2 ms-4">
                                        <button
                                          type="button"
                                          className="p-0 m-0 border-0"
                                          onClick={()=>handleShowopen({
                                            doc_number: order?.doc_number,
                                            order_items: order?.order_items,
                                          })}
                                        >
                                          <svg className="icon fs-18">
                                            <use href="#icon_eye"></use>
                                          </svg>
                                        </button>
                                      </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_customer"></use>
                                      </svg>
                                      Customer: {order?.organization_name}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_appointment"></use>
                                      </svg>
                                      Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon fs-20 me-2">
                                        <use href="#icon_truck2"></use>
                                      </svg>
                                      Delivery Address: {order?.address1}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_delivery"></use>
                                      </svg>
                                      Type Of Truck: {order?.truck_type}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_delivery"></use>
                                      </svg>
                                      Truck Line: {order?.truck_line}
                                    </li>
                                    {conditionalExp(item?.group_status >=2 ,<>
                                      <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_booking-id"></use>
                                      </svg>
                                      Booking ID: {order?.booking_id}
                                    </li>
                                      <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_truck-no"></use>
                                      </svg>
                                      Truck No: {order?.truck_no} 
                                    </li>
                                      <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_driver-name"></use>
                                      </svg>
                                      Driver Name: {order?.first_name} {order?.last_name}
                                    </li>
                                    
                                    </>)}
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {conditionalExp(
                    isLoading == false && accordion.length == 0,
                    <p className="w-full  text-center fs-1 py-5">
                      There are no group found
                    </p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center py-5">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
              {/*  */}
              <div
                className="tab-pane fade"
                id="pills-Inward"
                role="tabpanel"
                aria-labelledby="pills-Inward-tab"
                tabIndex="0"
              >
                <div className="accordion" id="accordionExample">
                  {accordion?.map((item,index) => (
                    <div className="accordion-item">
                      <h2 onClick={()=>{
                        setCheckedOrders(item?.orders?.filter(i=>i.order_status < 7)?.map(i=>({id:i.id,group_id:item.group_id,doc_number:i.doc_number,address1:i.address1,appointment_date:i.appointment_date})))
                      }}  className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle={`collapse`}
                          // data-bs-toggle="collapse"
                          data-bs-target={`#collapseTwo${index}`}
                          // data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls={`collapseTwo${index}`}
                          // aria-controls="collapseTwo"
                        >
                          <div className="w22 fs-14 py-2">
                            {conditionalExp(item?.group_name,`${item?.group_name} (ID:${item?.group_id})`,`ID:${item?.group_id}`)}
                          </div>
                          {/* <div className="d-flex align-items-center py-2 w-75 justify-content-center text-dark">
                            <div className="fs-14 fw-600 br-left px-2">
                              <p className="pb-2 fs-11 fw-700">
                                PickUp Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                <div className="br-right px-1">
                                {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                                </div>
                                <div className="px-1">
                                { item?.pickup_date && getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                                </div>
                              </div>
                            </div>
                            {conditionalExp(item?.dispatch_date,<div className="fs-14 fw-600 br-left br-right px-2">
                              <p className="pb-2 fs-11 fw-700">
                                Dispatch Date & Time
                              </p>
                              <div className="d-flex fs-14 fw-400">
                                {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                              </div>
                            </div>)}
                            <div className="bg-lightblue p-1 rounded-2 d-flex align-items-center ms-3">
                              <div className="w25px h25px bg-blue rounded-2 d-flex align-items-center justify-content-center me-2">
                                <svg className="icon fs-20 text-white">
                                  <use href="#icon_transit"></use>
                                </svg>
                              </div>
                              <span className="fw-600 text-blue fs-14">
                                In Transit
                              </span>
                            </div>
                          </div> */}
                        </button>
                        <button
                          type="button"
                          className="accor-tracktwo rounded-pill"
                          onClick={()=>handleShowTrackOrder({
                            group_id:item?.group_id,
                            positions:item?.positions,
                            deliveryLocations:item?.orders?.map(ord=>{
                              if(ord?.delivery_lat && ord?.delivery_long){
                                return {lat:ord.delivery_lat,long:ord.delivery_long}
                              }else{
                                return null;
                              }
                            })?.filter(ord=>ord != null),                         
                            pickupLocations:item?.orders?.map(ord=>{
                              if(ord?.pickup_lat && ord?.pickup_long){
                                return {lat:ord.pickup_lat,long:ord.pickup_long}
                              }else{
                                return null;
                              }
                            })?.filter(ord=>ord != null)                            
                          })}
                        >
                          View Tracking
                        </button>
                        
                        {item.group_status < 7 ? <button onClick={handleShowMarkAsModal} disabled={!checkedOrders?.find(i=> item?.orders?.find(j=>j.id==i.id))?true:false} type="button" className="accor-track rounded">Mark As Received</button>:
                        <div className="accor-receive">RECEIVED</div>
                        }
                        
                      </h2>
                      <div
                        id={`collapseTwo${index}`}
                        // id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="row row-cols-2 g-4">
                            {item?.orders?.map((order, ordindex) => (
                              <div className="col">
                                <div className="list-boxes">
                                  <ul>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_purchase"></use>
                                      </svg>
                                      Order Number: {order?.doc_number}  
                                      <div className="ms-auto">
                                        <label className="checkbox">
                                            <input type="checkbox" className="checkbox__inputred"
                                            disabled={order?.order_status >= 7}
                                            checked={checkedOrders?.find(i=>i.id==order?.id)}
                                            onChange={(e) => {
                                              if(e.target.checked){
                                               setCheckedOrders([...checkedOrders,{id:order?.id,group_id:item?.group_id,doc_number:order.doc_number,address1:order.address1,appointment_date:order.appointment_date}])
                                              }else{
                                               setCheckedOrders(checkedOrders?.filter(i=>i.id!=order?.id))
                                              }
                                             }}/>
                                          <span className="checkbox__inner"></span>
                                        </label>
                                      </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_total"></use>
                                      </svg>
                                      Total Quantity: {order?.total_qty}
                                      <div className="eye-btn2 ms-4">
                                        <button
                                          type="button"
                                          className="p-0 m-0 border-0"
                                          onClick={()=>handleShowopen({
                                            doc_number: order?.doc_number,
                                            order_items: order?.order_items,
                                          })}
                                        >
                                          <svg className="icon fs-18">
                                            <use href="#icon_eye"></use>
                                          </svg>
                                        </button>
                                      </div>
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_customer"></use>
                                      </svg>
                                      Customer: {order?.organization_name}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_appointment"></use>
                                      </svg>
                                      Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon fs-20 me-2">
                                        <use href="#icon_truck2"></use>
                                      </svg>
                                      Delivery Address: {order?.address1}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_delivery"></use>
                                      </svg>
                                      Type Of Truck: {order?.truck_type}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_delivery"></use>
                                      </svg>
                                      Truck Line: {order?.truck_line}
                                    </li>
                                    <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                        <use href="#icon_proof_delivery"></use>
                                      </svg>
                                      Reject Reason: {order?.reject_reason}
                                      <div className="eye-btn2 ms-4">
                                      <button
                                      type="button"
                                      className="p-0 m-0 border-0"
                                        onClick={()=>handleShow({
                                          type:'Rejection',
                                          doc_number: order?.doc_number,
                                          order_items: order?.order_items,
                                          selectedItem: order?.order_items[0],
                                          selectedImage: order?.order_items[0]?.delivery_proofs[0],
                                        })}
                                      >
                                      <svg className="icon fs-18">
                                          <use href="#icon_eye"></use>
                                      </svg>
                                      </button>
                                  </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {conditionalExp(
                    isLoading == false && accordion.length == 0,
                    <p className="w-full  text-center fs-1 py-5">
                      There are no group found
                    </p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center py-5">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-Delivered"
                role="tabpanel"
                aria-labelledby="pills-Delivered-tab"
                tabIndex="0"
              >
                <div className="accordion my-3" id="accordionExample">
                {accordion?.map((item,index) => (
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle={`collapse`}
                        // data-bs-toggle="collapse"
                        data-bs-target={`#collapseTwo${index}`}
                        // data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls={`collapseTwo${index}`}
                        // aria-controls="collapseTwo"
                      >
                        <div className="w22 fs-14">
                          {conditionalExp(item?.group_name,`${item?.group_name} (ID:${item?.group_id})`,`ID:${item?.group_id}`)}
                        </div>
                        <div className="d-flex align-items-center py-2 w-75 text-dark">
                          <div className="fs-14 fw-600 br-left px-2">
                            <p className="pb-2 fs-11 fw-700">
                              PickUp Date & Time
                            </p>
                            <div className="d-flex fs-14 fw-400">
                              <div className="br-right px-1">
                              {getMomentFormatted(item?.pickup_date,'DD/MM/YYYY')}
                              </div>
                              <div className="px-1">
                              {getMomentFormatted(`${item?.pickup_date} ${item?.pickup_time}`,'hh:mm A')}
                              </div>
                            </div>
                          </div>
                          {conditionalExp(item?.dispatch_date,<div className="fs-14 fw-600 br-left br-right px-2">
                            <p className="pb-2 fs-11 fw-700">
                              Dispatch Date & Time
                            </p>
                            <div className="d-flex fs-14 fw-400">
                              {/* <div className="br-right px-1">{item?.dispatch_date}</div>
                              <div className="px-1">11:02 AM</div> */}
                              {getMomentFormatted(item?.dispatch_date,'DD/MM/YYYY | hh:mm A')}
                            </div>
                          </div>)}
                          <h4 className="ms-auto">
                            {getDeliveredStatus(item?.orders)}
                          </h4>
                        </div>
                      </button>
                    </h2>
                    <div
                        id={`collapseTwo${index}`}
                      // id="collapseTwo"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="row row-cols-2 g-4">
                          {/* {item?.orders?.map((order, index) => (
                              ))} */}
                          {item?.orders?.map((order, index) => (
                          <div className="col">
                            <div className="list-boxes">
                              <ul>
                                  <li className="d-flex align-items-center">
                                      <svg className="icon me-2">
                                          <use href="#icon_purchase"></use>
                                      </svg>
                                      Order Number: {order?.doc_number}
                                    <div className=" ms-auto">
                                      {conditionalExp(order?.order_status < 6,<button
                                      type="button"
                                      className="p-2 m-0 border-0 grd-btn fs-14" 
                                      onClick={()=>{handleShowopen({
                                        purchase_order_id: order?.id,
                                        doc_number: order?.doc_number,
                                        order_items: order?.order_items?.map(orditm=>({...orditm,qtyToSend:0,boxQty:0,looseQty:0,})),
                                      },"return"); setSelectedPickupAddress(order?.address1 || order?.address2 || "")}}
                                      >Mark Retrun Items</button>)}
                                  </div>
                                  </li>
                                  <li className="d-flex align-items-center">
                                  <svg className="icon me-2">
                                    <use href="#icon_total"></use>
                                  </svg>
                                    Total Quantity: {order?.total_qty}
                                  <div className="eye-btn2 ms-4">
                                      <button
                                      type="button"
                                      className="p-0 m-0 border-0"
                                        onClick={()=>handleShowopen({
                                          doc_number: order?.doc_number,
                                          order_items: order?.order_items,
                                        })}
                                      >
                                      <svg className="icon fs-18">
                                          <use href="#icon_eye"></use>
                                      </svg>
                                      </button>
                                  </div>
                                  </li>
                                  <li className="d-flex align-items-center">
                                  <svg className="icon me-2">
                                    <use href="#icon_customer"></use>
                                  </svg>
                                    Customer: {order?.organization_name}
                                  </li>
                                  <li className="d-flex align-items-center">
                                  <svg className="icon me-2">
                                    <use href="#icon_appointment"></use>
                                  </svg>
                                    Appointment: {getMomentFormatted(order?.appointment_date,'DD/MM/YYYY | hh:mm A')}
                                  </li>
                                  <li className="d-flex align-items-center">
                                  <svg className="icon me-2">
                                    <use href="#icon_truck2"></use>
                                  </svg>
                                    Delivery Address: {order?.address1}
                                  </li>
                                  <li className="d-flex align-items-center">
                                    <svg className="icon me-2">
                                      <use href="#icon_delivery"></use>
                                    </svg>
                                    Type Of Truck: {order?.truck_type}
                                  </li>
                                  <li className="d-flex align-items-center">
                                    <svg className="icon me-2">
                                      <use href="#icon_delivery"></use>
                                    </svg>
                                    Truck Line: {order?.truck_line}
                                  </li>
                                  <li className="d-flex align-items-center">
                                    <svg className="icon me-2">
                                      <use href="#icon_booking-id"></use>
                                    </svg>
                                    Booking ID: {order?.booking_id}
                                  </li>
                                  <li className="d-flex align-items-center">
                                    <svg className="icon me-2">
                                      <use href="#icon_truck-no"></use>
                                    </svg>
                                    Truck No: {order?.truck_no}
                                  </li>
                                  <li className="d-flex align-items-center">
                                    <svg className="icon me-2">
                                      <use href="#icon_proof_delivery"></use>
                                    </svg>
                                    Proof Of Delivery: {getMomentFormatted(order?.delivery_date,'DD/MM/YYYY | hh:mm A')}
                                    <div className="eye-btn2 ms-4">
                                      <button
                                      type="button"
                                      className="p-0 m-0 border-0"
                                        onClick={()=>handleShow({
                                          type:'Delivery',
                                          doc_number: order?.doc_number,
                                          order_items: order?.order_items,
                                          selectedItem: order?.order_items[0],
                                          selectedImage: order?.order_items[0]?.delivery_proofs[0],
                                        })}
                                      >
                                      <svg className="icon fs-18">
                                          <use href="#icon_eye"></use>
                                      </svg>
                                      </button>
                                  </div>
                                  </li>
                              </ul>
                            </div>
                          </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                   {conditionalExp(
                    isLoading == false && accordion.length == 0,
                    <p className="w-full  text-center fs-1 py-5">
                      There are no group found
                    </p>
                  )}
                  {conditionalExp(
                    isLoading,
                    <div className="w-full text-center py-5">
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Modal */}
      <Modal size="lg" show={show} centered onHide={handleClose}className="tracking-modal">
        <Modal.Header closeButton className="pb-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 pt-0">
          <div className="d-flex align-items-center">
            <div className="d-flex mx-auto">
              <div className="text-center">
                <h4 className="fs-20 fw-600">Proof Of {show?.type}</h4>
                <p className="fw-600 py-2">Order Number: {show?.doc_number}</p>
              </div>
            </div>
          </div>
          <div>
            <select
              style={{ width: "160px" }}
              className="form-select"
              aria-label="choose-sku"
              value={show?.selectedItem?.item_code || ""}
              onChange={(e)=>{
                const selectedItem = show?.order_items?.find(
                  (item) => item.item_code === e.target.value
                );
                setShow({...show, selectedItem, selectedImage: selectedItem?.delivery_proofs[0]});
              }}
            >
              {/* <option selected>Choose SKU</option> */}
              {show?.order_items?.map((item, index) => (
                <option value={item.item_code}>{item.item_code}</option>
              ))}
            </select>
          </div>
          <hr />
          <div className="w-100 d-flex">
           {conditionalExp( !show?.selectedItem?.delivery_proofs?.length ,<p className="w-100 text-center">
            No Proof Found!
           </p>,<><div className="w-20">
              <div
                className="d-flex select-img flex-column gap-2 p-2 overflow-auto"
                style={{ maxHeight: "400px" }}
              >
                {show?.selectedItem?.delivery_proofs?.map((image,index)=>
                <button onClick={()=>{
                  setShow({...show, selectedImage: image});
                }} key={index} type="button" className="img-box mt-4" 
                // style={{border: show?.selectedImage == image ?  "2px solid #4FCE5C" : "initial"}}
                >
                <div>
                  <img loading="lazy" src={image} />
                </div> 
                </button>
                )}
              </div>
            </div>
            <div className="w-80" style={{ paddingLeft: "30px" }}>
              <div className="view-img" style={{ maxHeight: "400px" }}>
                <img loading="lazy" src={show?.selectedImage} alt="..." />
              </div>
            </div></>)}
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="lg" scrollable={true} show={showopen} centered onHide={handleCloseopen}>
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <h4 className="text-center m-0 pb-3 fs-22 fw-700">
            Order Number: {skuModalData?.doc_number}
          </h4>
          <div className="new-tbl">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-start">SKU</th>
                  <th className="text-start">Descripton</th>
                  <th className="text-start">Order Quantity</th>
                </tr>
              </thead>
              <tbody>
                {skuModalData?.order_items.map((itm)=>{
                  // console.log(itm)
                  return (<tr>
                    <td>{itm.item_code}</td>
                    <td>{itm.description}</td>
                    <td>{itm.qty}</td>
                  </tr>)
                })}
              </tbody>
             
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="xl" scrollable={true} show={showmark} centered onHide={handleClosemark}>
        <Modal.Header closeButton className="border-0 pb-0">
          {/* <Modal.Title className="d-flex align-items-center justify-content-center">Mark Return Items</Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <h4 className="text-center m-0 pb-3 fs-24 fw-700">
            Mark Return Items
          </h4>
          <h4 className="text-center m-0 pb-3 fs-22 fw-700">
            Order Number: {skuModalData?.doc_number}
          </h4>
          <div className="d-flex pb-4">
            <div>
              <label htmlFor="date" className="form-label d-block fw-bold">
                Appointment DateTime
              </label>
              <Space direction="vertical">
                <DatePicker popupClassName="datepicker" showTime needConfirm size="large" value={selectedAppointmentDate} onChange={(e)=>setSelectedAppointmentDate(e)}/>
              </Space>
            </div>
            <div className="ms-5">
              <label for="exampleInputEmail1" className="form-label fw-bold">Pickup Address</label>
              <textarea className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={selectedPickupAddress} onChange={(e)=>setSelectedPickupAddress(e.target.value)}/>
            </div>
          </div>
          <div className="new-tbl">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-start">SKU</th>
                  <th className="text-start">Descripton</th>
                  <th className="text-start">Order&nbsp;Quantity</th>
                  <th className="text-start">Already&nbsp;Returned&nbsp;Quantity</th>
                  <th className="text-start">Box&nbsp;Return&nbsp;Quantity</th>
                  <th className="text-start">Loose&nbsp;Return&nbsp;Quantity</th>
                  <th className="text-start">Total&nbsp;Return&nbsp;Quantity</th>
                </tr>
              </thead>
              <tbody>
                {skuModalData?.order_items.map((itm)=>{
                  // console.log(itm)
                  return (<tr>
                    <td>{itm.item_code}</td>
                    <td>{itm.description}</td>
                    <td>{itm.qty}</td>
                    <td>{itm.return_qty || 0}</td>
                    <td>
                      <div style={{maxWidth: "160px"}}>
                        <input min={0} disabled={itm?.return_qty || 0 >= itm?.qty} value={itm?.boxQty} onChange={e=>{
                          let temp = skuModalData?.order_items?.map((skus)=>{
                            if(skus?.item_code == itm?.item_code){
                              skus.boxQty = e.target.value
                              skus.qtyToSend = (parseInt(e.target.value || 0) * itm?.package_factor)
                            }
                            return skus
                          })
                          setSkuModalData({...skuModalData,order_items:temp})
                        }}  type="number" className="form-control" id="exampleInputbox" aria-describedby="Box"/>
                      </div>
                    </td>
                    <td>
                      <div style={{maxWidth: "160px"}}>
                        <input min={0} disabled={itm?.return_qty || 0 >= itm?.qty} value={itm?.looseQty} onChange={e=>{
                          let temp = skuModalData?.order_items?.map((skus)=>{
                            if(skus?.item_code == itm?.item_code){
                              skus.looseQty = e.target.value
                              skus.qtyToSend = (itm?.boxQty * itm?.package_factor) + parseInt(e.target.value || 0)
                            }
                            return skus
                          })
                          setSkuModalData({...skuModalData,order_items:temp})
                        }}   type="number" className="form-control" id="exampleInputloose" aria-describedby="Loose"/>
                      </div>
                    </td>
                    <td className={`${parseInt(itm?.qtyToSend) + parseInt(itm?.return_qty || 0) > itm?.qty ? 'text-danger' : ""}`}>{parseInt(itm?.qtyToSend) + parseInt(itm?.return_qty || 0)} {conditionalExp(parseInt(itm?.qtyToSend) + parseInt(itm?.return_qty ||0) > itm?.qty,<p>Return Quantity cannot be more than Order Quantity</p> )}</td>

                  </tr>)
                })}
              </tbody>
             
            </table>
          </div>
          {errorMarkOrder &&  <div className="d-flex">
                <p className="ms-auto text-danger ">{errorMarkOrder}</p>
          </div>}
          <div className="d-flex">
            <button type="button" className="accor-btn px-5 ms-auto"
            onClick={handleMarkReturn}
            disabled={isloadingmarkreturn ||!selectedAppointmentDate || !selectedPickupAddress || !skuModalData?.order_items?.find(item=>item?.qtyToSend) || skuModalData?.order_items?.find(item=>parseInt(item?.qtyToSend) + parseInt(item?.return_qty || 0) > item?.qty)}>{conditionalExp(isloadingmarkreturn,<Loader/>,"Submit")}</button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showTrackOrder}
        centered
        onHide={handleCloseTrackOrder}
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3 pt-0">
          <div className="border-bottom mb-2">
            <h4 className="text-center m-0 fs-20 fw-600">Tracking</h4>
            <p className="text-center fw-600 py-2">Group ID: {showTrackOrder?.group_id}</p>
          </div>
          <div>
          <div>
                Marker colors :
                <span className="mx-2">🟡 Yellow = Pickup</span>
                <span className="mx-2">
                  🔴 Red = Current
                </span>
                <span className="mx-2">
                  🟢 Green = Delivery
                  
                </span>
              </div>
          {isLoaded && <GMap loadError={loadError} movements={showTrackOrder?.positions || []} deliveryLocations={showTrackOrder?.deliveryLocations || []} pickupLocations={showTrackOrder?.pickupLocations || []}  />}
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showMarkAsModal} onHide={handleCloseMarkAsModal} centered>
        <Modal.Body className="p-4">
          <p className="fs-4 fw-700 text-center">
            Mark As Received
          </p>
          <p className="fs-4  text-center">
          are you sure you have received the item in warehouse?
          </p>
          <div className="d-flex justify-content-center mt-2">
            <button
              className="me-3 btn-nrml p-1 px-4 rounded-pill"
              onClick={handleCloseMarkAsModal}
            >
              Close
            </button>
            <button
            disabled={isLoadingMarkRecived}
              className="btn-nrml p-1 px-4 rounded-pill"
              onClick={() => {
                handleMarkAsReceived();
              }}
            >
              {conditionalExp(isLoadingMarkRecived, <Loader />, "Submit")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <GroupOrdersModal   show={showGroupOrdModal} setShow={setShowGroupOrdModal} getGroups={()=>getgroupedOrders('outward')}/>
      <ErrorModal
        error={error}
        setShowErrModal={setShowErrModal}
        showErrModal={showErrModal}
      />
    </>
  );
}

export default Distribution_status;
