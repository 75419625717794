import React, { useState } from "react";
import { Col, Container, Row, Modal } from "react-bootstrap";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

function Aftersave() {
  const [value, setValue] = React.useState(dayjs("2022-04-17"));
  const Tomorrow = dayjs().add(1, "day");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const shouldDisableDate = (date) => {
    return date.isBefore(Tomorrow, "day"); // Disable dates before tomorrow
  };
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className="wrapper-box">
              <div className="d-flex justify-content-center">
                <div style={{ width: "400px" }}>
                  <img className="w-100" src="images/save.png" />
                </div>
              </div>
              <p className="fw-bold fs-3 text-center py-2">
                Your response has been saved!
              </p>
              <p className="text-center pb-2">
                The Estimated pickup date is June19, 2023
              </p>
              <div className="text-center">
                <button type="button" className="btn-line" onClick={handleShow}>
                  Change pickup date
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="tracking-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <h2 className="fw-600 fs-26 text-center">
            Please Suggest pickup date
          </h2>
          <div className="p-4">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateCalendar", "DateCalendar"]}>
                <DemoItem>
                  <DateCalendar
                    value={value}
                    shouldDisableDate={shouldDisableDate}
                    onChange={(newValue) => setValue(newValue)}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Aftersave;
